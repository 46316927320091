import { Route, Routes } from '@angular/router';
import { actiefAlsExJongeMatcher, actiefVoorMezelfMatcher, inTeamMatcher } from '../core/matchers';
import { BerichtenNietInTeamPage } from './page/berichten-niet-in-team-page/berichten-niet-in-team.page';
import { GesprekkenOverviewPage } from './page/gesprekken-overview-page/gesprekken-overview-page.component';
import { GroepsgesprekAddPage } from './page/groepsgesprek-add-page/groepsgesprek-add.page';
import { BerichtenNietAangemeldPage } from './page/berichten-niet-aangemeld-page/berichten-niet-aangemeld.page';
import { BerichtGesprekPage } from './page/gesprek-page/bericht-gesprek-page.component';
import { GesprekSettingsPage } from './page/gesprek-settings-page/gesprek-settings.page';
import { NotitieAddPage } from '../profiel/page/notitie-add-page/notitie-add.page';
import { BerichtProbleemAddPage } from './page/bericht-probleem-add-page/bericht-probleem-add.page';
import { dirtyFormGuard } from '../shared/components/dirty-form-guard';
import { specificArtikelRoute, specificHulplijnRoute } from '../watwat/routes';
import { BerichtenExJongerePage } from './page/berichten-ex-jongere-page/berichten-ex-jongere.page';

const gesprekDetailRoutes: Route = {
  path: 'gesprek/:gesprekId',
  children: [
    {
      path: '',
      pathMatch: 'full',
      component: BerichtGesprekPage,
    },
    {
      path: 'instellingen',
      component: GesprekSettingsPage,
    },
    {
      path: 'notitie',
      component: NotitieAddPage,
    },
    {
      path: 'probleem',
      component: BerichtProbleemAddPage,
      canDeactivate: [dirtyFormGuard],
    },
    specificArtikelRoute,
    specificHulplijnRoute,
  ],
};

export const berichtRoutes: Routes = [
  {
    path: '',
    canMatch: [actiefVoorMezelfMatcher],
    component: BerichtenNietInTeamPage,
  },
  {
    path: '',
    canMatch: [actiefAlsExJongeMatcher],
    component: BerichtenExJongerePage,
  },
  {
    path: '',
    canMatch: [inTeamMatcher],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: GesprekkenOverviewPage,
      },
      gesprekDetailRoutes,
      {
        path: 'teamlid-add',
        loadChildren: () =>
          import('../team/team-add/team-add.module').then((m) => {
            return m.TeamAddModule;
          }),
      },
      {
        path: 'groepsgesprek-add',
        title: 'berichten.groepsgesprek-add-page.title',
        component: GroepsgesprekAddPage,
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    component: BerichtenNietAangemeldPage,
  },
];
