<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <div class="container-1-col">
        <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let verhaal">
          @if (verhaal.items.length === 0) {
            <p class="text-center mb-8">Je hebt nog geen gebeurtenissen in je verhaal.</p>
          }
        </ng-container>

        <parkour-button class="w-full flex" iconAfter="plus-circle" link="nieuw">
          Gebeurtenis toevoegen
        </parkour-button>

        <div class="mt-8">
          <ng-container
            *parkourSectionContent="
              { data: gebeurtenisSuggesties$ | async, hideSpinner: true };
              let suggesties
            "
          >
            <ul class="space-y-6">
              @for (suggestie of suggesties; track suggestie.id) {
                <li>
                  <parkour-gebeurtenis-voorstel
                    [gebeurtenisSuggestie]="suggestie"
                    (confirm)="onSuggestieConfirm(suggestie)"
                    (reject)="onSuggestieReject(suggestie)"
                  />
                </li>
              }
            </ul>
          </ng-container>
          <ng-container
            *parkourSectionContent="
              {
                data: mainPageData$ | async,
                errorDescription:
                  'We kunnen sommige gebeurtenissen nu niet weergeven, probeer later opnieuw.',
              };
              let verhaal
            "
          >
            <parkour-gebeurtenis-list
              [verhaal]="verhaal"
              [ongelezenGebeurtenisMeldingen]="(ongelezenGebeurtenisMeldingen$ | async) ?? []"
            />
          </ng-container>
        </div>
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
