import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { DoelenPlaceholderComponent } from '../../component/doelen-placeholder/doelen-placeholder.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { Profiel } from '../../../../profiel/model/profiel';
import { ProfielService } from '../../../../profiel/service/profiel.service';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { doelHeroSources } from '../../constants';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { ContactMethodeNietGeverifieerdCardComponent } from '../../../../profiel/component/contact-methode-niet-geverifieerd-card/contact-methode-niet-geverifieerd-card.component';

@Component({
  standalone: true,
  templateUrl: './doel-empty-state-no-team.page.html',
  imports: [
    AsyncPipe,
    TranslateModule,
    PageLayoutComponent,
    DoelenPlaceholderComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonHeader,
    IonContent,
    PageWithHeroComponent,
    PageContentDirective,
    ContactMethodeNietGeverifieerdCardComponent,
  ],
})
export class DoelenEmptyStateNoTeamPage extends ParkourDataPage<Profiel> {
  constructor(private readonly profielService: ProfielService) {
    super();
  }

  override retrieveMainData(): Observable<Profiel> {
    return this.profielService.getCurrentUserProfiel$();
  }

  protected readonly doelHeroSources = doelHeroSources;
}
