import { Component, computed, input } from '@angular/core';
import { ParkourInfoCardWithTitleComponent } from '@parkour/ui';
import { getContactEmailOrTelefoonnummer, getContactMethodeType } from '../../model/profiel';
import { ProfielService } from '../../service/profiel.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'parkour-contact-methode-niet-geverifieerd-card',
  standalone: true,
  imports: [ParkourInfoCardWithTitleComponent, RouterLink, NgClass],
  templateUrl: './contact-methode-niet-geverifieerd-card.component.html',
})
export class ContactMethodeNietGeverifieerdCardComponent {
  constructor(private readonly profielService: ProfielService) {}

  public readonly mijnProfiel = toSignal(this.profielService.userProfielOrUndefined$);
  protected readonly contactMethodeString = computed(() => {
    const profiel = this.mijnProfiel();
    return profiel ? getContactEmailOrTelefoonnummer(profiel) : '';
  });
  public readonly includeTopMargin = input<boolean>(false);
  protected readonly contactMethode = computed(() => {
    const profiel = this.mijnProfiel();
    return profiel ? getContactMethodeType(profiel) : undefined;
  });

  protected readonly titel = computed(() => {
    switch (this.contactMethode()) {
      case 'email':
        return 'Bevestig je e-mailadres';
      case 'gsm-nummer':
        return 'Bevestig je gsm-nummer';
      case undefined:
        return '';
    }
  });
}
