import { Component } from '@angular/core';
import { IonContent, IonHeader } from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';

@Component({
  standalone: true,
  templateUrl: './uitnodiging-geweigerd.page.html',
  imports: [IonHeader, IonContent, PageLayoutComponent],
})
export class UitnodigingGeweigerdPage {}
