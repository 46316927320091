<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        [parkourBackButton]="(standalone$ | async) ? ['../..'] : ['..']"
        defaultHref="doelen"
      />
    </ion-buttons>

    <ion-title>{{ 'watwat.artikel-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient classes="absolute top-0 left-0 before:-translate-y-[5rem]" />

    <div class="relative z-10 min-h-full">
      <ng-container *parkourPageContent="mainPageData$ | async; let artikelResult">
        <div class="py-8 container container-space">
          <parkour-banner [src]="artikelResult.image" alt="title">
            <div class="container-1-col">
              <!-- Todo, check a11y -->
              <h1 class="line-clamp-4 typo-h2">{{ artikelResult.title }}</h1>
            </div>
          </parkour-banner>

          <div class="container-1-col">
            <div class="mt-6 mb-6 flex flex-col self-center gap-y-6">
              <p class="text-center typo-body">
                Door
                <strong class="typo-body variant-body-fat">{{ artikelResult.author.name }}</strong>
              </p>

              <parkour-divider class="inline-block w-full" />

              <div class="flex items-center justify-center gap-x-2">
                <parkour-share-button
                  (shareArtikelOrOrganisation)="onShareArtikel($event)"
                  [artikel]="artikelResult"
                />

                <parkour-bookmark-button
                  (clicked)="onClickedBookmark($event, artikelResult.id, artikelResult.bookmarkId)"
                  [isActive]="!!artikelResult.bookmarkId"
                  class="self-center"
                  variant="button"
                />
              </div>
            </div>

            <div class="flex flex-col gap-y-6 mb-3">
              <parkour-artikel-content
                *ngFor="let content of artikelResult.content"
                [content]="content"
                data-testid="artikel-content"
              />
            </div>

            <ul *parkourShowIfSuccessful="selfTestsResult$ | async; let selfTestsResult">
              @for (result of selfTestsResult; track result) {
                <parkour-artikel-self-test-item [selfTest]="result" />
              }
            </ul>
          </div>
        </div>

        <parkour-helpline-methods [artikel]="artikelResult" />
        <div class="py-8 container container-space">
          <div class="flex items-center justify-center gap-6 px-6 mt-8">
            <p class="typo-body variant-body-small">
              <a
                class="underline typo-body variant-body-fat variant-body-small"
                href="https://watwat.be/"
                >{{ 'watwat.title' | translate }}</a
              >

              {{ 'watwat.description' | translate }}
            </p>

            <parkour-image alt="WatWat" class="max-w-[4.5rem]" src="assets/watwat/logo.svg" />
          </div>
          @if ((standalone$ | async) === false) {
            @if (artikelResult.themas.length > 0) {
              <div class="flex justify-center mt-8">
                <h2 class="typo-h3">
                  Meer artikels over
                  <a
                    [queryParams]="{ thema: artikelResult.themas[0] }"
                    [routerLink]="['..']"
                    class="underline"
                    >{{ themaMapping[artikelResult.themas[0]].label }}
                  </a>
                </h2>
              </div>
            }
          }
        </div>
      </ng-container>
    </div>
  </parkour-page-layout>
</ion-content>
