import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Result } from '../../utils';
import { ParkourLoadingSpinnerComponent } from '@parkour/ui';
import { ContentUnavailableCardComponent } from '../components/content-unavailable-card/content-unavailable-card.component';

type SectionData<T> = {
  data: Result<T> | null;
  errorDescription?: string;
  hideSpinner?: boolean;
};

@Directive({
  standalone: true,
  selector: '[parkourSectionContent]',
})
export class PageSectionDirective<T> {
  constructor(
    private templateRef: TemplateRef<{ $implicit: T }>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  private embeddedViewRef?: EmbeddedViewRef<{ $implicit: T }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private context: any = { $implicit: null };

  static ngTemplateContextGuard<T>(
    dir: PageSectionDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }

  @Input()
  set parkourSectionContent(sectionData: SectionData<T>) {
    if (sectionData.data) {
      if (sectionData.data.success) {
        this.context.$implicit = sectionData.data.value;

        if (!this.embeddedViewRef) {
          this.viewContainerRef.clear();
          this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(
            this.templateRef,
            this.context,
          );
        }
      } else {
        if (sectionData.errorDescription) {
          this.viewContainerRef.clear();
          this.embeddedViewRef = undefined;

          const contentUnavailableCardComponentComponentRef = this.viewContainerRef.createComponent(
            ContentUnavailableCardComponent,
          );
          contentUnavailableCardComponentComponentRef.setInput(
            'description',
            sectionData.errorDescription,
          );
        }
      }
    } else {
      this.embeddedViewRef = undefined;
      this.viewContainerRef.clear();

      if (!sectionData.hideSpinner) {
        this.viewContainerRef.createComponent(ParkourLoadingSpinnerComponent);
      }
    }
  }
}
