import { Injectable } from '@angular/core';
import { isParkourSecureStorageError, ParkourSecureStorage } from 'parkour-secure-storage';
import { catchError, forkJoin, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { HumanReadableError, TranslatedHumanReadableError } from '../../core/human-readable-error';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Device } from '@capacitor/device';
import { User } from '../user';
import { isNativeApp } from '../../utils';
import { MatomoTracker } from 'ngx-matomo-client';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { PersoonId } from 'parkour-web-app-dto';

const BIOMETRICS_STATUS_KEY = 'BIOMETRICS';
const BIOMETRICS_PERSOON_ID = 'BIOMETRICS_PERSOON_ID';

export type BiometricStatus = 'INGESTELD' | 'GEWEIGERD' | 'NIET_INGESTELD';
const BIOMETRICS_TOKEN_KEY = 'token';

const BIOMETRICS_POPUP_TITLE = 'Gebruik biometrie om aan te melden.';
const BIOMETRICS_POPUP_CANCEL = 'Annuleer';

@Injectable({
  providedIn: 'root',
})
export class BiometricsService {
  readonly TRACKER_CATEGORY = 'biometrics';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly http: HttpClient,
    private readonly matomoTracker: MatomoTracker,
  ) {}

  configPopupOpen = false;

  async initialize() {
    if (isNativeApp()) {
      const currentBiometricsStatus =
        await this.localStorageService.getValue(BIOMETRICS_STATUS_KEY);
      if (currentBiometricsStatus === 'INGESTELD') {
        const hasKey = await ParkourSecureStorage.hasKey({ key: BIOMETRICS_TOKEN_KEY });
        if (!hasKey.result) {
          await this.localStorageService.removeValue(BIOMETRICS_STATUS_KEY);
          await this.localStorageService.removeValue(BIOMETRICS_PERSOON_ID);
        }
      }
    }
  }

  private generateBiometricsToken(): Observable<string> {
    return from(Device.getId()).pipe(
      switchMap((deviceId) =>
        this.http.post(
          `${environment.API_BASE_URL}/api/auth/token`,
          {
            deviceId: deviceId.identifier,
          },
          {
            responseType: 'text',
          },
        ),
      ),
    );
  }

  enableBiometrics(persoonId: PersoonId): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'enable');
    return from(ParkourSecureStorage.getAvailabilityStatus()).pipe(
      map((availabilityStatus) => {
        if (
          availabilityStatus &&
          (availabilityStatus.status === 'NOT_ENROLLED' ||
            availabilityStatus.status === 'HARDWARE_UNAVAILABLE')
        ) {
          throw new TranslatedHumanReadableError(
            'biometrics.configure-biometrics.not-enrolled-error',
          );
        }
      }),
      switchMap(this.generateAndSaveBiometricsToken()),
      switchMap(() => {
        return from(
          Promise.all([
            this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'INGESTELD'),
            this.localStorageService.setValue(BIOMETRICS_PERSOON_ID, persoonId),
          ]),
        );
      }),
      map(() => undefined),
    );
  }

  private generateAndSaveBiometricsToken() {
    return () =>
      this.generateBiometricsToken().pipe(
        switchMap((token) =>
          ParkourSecureStorage.save({
            key: BIOMETRICS_TOKEN_KEY,
            value: token,
            title: BIOMETRICS_POPUP_TITLE,
            cancelLabel: BIOMETRICS_POPUP_CANCEL,
          }),
        ),
        catchError((e: Error) => {
          return from(this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'GEWEIGERD')).pipe(
            map(() => {
              throw this.mapError(e);
            }),
          );
        }),
      );
  }

  mapError(e: Error): HumanReadableError {
    if (isParkourSecureStorageError(e)) {
      switch (e.code) {
        case 'NO_LOCKSCREEN':
          return new TranslatedHumanReadableError(
            {
              headerKey: 'biometrics.configure-biometrics.no-lockscreen',
              messageKey: 'biometrics.configure-biometrics.no-lockscreen-message',
            },
            { cause: e },
          );
        default:
          return new TranslatedHumanReadableError('biometrics.configure-biometrics.failed', {
            cause: e,
          });
      }
    } else {
      return new TranslatedHumanReadableError('biometrics.configure-biometrics.failed', {
        cause: e,
      });
    }
  }

  clearBiometrics(): Observable<void> {
    return from(ParkourSecureStorage.remove({ key: BIOMETRICS_TOKEN_KEY })).pipe(
      switchMap(() =>
        Promise.all([
          this.localStorageService.removeValue(BIOMETRICS_STATUS_KEY),
          this.localStorageService.removeValue(BIOMETRICS_PERSOON_ID),
        ]),
      ),
      map(() => undefined),
    );
  }

  disableBiometrics(persoonId: PersoonId): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'disable');
    return from(ParkourSecureStorage.remove({ key: BIOMETRICS_TOKEN_KEY })).pipe(
      switchMap(() =>
        Promise.all([
          this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'GEWEIGERD'),
          this.localStorageService.setValue(BIOMETRICS_PERSOON_ID, persoonId),
        ]),
      ),
      map(() => undefined),
    );
  }

  getStatusForUser(user: User): Observable<BiometricStatus> {
    if (!isNativeApp()) {
      return of('GEWEIGERD');
    }

    return forkJoin([
      from(this.localStorageService.getValue(BIOMETRICS_PERSOON_ID)),
      this.getStatus(),
    ]).pipe(
      map(([persoonId, status]) => {
        if (status === 'NIET_INGESTELD') {
          return 'NIET_INGESTELD';
        } else if (user.type === 'aangemeld' && user.persoonId != persoonId) {
          return 'GEWEIGERD';
        } else {
          return status;
        }
      }),
    );
  }

  getStatus(): Observable<BiometricStatus> {
    return from(this.localStorageService.getValue(BIOMETRICS_STATUS_KEY)).pipe(
      map((statusString) => {
        if (statusString == null) {
          return 'NIET_INGESTELD';
        } else {
          return statusString as BiometricStatus;
        }
      }),
    );
  }

  getBiometricsToken(): Observable<string> {
    return from(
      ParkourSecureStorage.get({
        key: BIOMETRICS_TOKEN_KEY,
        title: BIOMETRICS_POPUP_TITLE,
        cancelLabel: BIOMETRICS_POPUP_CANCEL,
      }),
    ).pipe(
      map((token) => {
        if (!token.value) {
          throw new Error('Biometrics token is null');
        }
        return token.value;
      }),
      catchError((err: Error) => {
        if (
          'code' in err &&
          (err.code === 'ITEM_NOT_FOUND' || err.code === 'BIOMETRICS_INVALIDATED')
        ) {
          return this.clearBiometrics().pipe(switchMap(() => throwError(() => err)));
        } else {
          throw err;
        }
      }),
    );
  }
}
