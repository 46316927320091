<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="(standalone$ | async) ? ['../..'] : ['..']" />
    </ion-buttons>

    <ion-title>{{ 'watwat.hulplijn-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient classes="absolute top-0 left-0 before:-translate-y-[5rem]" />

    <div class="relative z-10 pt-15 pb-8 container container-space flex flex-col gap-y-6">
      <ng-container *parkourPageContent="mainPageData$ | async; let organisation">
        <div class="container-1-col flex flex-col items-center gap-y-6">
          <parkour-image
            [src]="organisation.image"
            class="w-[6rem] bg-n-1000 rounded"
            classes="flex rounded-sm aspect-square overflow-hidden"
            fit="contain"
          />

          <h1>{{ organisation.title }}</h1>

          <p class="text-center typo-body px-4">
            {{ organisation.description }}
          </p>

          <div class="flex items-center justify-center gap-x-2">
            <parkour-share-button
              (shareArtikelOrOrganisation)="onShareHulplijn($event)"
              [organisation]="organisation"
            />

            <parkour-bookmark-button
              (clicked)="onClickedBookmark($event, organisation.id, organisation.bookmarkId)"
              [isActive]="!!organisation.bookmarkId"
              class="self-center"
              variant="button"
            />
          </div>
        </div>

        <parkour-watwat-helplines [helplines]="[organisation]" class="container-1-col" />

        <div class="container-1-col flex flex-col gap-y-6">
          <parkour-artikel-content
            *ngFor="let content of organisation.content"
            [content]="content"
            data-testid="artikel-content"
          />
        </div>

        <div class="container-1-col flex items-center justify-center gap-6 px-6">
          <p class="typo-body variant-body-small">
            <a
              class="underline typo-body variant-body-fat variant-body-small"
              href="https://watwat.be/"
              >{{ 'watwat.title' | translate }}</a
            >

            {{ 'watwat.description' | translate }}
          </p>

          <parkour-image alt="WatWat" class="max-w-[4.5rem]" src="assets/watwat/logo.svg" />
        </div>
      </ng-container>

      <div class="container-1-col">
        <h2 class="typo-h3 mb-4">
          {{ 'watwat.hulplijn-page.more-helplines' | translate }}
        </h2>

        <ul class="flex flex-col gap-y-2">
          <ng-container *ngIf="mostRecentOrganisations$ | async as mostRecentOrganisations">
            @for (organisation of mostRecentOrganisations; track organisation.id) {
              @if (organisation.id === teleOnthaalOrganisation.id) {
                <li>
                  <parkour-helpline-card-item
                    [segmentsToHulplijn]="['..', String(teleOnthaalOrganisation.id)]"
                    [organisation]="teleOnthaalOrganisation"
                    titleTag="h3"
                  />
                </li>
              } @else {
                <li>
                  <parkour-helpline-card-item
                    [organisation]="organisation"
                    [segmentsToHulplijn]="['..', String(organisation.id)]"
                    titleTag="h3"
                  />
                </li>
              }
            }
          </ng-container>
        </ul>

        @if ((standalone$ | async) === false) {
          <parkour-button
            [link]="['..']"
            class="self-center block w-full mt-4"
            iconAfter="chevron-right"
            variant="ghost"
          >
            Alle hulplijnen
          </parkour-button>
        }
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
