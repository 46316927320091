import { inject, Injectable } from '@angular/core';
import { SucceservaringenService } from './succeservaringen.service';
import { TParkourIconName } from '@parkour/ui';
import { SupportedSucceservaringType } from '../shared/model/succeservaring';
import { combineLatest, map } from 'rxjs';
import { ProfielService } from '../profiel/service/profiel.service';
import { Profiel } from '../profiel/model/profiel';
import { SucceservaringDto } from 'parkour-web-app-dto';

export type Startopdracht = {
  icon: TParkourIconName;
  title: string;
  body: string;
  succeservaringType: SupportedSucceservaringType;
};

const bewaarEenArtikelOpdracht: Startopdracht = {
  title: 'Bewaar een artikel',
  body: 'Hou interessante artikels bij.',
  icon: 'bookmark',
  succeservaringType: 'ARTIKEL_BEWAARD',
};

const bewaarEenHulplijnOpdracht: Startopdracht = {
  title: 'Bewaar een hulplijn',
  body: 'Hou interessante hulplijnen bij.',
  icon: 'bookmark',
  succeservaringType: 'HULPLIJN_BEWAARD',
};

const voegEenNotitieToeOpdracht: Startopdracht = {
  title: 'Voeg een notitie toe',
  body: 'Hou interessante notities bij.',
  icon: 'bookmark',
  succeservaringType: 'NOTITIE_BEWAARD',
};

@Injectable({
  providedIn: 'root',
})
export class StartopdrachtenService {
  private readonly succeservaringenService = inject(SucceservaringenService);
  private readonly profielService = inject(ProfielService);
  private readonly jongereOpdrachten: Startopdracht[] = [
    {
      title: 'Nodig een teamlid uit',
      body: 'Vind steun bij je team.',
      icon: 'people',
      succeservaringType: 'TEAMLID_GEACTIVEERD',
    },
    {
      title: 'Voeg je eerste doel toe',
      body: 'Werk met je team aan je doelen.',
      icon: 'target',
      succeservaringType: 'DOEL_TOEGEVOEGD',
    },
    bewaarEenArtikelOpdracht,
    {
      title: 'Voeg je eerste gebeurtenis toe',
      body: 'Deel je verhaal met je team.',
      icon: 'compass',
      succeservaringType: 'GEBEURTENIS_TOEGEVOEGD',
    },
    bewaarEenHulplijnOpdracht,
    {
      title: 'Zet je eerste stap bij een doel',
      body: 'Vink een eerste stap af bij het bereiken van je doel.',
      icon: 'check',
      succeservaringType: 'DOEL_STAP_AFGEVINKT',
    },
    {
      title: 'Voeg een afspraak toe',
      body: 'Zet een herinnering in je kalender.',
      icon: 'calendar',
      succeservaringType: 'AFSPRAAK_TOEGEVOEGD',
    },
    voegEenNotitieToeOpdracht,
  ];

  private readonly persoonlijkTeamlidOpdrachten: Startopdracht[] = [
    {
      title: 'Neem deel aan een team',
      body: 'Laat je uitnodigen in een team.',
      icon: 'people',
      succeservaringType: 'UITNODIGING_AANVAARD',
    },
    bewaarEenArtikelOpdracht,
    {
      title: 'Stel een doel voor',
      body: 'Help een jongere met een doel.',
      icon: 'target',
      succeservaringType: 'DOEL_VOORGESTELD',
    },
    bewaarEenHulplijnOpdracht,
    {
      title: 'Stel een gebeurtenis voor',
      body: 'Help een jongere met hun verhaal.',
      icon: 'compass',
      succeservaringType: 'GEBEURTENIS_VOORGESTELD',
    },
    voegEenNotitieToeOpdracht,
    {
      title: 'Reageer op een doel',
      body: 'Geef een aanmoediging bij een doel van een jongere.',
      icon: 'smile',
      succeservaringType: 'EMOJI_REACTIE_DOEL_TOEGEVOEGD',
    },
  ];
  private readonly professioneelTeamlidOpdrachten: Startopdracht[] = [
    ...this.persoonlijkTeamlidOpdrachten,
    {
      title: 'Plan een videogesprek',
      body: 'Nodig een jongere uit om te videobellen.',
      icon: 'calendar',
      succeservaringType: 'BEELDBELAFSPRAAK_GEPLAND',
    },
  ];

  public readonly startopdrachten$ = combineLatest([
    this.succeservaringenService.succeservaringCounts,
    this.profielService.userProfielOrUndefined$,
  ]).pipe(map(([counts, profiel]) => this.getStartopdrachtenForProfiel(profiel, counts)));

  private getStartopdrachtenForProfiel(
    profiel: Profiel | undefined,
    counts: SucceservaringDto[],
  ): Startopdracht[] {
    let opdrachten: Startopdracht[] = [];
    switch (profiel?.type) {
      case 'JONGERE': {
        opdrachten = this.jongereOpdrachten;
        break;
      }
      case 'EX_JONGERE': {
        opdrachten = [];
        break;
      }
      case 'PERSOONLIJK_TEAMLID': {
        opdrachten = this.persoonlijkTeamlidOpdrachten;
        break;
      }
      case 'PROFESSIONEEL_TEAMLID': {
        opdrachten = this.professioneelTeamlidOpdrachten;
        break;
      }
    }
    return opdrachten.filter(
      (opdracht) => !counts.map((count) => count.type).includes(opdracht.succeservaringType),
    );
  }
}
