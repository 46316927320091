import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Capacitor, CapacitorHttp, HttpResponse } from '@capacitor/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  forkJoin,
  from,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { LoggingService } from '../../core/logging.service';
import { BiometricsService } from './biometrics.service';
import { asType, isNonNullable, stripNullProperties } from '../../utils';
import { HttpClient } from '@angular/common/http';
import {
  ExterneHoedanigheid,
  MijnProfielDto,
  MyProfielUpsertDto,
  ProfielId,
  SessionType,
  UserDto,
} from 'parkour-web-app-dto';
import { ParkourPopupService, PopupResult } from '@parkour/ui';
import { TranslateService } from '@ngx-translate/core';
import {
  AangemeldeUser,
  AnoniemeUser,
  DetailedUser,
  GeenProfielUser,
  RetrieveUserCause,
  User,
  validateAangemeldeUser,
} from '../user';
import { BiometricsAuthService } from './biometrics-auth.service';
import { AcmIdmAuthService } from './acm-idm-auth.service';
import { ProfielService } from '../../profiel/service/profiel.service';
import { LoginResult } from './background-detection.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';
import { ProfielCreateService } from '../../profiel/service/profiel-create.service';
import { DebugError } from '../../core/human-readable-error';
import { random } from 'lodash';
import { LastContextService } from '../../shared/services/last-context.service';

export type RedirectConfig = {
  redirectUrl?: string;
  redirectProfielId?: ProfielId;
  loginSession?: number;
};

export const BIOMETRICS_LOGIN_MODAL_ID = 'biometrics-login-modal';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  private handlingTimeout: false | RetrieveUserCause = false;

  get detailedUser$(): Observable<DetailedUser> {
    return this._detailedUser$.pipe(isNonNullable());
  }

  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  constructor(
    private readonly router: Router,
    private readonly loggingService: LoggingService,
    private readonly biometricsService: BiometricsService,
    private readonly popupService: ParkourPopupService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly ngZone: NgZone,
    private readonly biometricsAuthService: BiometricsAuthService,
    private readonly acmIdmAuthService: AcmIdmAuthService,
    private readonly httpClient: HttpClient,
    private readonly profielService: ProfielService,
    private readonly location: Location,
    private readonly analyticsService: AnalyticsService,
    private readonly profielCreateService: ProfielCreateService,
    private readonly lastContextService: LastContextService,
  ) {
    this.user$.subscribe((user) => {
      this.loggingService.log('User changed', user);
    });

    this.detailedUser$.subscribe((user) => {
      this.loggingService.log('Detailed user changed', user);
    });
  }

  private readonly _detailedUser$ = new BehaviorSubject<DetailedUser | undefined>(undefined);
  private readonly _user$ = new ReplaySubject<User>(1);

  public isHandlingTimeout(): boolean {
    return this.handlingTimeout !== false;
  }

  public loadInitialUser() {
    this.retrieveUser('initial').subscribe();
  }

  public getCurrentUser$(): Observable<User> {
    return this.user$.pipe(take(1));
  }

  public getAangemeldeUser$(): Observable<AangemeldeUser> {
    return this.getCurrentUser$().pipe(map((user: User) => validateAangemeldeUser(user)));
  }

  public hasJongereHoedanigheid = (): Observable<boolean> => {
    return this.getExterneHoedanigheid().pipe(
      map((externeHoedanigheid) => externeHoedanigheid === 'JONGERE'),
    );
  };

  public getGeenProfielOrAangemeldeUser$(): Observable<AangemeldeUser | GeenProfielUser> {
    return this.detailedUser$.pipe(
      take(1),
      map((user) => {
        if (user.type !== 'geen-profiel' && user.type !== 'aangemeld') {
          throw new Error('User type is not aangemeld or geen-profiel');
        }

        return user;
      }),
    );
  }

  public selectProfiel(
    profielId: ProfielId,
    redirectUrl?: string,
    loginSession?: number,
  ): Observable<void> {
    return this.httpClient
      .put<UserDto>(`${environment.API_BASE_URL}/api/users/current/profiel`, { profielId })
      .pipe(switchMap(() => this.retrieveUser('profiel-selected', redirectUrl, loginSession)));
  }

  public switchProfiel(profielId: ProfielId, redirectUrl: string): Observable<void> {
    return this.selectProfiel(profielId, redirectUrl + '?noBack=true').pipe(
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('profiel', 'profielGewisseld')),
    );
  }

  private getUserFromSession(): Observable<DetailedUser> {
    return this.httpClient.get<UserDto>(`${environment.API_BASE_URL}/api/users/current`).pipe(
      switchMap((dto) => this.userFromDto(dto)),
      catchError(() => {
        const currentUser = this._detailedUser$.getValue();
        if (!currentUser) {
          return of<AnoniemeUser>({ type: 'anoniem', error: true });
        } else {
          return of<DetailedUser>({ ...currentUser, error: true });
        }
      }),
    );
  }

  private userFromDto(dto: UserDto): Observable<DetailedUser> {
    switch (dto.role) {
      case 'ANONYMOUS':
        return of<AnoniemeUser>({ type: 'anoniem', error: false });
      case 'PERSOON':
        return forkJoin([this.getMijnProfielen(), this.getExterneHoedanigheid()]).pipe(
          map(([profielen, hoedanigheid]) =>
            asType<GeenProfielUser>({
              type: 'geen-profiel',
              voornaam: dto.voornaam,
              naam: dto.naam,
              persoonId: dto.persoonId,
              profielOpties: profielen.map((profiel) => profiel.id),
              hoedanigheid,
              error: false,
            }),
          ),
        );
      case 'AANGEMELD':
        return this.getExterneHoedanigheid().pipe(
          map((hoedanigheid) => ({
            type: 'aangemeld',
            profielId: dto.profiel.id,
            persoonId: dto.persoonId,
            voornaam: dto.voornaam,
            naam: dto.naam,
            hoedanigheid,
            error: false,
          })),
        );
    }
  }

  private loginSession: number | undefined;

  public retrieveUser(
    cause: RetrieveUserCause,
    redirectUrl?: string,
    loginSession?: number,
  ): Observable<void> {
    this.loggingService.log(`Retrieving user with cause: ${cause}`);
    if (this.loginSession !== loginSession) {
      if (redirectUrl) {
        this.loggingService.error(
          `User retrieval already in progress, redirectUrl ${redirectUrl} ignored!`,
        );
      } else {
        this.loggingService.log('User retrieval already in progress, ignored');
      }

      return of(undefined);
    }

    let newLoginSession = loginSession;
    if (!newLoginSession) {
      newLoginSession = random(1, 1000000);
      this.loginSession = newLoginSession;
    }

    return this.getUserFromSession().pipe(
      switchMap((user) => {
        const currentUser = this._detailedUser$.getValue();
        this._detailedUser$.next(user);

        if (
          cause !== 'logout' &&
          currentUser &&
          user.type === 'anoniem' &&
          this.isTimeouted(currentUser, user)
        ) {
          this.loggingService.log('User timeout');
          this._user$.next(user);
          return this.handleTimeout(cause, currentUser, newLoginSession, redirectUrl);
        } else if (user.type === 'geen-profiel') {
          return this.handleGeenProfiel(user, newLoginSession, redirectUrl);
        } else {
          this._user$.next(user);
          return this.profielService.retrieveProfiel(user).pipe(
            switchMap(async () => {
              {
                if (redirectUrl && !(user.type === 'anoniem' && user.error)) {
                  // Clear session before navigation due to navigation potentially triggering login
                  this.loginSession = undefined;
                  await this.router.navigateByUrl(redirectUrl);
                }
              }
            }),
          );
        }
      }),
      finalize(() => (this.loginSession = undefined)),
    );
  }

  private handleGeenProfiel(
    user: GeenProfielUser,
    loginSession: number,
    redirectUrl?: string,
  ): Observable<void> {
    const redirectUrlWithFallback = redirectUrl ?? this.location.path();

    if (this.location.path().includes('start/profiel')) {
      return of(undefined);
    }

    if (user.profielOpties.length > 0) {
      return from(
        this.router.navigate(['/app', 'start', 'profiel', 'selecteer'], {
          queryParams: { redirectUrl: redirectUrlWithFallback },
        }),
      ).pipe(map(() => undefined));
    } else {
      return from(
        this.profielCreateService.startCreatingProfiel(
          redirectUrlWithFallback,
          this.createMyProfiel,
          this.hasJongereHoedanigheid,
          'unspecified',
        ),
      ).pipe(map(() => undefined));
    }
  }

  private isTimeouted(oldUser: DetailedUser, user: DetailedUser): boolean {
    return user.type === 'anoniem' && oldUser.type !== 'anoniem';
  }

  private handleTimeout(
    cause: Exclude<RetrieveUserCause, 'logout'>,
    oldUser: DetailedUser,
    loginSession: number,
    redirectUrl?: string,
  ): Observable<void> {
    if (this.handlingTimeout) throw new DebugError(`Already logging in from ${cause}`);

    this.handlingTimeout = cause;
    const oldProfielId = oldUser.type === 'aangemeld' ? oldUser.profielId : undefined;
    const redirectConfig = { redirectProfielId: oldProfielId, redirectUrl, loginSession };

    let timeoutHandleObservable: Observable<void>;
    switch (cause) {
      case 'profiel-selected':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
      case 'refresh':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
      case 'from-background':
        timeoutHandleObservable = this.loginFromTimeoutAfterResume(redirectConfig);
        break;
      case 'from-deeplink':
        timeoutHandleObservable = this.loginFromTimeoutAfterResume(redirectConfig);
        break;
      case 'login':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
      case 'initial':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
      case 'profiel-created':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
      case '401':
        timeoutHandleObservable = this.loginFromTimeoutWhenActive(redirectConfig);
        break;
    }

    return timeoutHandleObservable.pipe(finalize(() => (this.handlingTimeout = false)));
  }

  public login(redirectConfig: RedirectConfig): Observable<void> {
    this.loggingService.log('Login started', redirectConfig);
    return this.biometricsService.getStatus().pipe(
      map((status) => status === 'INGESTELD'),
      switchMap((biometricsEnabled) => {
        if (biometricsEnabled) {
          return this.biometricsAuthService.startBiometricsAuthFlow(redirectConfig);
        } else {
          return this.acmIdmAuthService.startAcmIdmAuthFlow(redirectConfig);
        }
      }),
      switchMap((result) => this.finishLogin(result, redirectConfig)),
      finalize(() => this.biometricsAuthService.closeModalIfOpen()),
    );
  }

  private finishLogin(loginResult: LoginResult, redirectConfig: RedirectConfig) {
    return this.finishLoginDependingOnResultType(loginResult, redirectConfig);
  }

  private finishLoginDependingOnResultType(
    loginResult: LoginResult,
    redirectConfig: RedirectConfig,
  ): Observable<void> {
    switch (loginResult.type) {
      case 'biometric-login': {
        return this.retrieveUser('login', redirectConfig.redirectUrl, redirectConfig.loginSession);
      }
      case 'acm-idm-login': {
        // acm-idm login redirects to the login page, so we don't need to do anything here
        return of(undefined);
      }
      case 'home': {
        return from(this.navigateToHomePage());
      }
      case 'canceled':
        // login is interupted by other login
        return of(undefined);
    }
  }

  private getLocationFromLoginResponse(loginResponse: HttpResponse) {
    if (loginResponse.headers['location']) {
      return loginResponse.headers['location'];
    } else if (loginResponse.headers['Location']) {
      return loginResponse.headers['Location'];
    } else {
      throw Error('RedirectUrl cannot be empty');
    }
  }

  public async handleAuthenticationCallbackForMobileApp(callbackSlug: string) {
    try {
      let callbackUrl = `${environment.API_BASE_URL}${callbackSlug}`;
      if (Capacitor.getPlatform() === 'android') {
        callbackUrl = `${callbackUrl}&mobile`;
      }
      const authenticateResponse = await CapacitorHttp.get({
        url: callbackUrl,
        disableRedirects: true,
      });
      const location = this.getLocationFromLoginResponse(authenticateResponse);
      if (authenticateResponse.status === 302 && location) {
        this.ngZone.run(() => {
          this.retrieveUser('login', location).subscribe();
        });
      } else {
        throw Error(`Expected redirect but got ${authenticateResponse.status} instead`);
      }
    } catch (error) {
      this.loggingService.error(`Login failed: ${JSON.stringify(error)}`);
      await this.router.navigateByUrl('/failed');
    }
  }

  private getSessionType(): Observable<SessionType> {
    return this.http
      .get(`${environment.API_BASE_URL}/api/auth/session/type`, { responseType: 'text' })
      .pipe(map((response) => response as SessionType));
  }

  public logout(): Observable<void> {
    return this.getSessionType().pipe(
      switchMap((sessionType) => {
        if (sessionType === 'web' || sessionType === 'native_acm_idm') {
          switch (Capacitor.getPlatform()) {
            case 'ios':
              return this.acmIdmAuthService.logoutOfIOS();
            case 'android':
              return this.acmIdmAuthService.logoutOfAndroid();
            default: {
              window.location.href = `${environment.API_BASE_URL}/logout`;
              return of(undefined);
            }
          }
        } else if (sessionType === 'native_biometrics') {
          return this.http
            .delete(`${environment.API_BASE_URL}/api/auth/session`)
            .pipe(switchMap(() => this.retrieveUser('logout', '/')));
        } else {
          return of(undefined);
        }
      }),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('auth', 'logout')),
    );
  }

  private loginFromTimeoutAfterResume(redirectConfig: RedirectConfig): Observable<void> {
    return this.biometricsService.getStatus().pipe(
      map((status) => status === 'INGESTELD'),
      switchMap((biometricsEnabled) => {
        if (biometricsEnabled) {
          return this.biometricsAuthService.startBiometricsAuthFlow(redirectConfig);
        } else {
          return this.openTimeoutPopup().pipe(
            switchMap((loginAgainResult) => {
              switch (loginAgainResult) {
                case 'yes':
                  return this.acmIdmAuthService.startAcmIdmAuthFlow(redirectConfig);
                case 'no':
                  return of<LoginResult>({ type: 'home' });
                case 'closed-externally':
                  return of<LoginResult>({ type: 'canceled' });
              }
            }),
          );
        }
      }),
      switchMap((result) => this.finishLogin(result, redirectConfig)),
      finalize(() => this.biometricsAuthService.closeModalIfOpen()),
    );
  }

  private loginFromTimeoutWhenActive(redirectConfig: RedirectConfig): Observable<void> {
    this.loggingService.log('Start login on timeout when app is active');
    return this.openTimeoutPopup().pipe(
      switchMap((loginAgainResult) => {
        switch (loginAgainResult) {
          case 'yes':
            return this.login(redirectConfig);
          case 'no':
            return this.navigateToHomePage();
          case 'closed-externally':
            return of(undefined);
        }
      }),
    );
  }

  private async navigateToHomePage() {
    await this.router.navigateByUrl('/', {
      onSameUrlNavigation: 'reload',
      info: { overridePopups: true },
    });
  }

  private openTimeoutPopup(): Observable<PopupResult> {
    return this.translateService
      .get(['afmelden.expired-title', 'afmelden.expired-description'])
      .pipe(
        switchMap((translations) =>
          this.popupService.showPopup({
            icon: 'logout',
            title: translations['afmelden.expired-title'],
            description: translations['afmelden.expired-description'],
          }),
        ),
      );
  }

  private getExterneHoedanigheid(): Observable<ExterneHoedanigheid> {
    return this.httpClient
      .get(`${environment.API_BASE_URL}/api/externe-info/hoedanigheid`, { responseType: 'text' })
      .pipe(map((dto) => dto as ExterneHoedanigheid));
  }

  private getMijnProfielen(): Observable<MijnProfielDto[]> {
    return this.http.get<MijnProfielDto[]>(`${environment.API_BASE_URL}/api/profiel/me/all`);
  }

  public createMyProfiel = (
    profiel: MyProfielUpsertDto,
    redirectUrl?: string,
  ): Observable<void> => {
    return this.http
      .post(
        `${environment.API_BASE_URL}/api/profiel/me`,
        asType<MyProfielUpsertDto>(stripNullProperties(profiel)),
        { responseType: 'text' },
      )
      .pipe(
        switchMap(() => this.retrieveUser('profiel-created', redirectUrl)),
        tap(() => this.profielService.profielAdded$.next()),
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('profiel', 'profielAangemaakt', profiel.type),
        ),
      );
  };
}
