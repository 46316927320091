<div class="flex flex-col gap-y-4">
  <form (ngSubmit)="onFormSubmit()" class="flex flex-col gap-y-8" id="notitie-form">
    <div class="flex flex-col gap-y-4">
      <parkour-form-field
        [additionalErrors]="notitieForm.controls.titel.errors"
        [errorMessages]="{ required: 'Vul een titel in.', maxlength: 'Deze titel is te lang.' }"
        [inputFormControl]="notitieForm.controls.titel"
        [label]="'bewaard.notitie-titel' | translate"
        [required]="true"
        inputId="notitie-titel"
      >
        <parkour-input
          [autocomplete]="null"
          [formControl]="notitieForm.controls.titel"
          [required]="true"
          inputId="notitie-titel"
          testId="notitie-titel"
        />
      </parkour-form-field>

      <parkour-form-field
        [additionalErrors]="notitieForm.controls.beschrijving.errors"
        [errorMessages]="{ maxlength: 'Deze notitie is te lang.' }"
        [inputFormControl]="notitieForm.controls.beschrijving"
        [label]="'bewaard.notitie' | translate"
        inputId="notitie-beschrijving"
      >
        <parkour-textarea
          [formControl]="notitieForm.controls.beschrijving"
          inputId="notitie-beschrijving"
          testId="notitie-beschrijving"
        />
      </parkour-form-field>
    </div>

    @if (type === 'default') {
      <parkour-button class="block w-full" iconAfter="disk" type="submit">
        {{ 'bewaard.notitie-save' | translate }}
      </parkour-button>
    }
  </form>
</div>
