<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="berichtenHero"
      [title]="'berichten.gesprekken-overview-page.title' | translate"
    >
      <ng-container *parkourPageContent="mainPageData$ | async; let data">
        <div class="space-y-8 container-1-col">
          <p class="text-center">
            @if (data.type !== 'PROFESSIONEEL_TEAMLID') {
              {{ 'berichten.gesprekken-overview-page.niet-in-team' | translate }}
            } @else {
              {{ 'berichten.gesprekken-overview-page.niet-in-team-professioneel' | translate }}
            }
          </p>

          <parkour-berichten-placeholder />
        </div>
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
