import { Component } from '@angular/core';
import { ParkourInfoCardWithTitleComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'parkour-ex-jongere-info-card',
  standalone: true,
  imports: [ParkourInfoCardWithTitleComponent, TranslateModule, AsyncPipe],
  templateUrl: './ex-jongere-info-card.component.html',
})
export class ExJongereInfoCardComponent {
  constructor() {}
}
