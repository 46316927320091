import { Component } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { ParkourPage } from '../../../shared/parkour-page';
import { Observable } from 'rxjs';
import { CmsPaginaDto } from 'parkour-web-app-dto';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourDividerComponent, ParkourPageGradientComponent } from '@parkour/ui';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { FaqContentComponent } from '../../../cms/faq-content/faq-content.component';
import { AdaptToSidebarVisibleDirective } from '../../../shared/directives/adapt-to-sidebar-visible.directive';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { CmsService } from '../../../cms/cms.service';

@Component({
  templateUrl: './veelgestelde-vragen.page.html',
  standalone: true,
  imports: [
    CoreModule,
    PageLayoutComponent,
    ParkourPageGradientComponent,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonTitle,
    IonBackButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    ParkourDividerComponent,
    FaqContentComponent,
    AdaptToSidebarVisibleDirective,
    ParkourBackButtonDirective,
  ],
})
export class VeelgesteldeVragenPage extends ParkourPage {
  protected faqPagina: Observable<CmsPaginaDto> = this.cmsService.getCmsPagina();

  constructor(private readonly cmsService: CmsService) {
    super();
  }
}
