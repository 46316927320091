<div data-testid="gebeurtenis-list">
  @if (verhaal.error) {
    <parkour-gebeurtenis-error-card class="block mt-4" />
  }

  @if (verhaal.items.length > 0 || verhaal.error) {
    <ul class="mt-6">
      @for (item of verhaal.items; track item.id; let index = $index) {
        <li>
          <parkour-gebeurtenis-list-item
            [isLastItem]="verhaal.items.length - 1 === index"
            [ongelezenGebeurtenisMeldingen]="ongelezenGebeurtenisMeldingen"
            [verhaalItem]="item"
          />
        </li>
      }
    </ul>
  } @else {
    <parkour-verhaal-placeholder />
  }
</div>
