<div class="divide-y divide-n-600/30 bg-n-300 rounded">
  <div class="p-4">
    @if (gebeurtenisSuggestie.gebeurtenisData.imageId) {
      <parkour-image
        alt=""
        class="block mb-4"
        classes="aspect-video rounded-sm overflow-hidden"
        [src]="
          { id: gebeurtenisSuggestie.id, imageId: gebeurtenisSuggestie.gebeurtenisData.imageId }
            | suggestieImage
            | async
        "
      />
    }

    <div class="flex gap-x-2 justify-between">
      <div class="flex flex-col gap-y-2">
        <p class="typo-h5">{{ gebeurtenisSuggestie.gebeurtenisData.titel }}</p>

        <span class="typo-body text-n-700">{{
          gebeurtenisSuggestie.gebeurtenisData.startDatum | date
        }}</span>
      </div>

      @if (gebeurtenisSuggestie.gebeurtenisData.bijlagen.length > 0) {
        <parkour-icon
          [label]="'verhaal.overview-page.voorstel-attachment' | translate"
          name="attachment"
          size="3"
        />
      }
    </div>
  </div>

  <div class="p-4 flex gap-x-2 items-center">
    @if (gebeurtenisSuggestie.suggestedById | profiel | async; as suggestedByProfiel) {
      <parkour-profiel-foto-badge
        [alt]="suggestedByProfiel | profielnaam"
        [profiel]="suggestedByProfiel"
        [size]="'2'"
      />

      <p
        [innerHtml]="
          'verhaal.overview-page.voorstel'
            | translate
              : {
                  name: suggestedByProfiel | profielnaam,
                  date: gebeurtenisSuggestie.aangemaaktOpTijdstip | date,
                }
        "
      ></p>
    }
  </div>

  <div class="p-4 flex flex-col gap-y-6">
    <p>{{ 'verhaal.overview-page.voorstel-add' | translate }}</p>

    <div class="flex gap-x-4">
      <parkour-button (clicked)="onNo()" class="w-full" variant="tertiary">
        {{ 'buttons.nee' | translate }}
      </parkour-button>

      <parkour-button (clicked)="onYes()" class="w-full" variant="secondary"
        >{{ 'buttons.ja' | translate }}
      </parkour-button>
    </div>
  </div>
</div>
