import { Routes } from '@angular/router';
import { specificHulplijnRoute } from '../../watwat/routes';
import { verhaalCreateRoutes } from '../verhaal-create-routes';
import { DrieKolommenGebeurtenisDetailsPage } from '../verhaal-shared/page/drie-kolommen-gebeurtenis-details-page/drie-kolommen-gebeurtenis-details.page';
import { VerhaalForJongerePage } from './verhaal-jongere-page/verhaal-jongere-page.component';
import { ParkourGebeurtenisEditPage } from './parkour-gebeurtenis-edit-page/parkour-gebeurtenis-edit.page';
import { dirtyFormGuard } from '../../shared/components/dirty-form-guard';
import { GebeurtenisDetailsJongerePage } from './gebeurtenis-details-jongere-page/gebeurtenis-details-jongere.page';
import { JeugdhulpHistoriekEditPage } from './jeugdhulp-historiek-edit-page/jeugdhulp-historiek-edit-page.component';

export const verhaalJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VerhaalForJongerePage,
  },
  {
    path: 'nieuw',
    children: verhaalCreateRoutes,
  },
  {
    path: 'gebeurtenis/:gebeurtenis-id',
    children: [
      {
        path: 'wijzig',
        component: ParkourGebeurtenisEditPage,
        canDeactivate: [dirtyFormGuard],
      },
      {
        path: '',
        component: GebeurtenisDetailsJongerePage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'eyouth/:gebeurtenis-id',
    children: [
      {
        path: 'wijzig',
        component: JeugdhulpHistoriekEditPage,
        canDeactivate: [dirtyFormGuard],
      },
      {
        path: '',
        component: GebeurtenisDetailsJongerePage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'document/:gebeurtenis-id',
    children: [
      {
        path: '',
        component: DrieKolommenGebeurtenisDetailsPage,
        pathMatch: 'full',
      },
      specificHulplijnRoute,
    ],
  },
];
