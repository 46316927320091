import { Component } from '@angular/core';
import { UitnodigingenService } from '../../../uitnodiging/service/uitnodinginen.service';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourIconComponent,
  ParkourImageComponent,
} from '@parkour/ui';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';

@Component({
  standalone: true,
  templateUrl: './profiel-create-succes.page.html',
  imports: [
    AsyncPipe,
    NgIf,
    ParkourIconComponent,
    ParkourDividerComponent,
    ParkourImageComponent,
    ProfielFotoPipe,
    ProfielnaamPipe,
    ParkourButtonComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
  ],
})
export class ProfielCreateSuccesPage implements ViewWillEnter {
  userProfiel$ = this.profielService.getCurrentUserProfiel$();
  hasUitnodiging = false;

  constructor(
    private readonly profielService: ProfielService,
    private readonly uitnodigingenService: UitnodigingenService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  protected getRedirectUrl(): UrlTree {
    const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');

    if (!redirectUrl || redirectUrl === '') {
      return this.router.createUrlTree(['/']);
    }

    const urlTree = this.router.parseUrl(decodeURIComponent(redirectUrl));
    urlTree.queryParams['noBack'] = 'true';
    return urlTree;
  }

  onNavigateToRedirectUrl() {
    this.router.navigateByUrl(this.getRedirectUrl());
  }

  ionViewWillEnter(): void {
    this.hasUitnodiging = this.uitnodigingenService.hasAangekomenUitnodigingId();
  }
}
