import { Injectable, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { filter, of, switchMap, tap } from 'rxjs';
import AuthService from '../../authentication/service/auth.service';
import { LoggingService } from '../logging.service';
import BackgroundDetectionService from '../../authentication/service/background-detection.service';

export const PARKOUR_SCHEME = 'be.opgroeien.parkour';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkingService {
  constructor(
    private readonly zone: NgZone,
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
    private readonly backgroundDetectionService: BackgroundDetectionService,
  ) {}

  configureDeepLinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.loggingService.log('App opened with URL: ' + event.url);
      this.zone.run(() => {
        this.backgroundDetectionService.markAsFromUrl();
        this.routeToDeeplink(event.url);
      });
    });
  }

  routeToDeeplink(url: string) {
    let slug: string | undefined;
    if (url.startsWith(PARKOUR_SCHEME)) {
      slug = url.split(/be\.opgroeien\.parkour:\//).pop();
    }
    if (url.startsWith('https://parkour')) {
      slug = url.split(/parkour.*\.opgroeien\.be/).pop();
    }
    this.loggingService.log(`Redirecting to slug ${slug}`);
    if (slug === '/') {
      this.authService
        .getCurrentUser$()
        .pipe(
          filter((user) => user.type !== 'anoniem'),
          tap(() => this.loggingService.log('Navigated to / consider the user logged out')),
          switchMap(() => this.authService.retrieveUser('logout', '/')),
        )
        .subscribe();
      return;
    }
    if (slug?.startsWith('/login/oauth2/code/idm')) {
      of(this.authService.handleAuthenticationCallbackForMobileApp(slug)).subscribe();
    } else if (slug?.startsWith('/app/login/oauth2/code/idm')) {
      of(
        this.authService.handleAuthenticationCallbackForMobileApp(slug?.replace('/app', '')),
      ).subscribe();
    } else if (slug) {
      this.authService.retrieveUser('from-deeplink', slug).subscribe();
    }
  }
}
