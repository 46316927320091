<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [title]="'doelen.doelen-page.title' | translate"
      [heroImage]="doelHeroSources"
    >
    </parkour-page-with-hero>
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/doelen/background/background_doelen_blur.jpg"
    />
    <ng-container *parkourPageContent="mainPageData$ | async; let profiel">
      <div class="container-1-col flex flex-col gap-y-8">
        <p class="text-center">
          @if (profiel.type === 'PROFESSIONEEL_TEAMLID') {
            {{ 'doelen.doelen-page.no-team-text-professioneel' | translate }}
          } @else {
            {{ 'doelen.doelen-page.no-team-text' | translate }}
          }
        </p>

        <parkour-doelen-placeholder />
      </div>
    </ng-container>
    <parkour-page-image />
  </parkour-page-layout>
</ion-content>
