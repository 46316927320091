import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { Uitnodiging, UitnodigingUpsert } from '../model/uitnodiging';
import { ProfielId, UitnodigingDto, UitnodigingId } from 'parkour-web-app-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { stripNullProperties } from '../../utils';
import { TeamService } from '../../team/service/team.service';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable({
  providedIn: 'root',
})
export class UitnodigingenService {
  readonly TRACKER_CATEGORY = 'uitnodigingen';

  constructor(
    private readonly http: HttpClient,
    private readonly teamService: TeamService,
    private readonly tracker: MatomoTracker,
  ) {}

  uitnodigingIdFromLink?: UitnodigingId;

  sendUitnodiging(upsert: UitnodigingUpsert): Observable<Uitnodiging> {
    return this.http
      .post<UitnodigingDto>(
        `${environment.API_BASE_URL}/api/me/uitnodiging`,
        stripNullProperties(upsert),
      )
      .pipe(tap(() => this.tracker.trackEvent(this.TRACKER_CATEGORY, 'uitnodiging-verzonden')));
  }

  getUitnodigingenVanJongere(jongereId: ProfielId): Observable<Array<Uitnodiging>> {
    return this.http.get<Array<UitnodigingDto>>(
      `${environment.API_BASE_URL}/api/jongere/${jongereId}/uitnodiging`,
    );
  }

  getUitnodiging(id: UitnodigingId): Observable<Uitnodiging> {
    return this.http.get<UitnodigingDto>(`${environment.API_BASE_URL}/api/uitnodiging/${id}`);
  }

  weigerUitnodiging(id: UitnodigingId): Observable<Uitnodiging> {
    this.removeCurrentUitnodingingIdIfMatching(id);
    return this.http
      .put<UitnodigingDto>(`${environment.API_BASE_URL}/api/uitnodiging/${id}/weiger`, {})
      .pipe(tap(() => this.tracker.trackEvent(this.TRACKER_CATEGORY, 'uitnodiging-geweigerd')));
  }

  hasAangekomenUitnodigingId(): boolean {
    return !!this.uitnodigingIdFromLink;
  }

  getMijnAangekomenUitnodiging(): Observable<Uitnodiging | undefined> {
    if (this.uitnodigingIdFromLink) {
      return this.getUitnodiging(this.uitnodigingIdFromLink).pipe(
        map((uitnodiging) => {
          if (uitnodiging.status === 'VERSTUURD') {
            return uitnodiging;
          } else {
            this.removeMyCurrentUitnodigingId();
            return undefined;
          }
        }),
      );
    } else {
      return of(undefined);
    }
  }

  saveMyCurrentUitnodigingId(uitnodigingId: UitnodigingId) {
    this.uitnodigingIdFromLink = uitnodigingId;
  }

  removeMyCurrentUitnodigingId() {
    this.uitnodigingIdFromLink = undefined;
  }

  accepteerUitnodiging(uitnodigingId: UitnodigingId) {
    this.removeCurrentUitnodingingIdIfMatching(uitnodigingId);
    return this.http
      .put<UitnodigingDto>(
        `${environment.API_BASE_URL}/api/uitnodiging/${uitnodigingId}/accepteer`,
        {},
      )
      .pipe(tap(() => this.tracker.trackEvent(this.TRACKER_CATEGORY, 'uitnodiging-geaccepteerd')));
  }

  private removeCurrentUitnodingingIdIfMatching(uitnodigingId: UitnodigingId) {
    if (this.uitnodigingIdFromLink === uitnodigingId) {
      this.removeMyCurrentUitnodigingId();
    }
  }

  getUitnodigingenVoorMij(): Observable<Array<Uitnodiging>> {
    return this.http.get<Array<UitnodigingDto>>(
      `${environment.API_BASE_URL}/api/profiel/me/uitnodiging`,
    );
  }

  verzendHerinnering(uitnodigingId: UitnodigingId) {
    return this.http.put<void>(
      `${environment.API_BASE_URL}/api/uitnodiging/${uitnodigingId}/herinnering`,
      {},
    );
  }

  verwijderUitnodiging(uitnodigingId: UitnodigingId) {
    return this.http
      .delete<Uitnodiging>(`${environment.API_BASE_URL}/api/uitnodiging/${uitnodigingId}`)
      .pipe(tap(() => this.tracker.trackEvent(this.TRACKER_CATEGORY, 'uitnodiging-verwijderd')));
  }

  bevestigUitnodiging(uitnodigingId: UitnodigingId) {
    return this.http
      .put<Uitnodiging>(
        `${environment.API_BASE_URL}/api/uitnodiging/${uitnodigingId}/bevestigen`,
        {},
      )
      .pipe(
        tap(() => this.teamService.invalidateCache()),
        tap(() => this.tracker.trackEvent(this.TRACKER_CATEGORY, 'uitnodiging-bevestigd')),
      );
  }
}
