import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SysteemConfigDto } from 'parkour-web-app-dto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SysteemConfigService {
  public getSysteemConfig(): Observable<SysteemConfigDto> {
    return this.http.get<SysteemConfigDto>(
      `${environment.API_BASE_URL}/api/config/systeem-boodschap`,
    );
  }

  constructor(private readonly http: HttpClient) {}
}
