import { SupportedSucceservaringType } from '../shared/model/succeservaring';
import { SucceservaringVisualization } from './succeservaringen.service';

export const succeservaringVisualizations: Record<
  SupportedSucceservaringType,
  SucceservaringVisualization
> = {
  TEAMLID_GEACTIVEERD: {
    icon: '🙌',
    titel: 'Go team!',
    beschrijving: '1e teamlid geactiveerd',
  },
  UITNODIGING_AANVAARD: {
    icon: '🙌',
    titel: 'Go team!',
    beschrijving: '1e uitnodiging aanvaard',
  },
  ARTIKEL_BEWAARD: {
    icon: '📎',
    titel: 'Eentje om bij te houden!',
    beschrijving: '1e artikel bewaard',
  },
  HULPLIJN_BEWAARD: {
    icon: '👏',
    titel: 'Hulp en info bijhouden, da’s top!',
    beschrijving: '1e hulplijn bewaard',
  },
  DOEL_VOORGESTELD: {
    icon: '🚀',
    titel: 'Gouden idee!',
    beschrijving: '1e doel voorgesteld',
  },
  DOEL_TOEGEVOEGD: {
    icon: '🫡',
    titel: 'Missie gestart!',
    beschrijving: '1e doel toegevoegd',
  },
  GEBEURTENIS_VOORGESTELD: {
    icon: '✨',
    titel: 'Yes! Jij maakt dit verhaal compleet!',
    beschrijving: '1e gebeurtenis voorgesteld',
  },
  GEBEURTENIS_TOEGEVOEGD: {
    icon: '📔',
    titel: 'Het begin van een nieuw dagboek!',
    beschrijving: '1e gebeurtenis toegevoegd',
  },
  EMOJI_REACTIE_DOEL_TOEGEVOEGD: {
    icon: '❤️',
    titel: 'BAM!',
    beschrijving: '1e emoji-reactie op een doel toegevoegd',
  },
  AFSPRAAK_TOEGEVOEGD: {
    icon: '🔮',
    titel: 'Ik zie... de toekomst',
    beschrijving: '1e afspraak toegevoegd',
  },
  BEELDBELAFSPRAAK_GEPLAND: {
    icon: '🤳',
    titel: 'Face-to-face, maar dan digitaal!',
    beschrijving: '1e afspraak beeldbellen gepland',
  },
  DOEL_STAP_AFGEVINKT: {
    icon: '✅',
    titel: 'Eén stap dichter bij wereldheerschappij... of je doel! Check!',
    beschrijving: '1e stap afgevinkt',
  },
  NOTITIE_BEWAARD: {
    icon: '🧠',
    titel: 'Nice! Je brein staat op papier!',
    beschrijving: '1e notitie bewaard',
  },
  PROFIEL_OUDER_DAN_3_DAGEN: {
    icon: '🍌',
    titel: 'Al meer dan 3 dagen op PARKOUR! Hier is een banaan!',
    beschrijving: 'Minstens 3 dagen op PARKOUR',
  },
};
