import {Component, Input} from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from "@angular/forms";
import {
    TParkourInputSlotPlacement,
    TParkourInputType,
} from "./parkour-input.types";
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {ParkourFocusVisibleModule} from "../parkour-focus-visible/parkour-focus-visible.module";

// Only use 'off' if you have a good reason to do so. It's better to use null which just doesn't place the autocomplete attribute.
export type AutocompleteOptions = 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'impp'
    | 'billing'
    | 'home'
    | 'work'
    | 'mobile'
    | 'fax'
    | 'pager';

@Component({
    selector: "parkour-input",
    templateUrl: "./parkour-input.component.html",
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ParkourInputComponent,
        },
    ],
    imports: [
        NgClass,
        NgIf,
        NgTemplateOutlet,
        ReactiveFormsModule,
        ParkourFocusVisibleModule,
    ],
})
export class ParkourInputComponent implements ControlValueAccessor {
    @Input() public classes?: string;
    @Input({required: true}) public inputId!: string;
    @Input() public testId?: string;
    @Input() public error?: string;
    @Input() public type: TParkourInputType = "text";
    @Input() public inputPlaceholder: string = "";
    @Input() public min: number | string = 0;
    @Input() public max: number | string = 100;
    @Input() public disabled = false;
    @Input() public required = false;
    @Input() public pattern = "";
    @Input({required: true}) public autocomplete!: AutocompleteOptions | null;
    @Input() public slotPlacement: TParkourInputSlotPlacement = "right";
    @Input() public formControl: FormControl<string | undefined> | null = null;

    public isFocus!: boolean;
    public value: FormControl = new FormControl(null);
    public onChange?: (value: string | number) => void;
    public onTouched?: () => void;

    onInputChange(event: Event) {
        if (this.onChange) {
            this.onChange((event.target as HTMLInputElement).value);
        }
    }

    writeValue(value: string | number): void {
        this.value.setValue(value, {emitEvent: false});
    }

    registerOnChange(onChange: () => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }
}
