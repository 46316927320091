import { BrandedType } from '../branded-type';
import { ApiProperty, getSchemaPath } from '@nestjs/swagger';
import { Thema } from './thema.dto';

export type WatWatHelpline =
  | WatWatChatHelpline
  | WatWatTelHelpline
  | WatWatVisitHelpline
  | WatWatForumHelpline
  | WatWatMailHelpline
  | WatWatActivityHelpline;

export class WatWatChatHelpline {
  @ApiProperty({ type: String })
  method!: 'chat';
    @ApiProperty()
  organisation!: number;
}

export class WatWatTelHelpline {
  @ApiProperty({ type: String })
  method!: 'tel';
  @ApiProperty()
  organisation!: number;
}

export class WatWatVisitHelpline {
  @ApiProperty({ type: String })
  method!: 'visit';
    @ApiProperty()
  organisation!: number;
}

export class WatWatForumHelpline {
  @ApiProperty({ type: String })
  method!: 'forum';
    @ApiProperty()
  organisation!: number;
}

export class WatWatMailHelpline {
  @ApiProperty({ type: String })
  method!: 'mail';
    @ApiProperty()
  organisation!: number;
}

export class WatWatActivityHelpline {
    @ApiProperty({ type: String })
  method!: 'activity';
    @ApiProperty()
  organisation!: number;
}

class WatWatContentDto {
  @ApiProperty()
  readonly text!: string;
}

export class WatWatTextContent {
  @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'text';
    @ApiProperty()
  readonly content!: WatWatContentDto;
}

export class WatWatVideoContent {
  @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'video';
    @ApiProperty()
  readonly content!: WatWatYoutubeContentDetails | WatWatVimeoContentDetails;
}

export class WatWatYoutubeContentDetails {
    @ApiProperty({ type: String })
  readonly type!: 'youtube';
    @ApiProperty()
  readonly youtube!: string;
    @ApiProperty()
  readonly title!: string;
}

export class WatWatVimeoContentDetails {
    @ApiProperty({ type: String })
  readonly type!: 'vimeo';
    @ApiProperty()
  readonly vimeo!: string;
    @ApiProperty()
  readonly title!: string;
}


export class WatWatCallToActionContentImage {
  @ApiProperty({required: false})
  readonly copyright?: string;
  @ApiProperty()
  readonly file!: string;
  @ApiProperty()
  readonly title!: string;
}

export class WatWatCallToActionContentLink {
  @ApiProperty()
  readonly external!: boolean;
  @ApiProperty()
  readonly text!: string;
  @ApiProperty()
  readonly url!: string;
}

export class WatWatCallToActionContentDetails {
  @ApiProperty()
  readonly title!: string;
  @ApiProperty({required: false})
  readonly description?: string;
  @ApiProperty({required: false})
  readonly image?: WatWatCallToActionContentImage;
  @ApiProperty({required: false})
  readonly link?: WatWatCallToActionContentLink;
}

export class WatWatCallToActionContent {
  @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'call_to_action';
    @ApiProperty()
  readonly content!: WatWatCallToActionContentDetails;
}

export class WatWatQuoteContentDetails {
  @ApiProperty()
  readonly text!: string;
  @ApiProperty({required: false})
  readonly source?: string;
}

class WatWatAttachment {
  @ApiProperty({required: false})
  readonly file?: string;
  @ApiProperty({required: false})
  readonly title?: string;
}

export class WatWatQuoteContent {
  @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'quote';
    @ApiProperty()
  readonly content!: WatWatQuoteContentDetails;
}
export class WatWatAttachmentContentDetails {
  @ApiProperty({type: [WatWatAttachment]})
  readonly attachments!: WatWatAttachment[];
}

export class WatWatAttachmentContent {
    @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'attachment';
    @ApiProperty()
  readonly content!: WatWatAttachmentContentDetails;
}

class FaqItem {
  @ApiProperty({required: false})
  readonly id?: string;
  @ApiProperty({required: false})
  readonly question?: string;
  @ApiProperty({required: false})
  readonly answer?: string;
}

export class WatWatFaqContentDetails {
  @ApiProperty({type: [FaqItem]})
  readonly items!: FaqItem[];
}
export class WatWatFaqContent {
  @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
  @ApiProperty({ type: String })
  readonly type!: 'faq';
    @ApiProperty()
  readonly content!: WatWatFaqContentDetails;
}

export class WatWatImageContentImage {
  @ApiProperty({required: false})
  readonly alt?: string;
  @ApiProperty({required: false})
  readonly copyright?: string;
  @ApiProperty()
  readonly file!: string;
  @ApiProperty()
  readonly title!: string;
}

export class WatWatImageContentDetails {
  @ApiProperty()
  readonly image!: WatWatImageContentImage;
}

export class WatWatImageContent {
    @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'image';
    @ApiProperty()
  readonly content!: WatWatImageContentDetails;
}

export class WatWatHtmlEmbedContent {
  @ApiProperty()
  readonly id!: string;
  @ApiProperty()
  readonly weight!: number;
  @ApiProperty({ type: String })
  readonly type!: 'html_embed';
}

export class WatWatHtmlEmbedLargeContent {
    @ApiProperty()
  readonly id!: string;
    @ApiProperty()
  readonly weight!: number;
    @ApiProperty({ type: String })
  readonly type!: 'html_embed_large';
}

export type WatWatContent =
  | WatWatTextContent
  | WatWatVideoContent
  | WatWatCallToActionContent
  | WatWatQuoteContent
  | WatWatFaqContent
  | WatWatAttachmentContent
  | WatWatImageContent
  | WatWatHtmlEmbedContent
  | WatWatHtmlEmbedLargeContent;

export type ArtikelId = BrandedType<number, 'artikel'>;

export type OrganisationId = BrandedType<number, 'organisation'>;

export class WatWatArtikelAuthor {
  @ApiProperty()
  readonly name!: string;
  @ApiProperty({required: false})
  readonly email?: string;
  @ApiProperty()
  readonly isContributor!: boolean;
}

export class WatWatArtikelDto {
  @ApiProperty({ type: Number })
  readonly id!: ArtikelId;
  @ApiProperty()
  readonly title!: string;
  @ApiProperty()
  readonly author!: WatWatArtikelAuthor;
  @ApiProperty()
  readonly image!: string;
  @ApiProperty({ type: Array<string> })
  readonly themas!: Thema[];
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatTextContent) },
        { $ref: getSchemaPath(WatWatVideoContent) },
        { $ref: getSchemaPath(WatWatCallToActionContent) },
        { $ref: getSchemaPath(WatWatQuoteContent) },
        { $ref: getSchemaPath(WatWatFaqContent) },
        { $ref: getSchemaPath(WatWatAttachmentContent) },
        { $ref: getSchemaPath(WatWatImageContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedLargeContent) },
      ],
    },
  })
  readonly content!: WatWatContent[];
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatActivityHelpline) },
        { $ref: getSchemaPath(WatWatChatHelpline) },
        { $ref: getSchemaPath(WatWatForumHelpline) },
        { $ref: getSchemaPath(WatWatTelHelpline) },
        { $ref: getSchemaPath(WatWatVisitHelpline) },
        { $ref: getSchemaPath(WatWatMailHelpline) },
      ],
    },
  })
  readonly helplines!: WatWatHelpline[];
  @ApiProperty({type: Array<number>})
  readonly selfTests!: number[];
}

export class WatWatSelfTest {
  @ApiProperty()
  readonly title!: string;
  @ApiProperty({required: false})
  readonly url?: string;
  @ApiProperty({required: false})
  readonly link_text?: string;
  @ApiProperty({required: false})
  readonly description?: string;
  @ApiProperty({required: false})
  readonly name?: string;
  @ApiProperty({required: false})
  readonly email?: string;
}

export type WatWatOrganisationMethodType = 'chat' | 'mail' | 'tel' | 'forum' | 'visit' | 'activity';

export class WatWatOrganisationMethod {
  @ApiProperty({ type: String })
  readonly type!: WatWatOrganisationMethodType;
    @ApiProperty()
  readonly description!: string;
  @ApiProperty({required: false})
  readonly telephone?: string;
  @ApiProperty({required: false})
  readonly url?: string;
  @ApiProperty({required: false})
  readonly email?: string;
}

export class WatWatOrganisation {
  @ApiProperty({ type: Number })
  readonly id!: OrganisationId;
  @ApiProperty()
  readonly title!: string;
  @ApiProperty()
  readonly url!: string;
  @ApiProperty()
  readonly description!: string;
  @ApiProperty()
  readonly helpOrganisationText!: string;
  @ApiProperty({type: [WatWatOrganisationMethod]})
  readonly methods!: WatWatOrganisationMethod[];
  @ApiProperty({ type: String, nullable: true })
  readonly image!: string | null;
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatTextContent) },
        { $ref: getSchemaPath(WatWatVideoContent) },
        { $ref: getSchemaPath(WatWatCallToActionContent) },
        { $ref: getSchemaPath(WatWatQuoteContent) },
        { $ref: getSchemaPath(WatWatFaqContent) },
        { $ref: getSchemaPath(WatWatAttachmentContent) },
        { $ref: getSchemaPath(WatWatImageContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedLargeContent) },
      ],
    },
  })
  readonly content!: WatWatContent[];
}

export function validateOrganisationId(id?: number | string | null): OrganisationId {
  if (typeof id === 'number') {
    return id as OrganisationId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as OrganisationId;
  }

  throw new Error('OrganisationId cannnot be undefined');
}

export class WatWatOrganisationQueryResultDto {
  @ApiProperty()
  readonly total!: number;
  @ApiProperty({type: [WatWatOrganisation]})
  readonly results!: WatWatOrganisation[];
  @ApiProperty()
  readonly pageSize!: number;
}

export class ArtikelQueryResultDto {
  @ApiProperty()
  readonly total!: number;
  @ApiProperty({type: [WatWatArtikelDto]})
  readonly results!: WatWatArtikelDto[];
  @ApiProperty()
  readonly pageSize!: number;
}

export function parseArtikelId(id?: number | string | null): ArtikelId {
  if (typeof id === 'number') {
    return id as ArtikelId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as ArtikelId;
  }

  throw new Error('ArtikelId cannnot be undefined');
}

export function parseOrganisationId(id?: number | string | null): OrganisationId {
  if (typeof id === 'number') {
    return id as OrganisationId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as OrganisationId;
  }

  throw new Error('OrganisationId cannnot be undefined');
}
