import { ApiProperty } from '@nestjs/swagger';

export type SucceservaringTypeDto =
  | 'TEAMLID_GEACTIVEERD'
  | 'UITNODIGING_AANVAARD'
  | 'ARTIKEL_BEWAARD'
  | 'HULPLIJN_BEWAARD'
  | 'DOEL_VOORGESTELD'
  | 'DOEL_TOEGEVOEGD'
  | 'GEBEURTENIS_VOORGESTELD'
  | 'GEBEURTENIS_TOEGEVOEGD'
  | 'EMOJI_REACTIE_DOEL_TOEGEVOEGD'
  | 'AFSPRAAK_TOEGEVOEGD'
  | 'BEELDBELAFSPRAAK_GEPLAND'
  | 'DOEL_STAP_AFGEVINKT'
  | 'NOTITIE_BEWAARD'
  | 'PROFIEL_OUDER_DAN_3_DAGEN';

export class SucceservaringPusherMeldingDto {
  readonly type!: SucceservaringTypeDto;
}

export class SucceservaringDto {
  @ApiProperty({ type: String })
  readonly type!: SucceservaringTypeDto;
  @ApiProperty({ type: Number })
  readonly count!: number;
  @ApiProperty({ type: Number })
  readonly lastReadCount!: number;
}
