<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-page-with-hero
        [heroImage]="teamlidContext$ | profielFotoOfJongere | async"
        [title]="'doelen.doelen-van-jongere-page.doelen-van' | translate"
      >
        @if (
          data.actieveDoelen.length > 0 ||
          data.behaaldeDoelen.length > 0 ||
          data.voorstellen.length > 0
        ) {
          <ng-container *parkourSectionContent="{ data: segmentItems$ | async }; let segmentItems">
            <div class="container-1-col">
              <parkour-segment
                (changed)="segmentChanged($event)"
                [defaultValue]="activeSegmentItem"
                [items]="segmentItems"
              />
            </div>

            @if (teamlidContext$ | async; as context) {
              <div class="container-1-col">
                @switch (activeSegmentItem) {
                  @case ('actieveDoelen') {
                    <div class="flex flex-col gap-y-8">
                      <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
                        {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
                      </parkour-button>
                      <parkour-doel-list
                        [doelen]="data.actieveDoelen"
                        [geenDoelenText]="
                          'doelen.doelen-page.geen-doelen-teamlid'
                            | translate: { jongere: context.jongereProfiel | profielnaam }
                        "
                        [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      />
                    </div>
                  }
                  @case ('behaaldeDoelen') {
                    <div class="flex flex-col gap-y-8">
                      <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
                        {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
                      </parkour-button>

                      <parkour-doel-list
                        [doelen]="data.behaaldeDoelen"
                        [geenDoelenText]="
                          'doelen.behaalde-doelen-van-jongere-page.geen-doelen'
                            | translate
                              : {
                                  jongere: context.jongereProfiel | profielnaam,
                                }
                        "
                        [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      />
                    </div>
                  }
                }
              </div>
            }
          </ng-container>
        } @else {
          <div class="container-1-col flex flex-col gap-y-8">
            <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
              {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
            </parkour-button>

            <div class="relative">
              <parkour-doelen-placeholder />

              <div
                class="absolute bottom-0 left-0 w-full h-full pointer-events-none bg-gradient-to-t from-n-100 to-transparent"
                role="presentation"
              ></div>
            </div>
          </div>
        }
      </parkour-page-with-hero>
    </ng-container>
  </parkour-page-layout>
</ion-content>
