import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Result } from '../../utils';
import { ParkourLoadingSpinnerComponent } from '@parkour/ui';
import { NotFoundComponent } from '../components/not-found/not-found.component';

@Directive({
  standalone: true,
  selector: '[parkourPageContent]',
})
export class PageContentDirective<T> {
  constructor(
    private templateRef: TemplateRef<{ $implicit: T }>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  static ngTemplateContextGuard<T>(
    dir: PageContentDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }

  private embeddedViewRef?: EmbeddedViewRef<{ $implicit: T }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private context: any = { $implicit: null };

  @Input()
  set parkourPageContent(data: Result<T> | null) {
    if (data) {
      if (data.success) {
        this.context.$implicit = data.value;

        if (!this.embeddedViewRef) {
          this.viewContainerRef.clear();
          this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(
            this.templateRef,
            this.context,
          );
        }
      } else {
        this.viewContainerRef.clear();
        this.embeddedViewRef = undefined;

        const notFoundComponent = this.viewContainerRef.createComponent(NotFoundComponent);
        notFoundComponent.instance.failure = data;
      }
    } else {
      this.viewContainerRef.clear();
      this.embeddedViewRef = undefined;
      this.viewContainerRef.createComponent(ParkourLoadingSpinnerComponent);
    }
  }
}
