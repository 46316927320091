<form [formGroup]="doelFormGroup" class="flex flex-col gap-y-8">
  <parkour-form-field
    [inputFormControl]="doelFormGroup.controls.naam"
    [label]="'doelen.doel-toevoegen-page.form.naam' | translate"
    [required]="true"
    class="block"
    inputId="naam"
  >
    <parkour-input
      [autocomplete]="null"
      [formControl]="doelFormGroup.controls.naam"
      [required]="true"
      class="block"
      inputId="naam"
      inputPlaceholder=""
    />
  </parkour-form-field>

  <parkour-form-field
    [inputFormControl]="doelFormGroup.controls.omschrijving"
    [label]="'doelen.doel-suggestie-page.bericht' | translate"
    class="block"
    inputId="omschrijving"
  >
    <parkour-textarea
      [formControl]="doelFormGroup.controls.omschrijving"
      class="block"
      inputId="omschrijving"
    />
  </parkour-form-field>

  <parkour-form-field
    [inputFormControl]="doelFormGroup.controls.thema"
    [label]="'doelen.doel-toevoegen-page.form.thema' | translate"
    [required]="true"
    class="block"
    inputId="thema"
  >
    <ion-radio-group
      [formControl]="doelFormGroup.controls.thema"
      class="grid grid-cols-2 gap-2 mt-4"
      aria-labelledby="Thema"
    >
      @for (thema of themas; track thema) {
        <parkour-doel-thema [thema]="thema" class="block w-full" />
      }
    </ion-radio-group>
  </parkour-form-field>

  <parkour-form-field
    [inputFormControl]="doelFormGroup.controls.streefDatum"
    [label]="'doelen.doel-toevoegen-page.form.streefdatum' | translate"
    class="block"
    inputId="streefDatum"
  >
    <parkour-input
      [autocomplete]="null"
      class="block"
      formControlName="streefDatum"
      inputId="streefDatum"
      [inputPlaceholder]="'forms.date-format' | translate"
      type="date"
    >
    </parkour-input>
  </parkour-form-field>
</form>
