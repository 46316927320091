import { Component, ViewChild } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { combineLatest, Observable } from 'rxjs';
import { TeamService } from '../../../team/service/team.service';
import { GroepsgesprekFormComponent } from '../../component/groepsgesprek-add-form/groepsgesprek-form.component';
import { GroepsgesprekInsert } from '../../model/gesprek';
import { BerichtenService } from '../../service/berichten.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { AangemeldeUser } from '../../../authentication/user';
import { JongereContext, TeamlidContext } from '../../../shared/model/context';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  ParkourAvatarComponent,
  ParkourIconComponent,
  ParkourInfoCardComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { MijnProfiel } from '../../../profiel/model/profiel';
import { ProfielInTeam } from '../../../profiel/model/profiel-in-team';
import AuthService from '../../../authentication/service/auth.service';

type PageData = {
  teamleden: Array<TeamlidProfiel>;
  context: JongereContext | TeamlidContext;
  user: AangemeldeUser;
  profiel: MijnProfiel;
  profielInTeam: ProfielInTeam;
};

@Component({
  standalone: true,
  templateUrl: './groepsgesprek-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    AsyncPipe,
    ParkourHeaderActionComponent,
    NgIf,
    PageLayoutComponent,
    PageContentDirective,
    ParkourInfoCardComponent,
    ParkourAvatarComponent,
    ParkourIconComponent,
    ProfielFotoPipe,
    ProfielnaamPipe,
    ParkourProfielBlockComponent,
    ProfielPipe,
    GroepsgesprekFormComponent,
    ParkourPageGradientComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class GroepsgesprekAddPage extends ParkourDataPage<PageData> {
  @ViewChild('groepsgesprekForm') groepsgesprekFormComponent?: GroepsgesprekFormComponent;

  constructor(
    private readonly teamService: TeamService,
    private readonly berichtenService: BerichtenService,
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly profielService: ProfielService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<PageData> {
    return combineLatest({
      teamleden: this.teamService.getMyTeamleden(),
      user: this.authService.getAangemeldeUser$(),
      context: this.contextService.contextWithActiveJongere$(),
      profielInTeam: this.profielService.getCurrentUserProfielInTeam$(),
      profiel: this.profielService.getCurrentUserProfiel$(),
    });
  }

  onSubmit(groepsgesprek: GroepsgesprekInsert) {
    this.berichtenService.addGroepsgesprek(groepsgesprek).subscribe((id) => {
      this.groepsgesprekFormComponent?.deelnemersFormGroup.markAsPristine();
      this.router.navigate(['..', 'gesprek', id], { relativeTo: this.route, replaceUrl: true });
    });
  }

  getTeamledenOptions(teamleden: Array<TeamlidProfiel>, user: AangemeldeUser) {
    return teamleden.filter(
      (teamlid) => teamlid.id !== user.profielId && teamlid.status === 'ACTIEF',
    );
  }

  getBeblokkeerdeTeamleden(teamleden: Array<TeamlidProfiel>) {
    return teamleden.filter((teamlid) => teamlid.status === 'GEBLOKKEERD');
  }
}
