import { ApiProperty, getSchemaPath } from '@nestjs/swagger';

export type CmsContent = CmsFaqContent | CmsTextTitleImageLinkContent;

export class CmsFaqContent {
  @ApiProperty({type: String, required: true})
  readonly type!: 'faq';
  @ApiProperty({type: String, required: true})
  readonly title!: string;
  @ApiProperty({required: true})
  readonly faqValues!: CmsFaqValue[]
}

export class CmsImage {
  @ApiProperty({ required: false })
  url?: string;
  @ApiProperty({ required: false })
  alt?: string;
  @ApiProperty({ required: false })
  caption?: string;
  @ApiProperty({ required: false, name: "fullUrl" })
  fullUrl?: string;
}

export class CmsVideo {
  @ApiProperty({ required: false })
  title?: string;
  @ApiProperty({ required: false })
  type?: string;
  @ApiProperty({ required: false })
  url?: string;
}

export class CmsFaqValue {
  @ApiProperty( {required: false})
  readonly question?: string;
  @ApiProperty({ required: false })
  readonly answer?: string;
  @ApiProperty({ required: false })
  readonly image?: CmsImage;
  @ApiProperty({ required: false })
  readonly video?: CmsVideo;
}

export class CmsTextTitleImageLinkContent {
  @ApiProperty({type: String, required: true})
  readonly type!: 'text-title-image-link';
  @ApiProperty({required: false})
  readonly title?: string;
  @ApiProperty({required: false})
  readonly text?: string;
  @ApiProperty({required: false})
  readonly image?: CmsImage;
  @ApiProperty({required: false})
  readonly links?: string;
}

export class CmsPaginaDto {
  @ApiProperty()
  readonly type!: string;
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(CmsFaqContent) },
        { $ref: getSchemaPath(CmsTextTitleImageLinkContent) },
      ],
    },
  })
  readonly content!: CmsContent[];
}
