import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import {
  BehaviorSubject,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  Observable,
  of,
  Subject,
  take,
} from 'rxjs';
import AuthService from './auth.service';
import { isNativeApp } from '../../utils';
import { LoggingService } from '../../core/logging.service';

export type LoginResult = {
  type: 'biometric-login' | 'acm-idm-login' | 'home' | 'canceled';
};

@Injectable({
  providedIn: 'root',
})
export default class BackgroundDetectionService {
  private readonly _active$ = new BehaviorSubject<boolean>(true);
  private fromUrl = false;

  get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  private _localAppState = new Subject<boolean>();

  constructor(
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
  ) {}

  initialize() {
    this._localAppState
      .pipe(
        distinctUntilChanged(),
        exhaustMap((active) => {
          this.loggingService.log(`App state changed: ${active}, fromUrl: ${this.fromUrl}`);
          const oldFromUrl = this.fromUrl;
          this.fromUrl = false;
          if (active && !oldFromUrl) {
            return this.authService
              .retrieveUser('from-background')
              .pipe(finalize(() => this._active$.next(true)));
          } else {
            this._active$.next(active);
            return of(undefined);
          }
        }),
      )
      .subscribe();

    if (isNativeApp()) {
      App.addListener('appStateChange', (appState) => {
        const active = appState.isActive;
        this.loggingService.log('AppStateChange to', active);
        this._localAppState.next(active);
      });
    }
  }

  isCurrentlyActive() {
    return this.active$.pipe(take(1));
  }

  waitUntilActive(): Observable<boolean> {
    return this.active$.pipe(
      filter((active) => active),
      take(1),
    );
  }

  markAsFromUrl() {
    this.fromUrl = true;
  }
}
