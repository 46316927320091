import { inject } from '@angular/core';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { Gebeurtenis } from '../../model/gebeurtenis';
import { VerhalenService } from '../../service/verhalen.service';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, switchMap, take } from 'rxjs';
import { validateGebeurtenisId } from '../../utils';
import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { asType } from '../../../../utils';

export abstract class GebeurtenisDetailsJongereBasePage
  extends ParkourDataPage<Gebeurtenis>
  implements CustomPageTitle
{
  protected readonly verhalenService = inject(VerhalenService);
  protected readonly route = inject(ActivatedRoute);

  override retrieveMainData(): Observable<Gebeurtenis> {
    return this.route.paramMap.pipe(
      take(1),
      map((params) => params.get('gebeurtenis-id')),
      map(validateGebeurtenisId),
      switchMap((gebeurtenisId) => this.verhalenService.getGebeurtenis(gebeurtenisId)),
    );
  }

  public getCustomPageTitle(): Observable<PageTitle> {
    return this.mainPageData$.pipe(
      switchMap((mainPageData) => {
        if (mainPageData.success) {
          return this.translateService
            .get(
              mainPageData.value.type === 'EYOUTH'
                ? 'verhaal.eyouth-gebeurtenis-details-page.title'
                : 'verhaal.gebeurtenis-details-page.title',
            )
            .pipe(
              map((detail) => ({
                title: mainPageData.value.titel,
                category: detail,
              })),
            );
        } else {
          return asType<Observable<string>>(
            this.translateService.get('verhaal.gebeurtenis-details-page.title'),
          );
        }
      }),
    );
  }
}
