import { Component, ViewChild } from '@angular/core';
import { AsyncPipe, Location, NgIf } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { BewaardeItemsService } from '../../service/bewaarde-items.service';
import { ActivatedRoute } from '@angular/router';
import { NotitieId, validateNotitieId } from 'parkour-web-app-dto';
import { NotitieFormComponent } from '../../component/notitie-form/notitie-form.component';
import { Notitie, NotitieUpsert } from '../../model/notitie';
import {
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';

@Component({
  selector: 'parkour-notitie-edit',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    TranslateModule,
    NotitieFormComponent,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourButtonComponent,
    ParkourLoadingSpinnerComponent,
    ParkourHeaderActionComponent,
    PageLayoutComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
  templateUrl: './notitie-edit.page.html',
})
export class NotitieEditPage implements ViewWillEnter, DirtyFormCheck {
  @ViewChild('notitieForm') notitieForm!: NotitieFormComponent;
  notitieId!: NotitieId;
  notitie$ = new Observable<Notitie>();

  constructor(
    private readonly bewaardeItemsService: BewaardeItemsService,
    private readonly parkourPopupService: ParkourPopupService,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
  ) {}

  ionViewWillEnter(): void {
    this.notitieId = validateNotitieId(this.activatedRoute.snapshot.paramMap.get('notitieId'));
    this.notitie$ = this.bewaardeItemsService.getNotitie(this.notitieId).pipe(take(1));

    this.notitie$.subscribe((notitie) => {
      this.notitieForm.initialState = notitie;
    });
  }

  onNotitieSubmit(id: NotitieId, notitieUpsert: NotitieUpsert) {
    this.notitieForm?.notitieForm?.markAllAsTouched();
    if (this.notitieForm?.notitieForm?.valid) {
      this.bewaardeItemsService.updateNotitie(id, notitieUpsert).subscribe(() => {
        this.notitieForm?.notitieForm?.reset();
        this.location.back();
      });
    }
  }

  onDeleteClick(notitieId: NotitieId) {
    this.translate
      .get([
        'profiel.notitie-edit-page.verwijderen-titel',
        'profiel.notitie-edit-page.verwijderen-description',
        'buttons.ja',
        'buttons.nee',
      ])
      .subscribe((translations) => {
        this.parkourPopupService
          .showPopup({
            jaText: translations['buttons.ja'],
            neeText: translations['buttons.nee'],
            title: translations['profiel.notitie-edit-page.verwijderen-titel'],
            description: translations['profiel.notitie-edit-page.verwijderen-description'],
            icon: 'trash',
          })
          .then((result) => {
            if (result === 'yes') {
              this.bewaardeItemsService
                .deleteNotitie(notitieId)
                .subscribe(() => this.location.back());
            }
          });
      });
  }

  isFormDirty() {
    return this.notitieForm?.notitieForm?.dirty || false;
  }
}
