import { inject } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { AangemeldeUser, User } from '../../../authentication/user';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
} from '@angular/router';
import { ContextService } from '../../../shared/services/context.service';
import { mapLoginError } from '../../../shared/login-error-mappings';
import { ParkourToastService } from '@parkour/ui';
import AuthService from '../../../authentication/service/auth.service';
import { ContextId } from 'parkour-web-app-dto';
import { Location } from '@angular/common';
import { LoggingService } from '../../logging.service';
import { LastContextService } from '../../../shared/services/last-context.service';

export const addContextToUrlRedirect = () => {
  const router: Router = inject(Router);

  return determineContextId().pipe(
    map((contextId) => router.createUrlTree(['app', contextId, 'home'])),
  );
};

function determineContextIdWithUser(lastContextService: LastContextService, user: AangemeldeUser) {
  return lastContextService.getLastContextId(user.profielId).pipe(
    map((lastContext) => {
      if (!lastContext) {
        return user.profielId;
      } else {
        return lastContext;
      }
    }),
  );
}

function determineContextId(): Observable<string> {
  const lastContextService: LastContextService = inject(LastContextService);
  const authService: AuthService = inject(AuthService);
  return authService.getCurrentUser$().pipe(
    switchMap((user) => {
      if (user.type === 'anoniem') {
        return of('anoniem');
      } else {
        return determineContextIdWithUser(lastContextService, user);
      }
    }),
  );
}

function validateContextId(
  contextId: ContextId,
  user: User,
  authService: AuthService,
  currentRoute: string,
  router: Router,
) {
  if (contextId !== 'anoniem' && user.type === 'anoniem') {
    if (user.error) {
      return of(router.createUrlTree(['/']));
    }

    return authService
      .login({
        redirectUrl: currentRoute,
      })
      .pipe(map(() => true));
  } else if (contextId === 'anoniem' && user.type === 'aangemeld') {
    return of(router.createUrlTree(['app', user.profielId, 'home']));
  } else {
    return of(true);
  }
}

export const fetchContextAndEnsureCorrectness: CanMatchFn = (_: Route, segments: UrlSegment[]) => {
  const authService: AuthService = inject(AuthService);
  const contextService: ContextService = inject(ContextService);
  const loggingService: LoggingService = inject(LoggingService);
  const router: Router = inject(Router);
  const location = inject(Location);
  const contextId = segments[1].path as ContextId;
  loggingService.log('Fetch context from url', segments, location);

  return authService.getCurrentUser$().pipe(
    switchMap((user) =>
      validateContextId(contextId as ContextId, user, authService, location.path(), router),
    ),
    switchMap((result) => {
      if (result === true) {
        return contextService.retrieveContextById(contextId).pipe(
          map((context) => {
            if (context === undefined) {
              return router.createUrlTree(['app/me/home']);
            } else {
              return true;
            }
          }),
        );
      } else {
        return of(result);
      }
    }),
  );
};

export const showLoginErrorsGuards = (route: ActivatedRouteSnapshot) => {
  const toastService: ParkourToastService = inject(ParkourToastService);
  const router: Router = inject(Router);
  const error = route.queryParamMap.get('error');
  const errorConfig = mapLoginError(error);

  toastService.showToast({
    header: errorConfig.titel,
    content: errorConfig.description,
    error: true,
  });

  return router.createUrlTree(['/']);
};

export const replaceMeWithContextIdOrLogin = (route: ActivatedRouteSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const lastContextService: LastContextService = inject(LastContextService);
  const router: Router = inject(Router);
  return authService.getCurrentUser$().pipe(
    switchMap((user) => {
      const segments = route.url.map((segment) => segment.path);
      if (user.type === 'anoniem') {
        if (user.error) {
          return of(
            router.createUrlTree(['app', 'anoniem', ...segments], {
              queryParams: route.queryParams,
            }),
          );
        }
        const redirectUrl = router.serializeUrl(
          router.createUrlTree(['app', 'me', ...segments], {
            queryParams: route.queryParams,
          }),
        );
        return authService.login({ redirectUrl }).pipe(map(() => false));
      } else {
        return determineContextIdWithUser(lastContextService, user).pipe(
          map((contextId) =>
            router.createUrlTree(['app', contextId, ...segments], {
              queryParams: route.queryParams,
            }),
          ),
        );
      }
    }),
  );
};

export const preventBackNavigation = () => {
  const router: Router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  router.navigate(activatedRoute.snapshot.url);
  return false;
};
