<div
  [ngClass]="{
    'border-n-500': !error && !disabled && !isFocus,
    'border-negative': error,
    'border-n-300': disabled,
    'border-n-600': isFocus,
  }"
  class="relative flex items-center border-[1.5px] h-[3rem] rounded bg-n-200 transition-colors pr-[0.375rem]
    {{ classes }}
  "
>
  <ng-container
    *ngIf="slotPlacement === 'left'"
    [ngTemplateOutlet]="inputContent"
  ></ng-container>

  <input
    (blur)="isFocus = false"
    (focus)="isFocus = true"
    (input)="onInputChange($event)"
    [attr.data-testid]="testId"
    [attr.required]="required"
    [attr.autocomplete]="autocomplete"
    [disabled]="disabled"
    [formControl]="formControl || value"
    [id]="inputId"
    [max]="max"
    [min]="min"
    [name]="inputId"
    [ngClass]="{
      'placeholder:text-n-1000/70': !disabled,
      'cursor-not-allowed placeholder:text-n-1000/30': disabled,
    }"
    [pattern]="pattern"
    [placeholder]="inputPlaceholder"
    [type]="type"
    class="pl-3 pr-[0.375rem] flex-1 border-none bg-transparent placeholder:typo-body typo-body text-n-1000 self-stretch"
    parkourFocusVisibleElement
  />

  <ng-container
    *ngIf="slotPlacement === 'right'"
    [ngTemplateOutlet]="inputContent"
  ></ng-container>
</div>

<ng-template #inputContent>
  <div [ngClass]="{ 'pointer-events-none': disabled }">
    <ng-content></ng-content>
  </div>
</ng-template>
