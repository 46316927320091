import { Injectable } from '@angular/core';
import {
  DoelDto,
  DoelId,
  DoelSuggestieDto,
  DoelSuggestieUpsertDto,
  DoelUpsertDto,
  ProfielId,
  SuggestieId,
} from 'parkour-web-app-dto';
import { map, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Doel, DoelSuggestie, doelSuggestieFromDto, DoelSuggestieUpsert } from '../model/doel';
import { DoelUpsert } from '../model/doel-upsert';
import { asType, stripNullProperties } from '../../../utils';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../../shared/services/context.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../../analytics/analytics-event.model';
import { AnalyticsService } from '../../../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class DoelenService {
  constructor(
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  doelFromDto(dto: DoelDto): Doel {
    return new Doel(
      dto.id,
      dto.naam,
      dto.omschrijving,
      dto.thema,
      dto.aanmaakDatum,
      dto.eigenaarId,
      dto.streefDatum ? new Date(dto.streefDatum) : undefined,
      dto.eindDatum ? new Date(dto.eindDatum) : undefined,
      dto.acties,
      dto.gedeeldMet,
      dto.deelMode,
      dto.activeGebeurtenisSuggestie,
    );
  }

  doelUpsertToDto(doel: DoelUpsert): DoelUpsertDto {
    return {
      ...doel,
      streefDatum: doel.streefDatum ? doel.streefDatum.toISOString() : undefined,
    };
  }

  getBehaaldeDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/behaald`,
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getGedeeldeBehaaldeDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/behaald`,
          {
            params: { gedeeldMet: contextId },
          },
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getActieveDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/actief`,
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getGedeeldeActieveDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/actief`,
          {
            params: { gedeeldMet: contextId },
          },
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  addDoel(newDoel: DoelUpsert): Observable<void> {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) => {
        return this.http.post<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen`,
          asType<DoelUpsertDto>(stripNullProperties(this.doelUpsertToDto(newDoel))),
        );
      }),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('doelen', 'aangemaakt')),
      tap(() => {
        if (newDoel.originSuggestieId) {
          this.analyticsService.trackEvent(new AnalyticsEvent('doelen', 'voorstelAanvaard'));
        }
        this.analyticsService.trackEvent(this.trackDeelmodeChanged(newDoel));
      }),
    );
  }

  getDoel(id: DoelId): Observable<Doel> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<DoelDto>(`${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${id}`),
      ),
      map((dto) => this.doelFromDto(dto)),
    );
  }

  updateDoel(doelId: DoelId, doel: DoelUpsert, originalDoel: Doel): Observable<void> {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.put<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}`,
          asType<DoelUpsertDto>(stripNullProperties(this.doelUpsertToDto(doel))),
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, () => {
        function isDeelmodeChanged() {
          return doel.deelMode !== originalDoel.deelMode;
        }

        if (isDeelmodeChanged()) {
          return this.trackDeelmodeChanged(doel);
        } else if (
          !isDeelmodeChanged() &&
          doel.gedeeldMet.length !== originalDoel.gedeeldMet.length
        ) {
          if (doel.gedeeldMet.length === 0) {
            return new AnalyticsEvent('doelen', 'gedeeldMetNiemand');
          } else {
            return new AnalyticsEvent('doelen', 'gedeeldMetSpecifiek');
          }
        } else {
          return new AnalyticsEvent('doelen', 'gewijzigd');
        }
      }),
    );
  }

  private trackDeelmodeChanged(doel: DoelUpsert) {
    if (doel.deelMode === 'SPECIFIEK' && doel.gedeeldMet.length === 0) {
      return new AnalyticsEvent('doelen', 'gedeeldMetNiemand');
    } else if (doel.deelMode === 'SPECIFIEK') {
      return new AnalyticsEvent('doelen', 'gedeeldMetSpecifiek');
    } else {
      return new AnalyticsEvent('doelen', 'gedeeldMetIedereen');
    }
  }

  deleteDoel(doelId: DoelId): Observable<void> {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.delete<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}`,
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('doelen', 'verwijderd')),
    );
  }

  completeDoel(doelId: DoelId) {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.put<DoelDto>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}/behaal`,
          {},
          {},
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('doelen', 'behaald')),
      map((dto) => this.doelFromDto(dto)),
    );
  }

  addDoelSuggestie(doelSuggestieUpsert: DoelSuggestieUpsert): Observable<void> {
    return this.http
      .post<void>(
        `${environment.API_BASE_URL}/api/suggesties/doel`,
        asType<DoelSuggestieUpsertDto>(doelSuggestieUpsert),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('doelen', 'voorstelAangemaakt'),
        ),
      );
  }

  getDoelSuggestie(suggestieId: SuggestieId): Observable<DoelSuggestie> {
    return this.http
      .get<DoelSuggestieDto>(`${environment.API_BASE_URL}/api/suggesties/doel/${suggestieId}`)
      .pipe(map((dto) => doelSuggestieFromDto(dto)));
  }

  getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelSuggestieDto>>(`${environment.API_BASE_URL}/api/suggesties/doel`, {
          params: {
            to: contextId,
          },
        }),
      ),
      map((dto) => dto.map((d) => doelSuggestieFromDto(d))),
    );
  }

  getDoelSuggestiesBy(by: ProfielId, to: ProfielId): Observable<DoelSuggestie[]> {
    return this.http
      .get<Array<DoelSuggestieDto>>(`${environment.API_BASE_URL}/api/suggesties/doel`, {
        params: {
          by,
          to,
        },
      })
      .pipe(map((dto) => dto.map((d) => doelSuggestieFromDto(d))));
  }

  rejectSuggestie(suggestieId: SuggestieId): Observable<void> {
    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/suggesties/${suggestieId}/reject`, {})
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('doelen', 'voorstelGeweigerd'),
        ),
      );
  }
}
