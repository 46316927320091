import {ApiProperty, getSchemaPath} from '@nestjs/swagger';
import {IdOf} from '../branded-type';
import {ProfielId} from './profiel.dto';
import {Page} from './page.dto';
import {InteractieEmoji, ReactiesDto} from './reactie.dto';
import {FotoId} from './gebeurtenis.dto';

export type GesprekId = IdOf<'gesprek'>;
export type BerichtId = IdOf<'bericht'>;
export type BestandId = IdOf<'bestand'>;
export type VideogesprekId = IdOf<'videogesprek'>;
export type EventId = IdOf<'event'>;
export type FileId = IdOf<'file'>;

export type GesprekEvent =
    | DeelnemerLeftEventDto
    | DeelnemerRejoinedEventDto
    | VideoGesprekEndedGesprekEventDto;

export class DeelnemerLeftEventDto {
    @ApiProperty({type: String})
    readonly type: 'DEELNEMER_LEFT' = 'DEELNEMER_LEFT' as const;
    @ApiProperty({type: String})
    readonly id!: EventId;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    readonly timestamp!: string;
}

export class DeelnemerRejoinedEventDto {
    @ApiProperty({type: String})
    readonly type: 'DEELNEMER_REJOINED' = 'DEELNEMER_REJOINED' as const;
    @ApiProperty({type: String})
    readonly id!: EventId;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    readonly timestamp!: string;
}

export class VideoGesprekEndedGesprekEventDto {
    @ApiProperty({type: String})
    readonly type: 'VIDEOGESPREK_ENDED' = 'VIDEOGESPREK_ENDED' as const;
    @ApiProperty({type: String})
    readonly startTimestamp!: string;
    @ApiProperty({type: String})
    readonly endTimestamp!: string;
    @ApiProperty()
    readonly answered!: boolean;
}

export class DeelnemerDto {
    @ApiProperty({type: String})
    readonly id!: ProfielId;
    @ApiProperty({type: String})
    readonly channel!: string;
}

export type GesprekStatus = 'ACTIEF' | 'GEARCHIVEERD' | 'GEBLOKKEERD';
export type GesprekType = 'ONE_ON_ONE' | 'GROEPSGESPREK' | 'DOEL';

export class GroepsgesprekInsertDto {
    readonly deelnemers!: Array<GesprekDeelnemerUpsertDto>;
    @ApiProperty({type: String})
    readonly ownerId!: ProfielId;
    @ApiProperty({type: String, required: false})
    readonly imageId?: FotoId;
    @ApiProperty({type: String})
    readonly type!: 'GROEPSGESPREK';
}

export class GesprekUpdateDto {
    @ApiProperty({type: String, required: false})
    readonly imageId?: FotoId;
}

export class GesprekDeelnemerUpsertDto {
    @ApiProperty({type: String})
    readonly externeId!: ProfielId;
}

export type BerichtDto =
    | TextBerichtDto
    | GedeeldeResourceBerichtDto
    | AttachmentBerichtDto
    | SpraakBerichtDto
    | EventBerichtDto
    | GemaskeerdBerichtDto;

export class GemaskeerdBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    readonly type!: 'GEMASKEERD';
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty({type: String})
    readonly timestamp!: string;
}

export class TextBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    readonly type!: 'TEKST';
    @ApiProperty({type: String})
    readonly inhoud!: string;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty({type: String})
    readonly timestamp!: string;
    readonly reacties!: ReactiesDto;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
    @ApiProperty({required: false})
    readonly origineelBericht?: TextBerichtDto;
    @ApiProperty()
    readonly antwoord!: boolean;
}

export class GedeeldeResourceBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    readonly type!: 'GEDEELDE_RESOURCE';
    @ApiProperty({type: String})
    readonly resourceId!: string;
    @ApiProperty({type: String})
    readonly resourceType!: GedeeldeResourceType;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty()
    readonly timestamp!: string;
    @ApiProperty()
    readonly reacties!: ReactiesDto;
}

export class BestandDto {
    @ApiProperty({type: String})
    readonly id!: BestandId;
    @ApiProperty()
    readonly filename!: string;
    @ApiProperty({type: String})
    readonly fileId!: FileId;
    @ApiProperty()
    readonly size!: number;
    @ApiProperty()
    readonly hasPreview!: boolean;
}

export class AttachmentBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    readonly type!: 'ATTACHMENT';
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty({type: String})
    readonly timestamp!: string;
    @ApiProperty()
    readonly bestand!: BestandDto;
    @ApiProperty()
    readonly reacties!: ReactiesDto;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
    @ApiProperty({required: false})
    readonly origineelBericht?: AttachmentBerichtDto;
    @ApiProperty()
    readonly antwoord!: boolean;
}

export class SpraakBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    @ApiProperty()
    readonly type!: 'SPRAAK';
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty()
    readonly timestamp!: string;
    @ApiProperty()
    readonly bestand!: BestandDto;
    @ApiProperty()
    readonly reacties!: ReactiesDto;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
    @ApiProperty({required: false})
    readonly origineelBericht?: SpraakBerichtDto;
    @ApiProperty()
    readonly antwoord!: boolean;
}

export class EventBerichtDto {
    @ApiProperty({type: String})
    readonly id!: BerichtId;
    @ApiProperty()
    readonly type!: 'EVENT';
    @ApiProperty({type: String})
    readonly timestamp!: string;
    @ApiProperty()
    readonly gesprekEvent!: GesprekEvent;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
}

export class PusherAuthDto {
    @ApiProperty()
    readonly auth!: string;
}

export type CreateBerichtDto =
    | CreateTekstBerichtDto
    | CreateGedeeldeResourceBerichtDto
    | CreateBestandBericht;

class CreateTekstBerichtDto {
    @ApiProperty()
    readonly type = 'TEKST';
    @ApiProperty()
    readonly inhoud!: string;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
}

class CreateGedeeldeResourceBerichtDto {
    @ApiProperty()
    readonly type = 'GEDEELDE_RESOURCE';
    @ApiProperty()
    readonly resourceId!: string;
    @ApiProperty({type: String})
    readonly resourceType!: GedeeldeResourceType;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
}

export class CreateBestandBericht {
    @ApiProperty()
    readonly type!: BestandType;
    @ApiProperty({type: String})
    readonly deelnemerId!: ProfielId;
    @ApiProperty()
    readonly bestand!: File;
    @ApiProperty({type: String, required: false})
    readonly origineelBerichtId?: BerichtId;
}

export class CreateReactieBerichtDto {
    @ApiProperty({type: String})
    readonly type!: InteractieEmoji;
}

export type GedeeldeResourceType = 'ARTIKEL' | 'HULPLIJN';

export type BestandType = 'SPRAAK' | 'ATTACHMENT';

export class NotifierAuthDto {
    @ApiProperty()
    readonly socket_id!: string;
    @ApiProperty()
    readonly channel_name!: string;
}

export class BerichtenPage implements Page<BerichtDto> {
    @ApiProperty({
        type: 'array',
        items: {
            anyOf: [
                {$ref: getSchemaPath(TextBerichtDto)},
                {$ref: getSchemaPath(SpraakBerichtDto)},
                {$ref: getSchemaPath(AttachmentBerichtDto)},
                {$ref: getSchemaPath(SpraakBerichtDto)},
                {$ref: getSchemaPath(SpraakBerichtDto)},
                {$ref: getSchemaPath(EventBerichtDto)},
                {$ref: getSchemaPath(GemaskeerdBerichtDto)},
            ],
        },
    })
    readonly content!: BerichtDto[];
    @ApiProperty()
    readonly totalPages!: number;
    @ApiProperty()
    readonly number!: number;
    @ApiProperty()
    readonly last!: boolean;
}

export function validateGesprekId(id: string | null | undefined): GesprekId {
    if (!id) {
        throw new Error('id is undefined');
    }

    return id as GesprekId;
}

export function validateVideogesprekId(id: string | null | undefined): VideogesprekId {
    if (!id) {
        throw new Error('id is undefined');
    }

    return id as VideogesprekId;
}

export class GesprekDto {
    @ApiProperty({type: String})
    readonly id!: GesprekId;
    @ApiProperty()
    readonly ownerId!: string;
    @ApiProperty({required: false})
    readonly avatar?: string;
    @ApiProperty({type: String})
    readonly status!: GesprekStatus;
    @ApiProperty({type: String})
    readonly type!: GesprekType;
    @ApiProperty({type: String, required: false})
    readonly fotoId?: FotoId;
    @ApiProperty({
        anyOf: [
            {$ref: getSchemaPath(TextBerichtDto)},
            {$ref: getSchemaPath(GedeeldeResourceBerichtDto)},
            {$ref: getSchemaPath(AttachmentBerichtDto)},
            {$ref: getSchemaPath(SpraakBerichtDto)},
            {$ref: getSchemaPath(SpraakBerichtDto)},
            {$ref: getSchemaPath(EventBerichtDto)},
            {$ref: getSchemaPath(GemaskeerdBerichtDto)},
        ],
        required: false,
    })
    readonly laatsteBericht?: BerichtDto;
    @ApiProperty({type: [DeelnemerDto]})
    readonly deelnemers!: DeelnemerDto[];
    @ApiProperty()
    readonly aangemaaktOp!: string;
    @ApiProperty({
        type: 'array',
        items: {
            anyOf: [
                {$ref: getSchemaPath(DeelnemerLeftEventDto)},
                {$ref: getSchemaPath(DeelnemerRejoinedEventDto)},
            ],
        },
    })
    readonly events!: GesprekEvent[]; //TODO: Backwards compatibility: 1.001.0041
    @ApiProperty({required: false})
    readonly channel?: string;
    @ApiProperty({type: String, required: false})
    readonly videogesprekId?: VideogesprekId;
}

export class BerichtEvent {
    @ApiProperty({type: String})
    readonly berichtId!: BerichtId;
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty()
    readonly timestamp!: string;
}
