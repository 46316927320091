import { inject } from '@angular/core';
import { ParkourPopupService } from '@parkour/ui';
import { CanDeactivateFn, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

export interface DirtyFormCheck {
  isFormDirty: () => boolean;
  canOverrideDirtyForm?: (nextStateUrl: string) => boolean;
}

function implementsDirtyFormCheck(component: NonNullable<unknown>): component is DirtyFormCheck {
  return typeof component === 'object' && component && 'isFormDirty' in component;
}

let popupAlreadyShown = false;

export const dirtyFormGuard: CanDeactivateFn<NonNullable<unknown>> = async (
  component,
  snapshot,
  nextState,
) => {
  const router = inject(Router);

  const info = router.getCurrentNavigation()?.extras.info;
  if (typeof info === 'object' && info && 'overridePopups' in info && info['overridePopups']) {
    return true;
  }

  if (!implementsDirtyFormCheck(component)) {
    return true;
  }

  if (!component.isFormDirty()) {
    return true;
  }
  if (component.canOverrideDirtyForm && component.canOverrideDirtyForm(nextState.url.toString())) {
    return true;
  }
  if (popupAlreadyShown) {
    return true;
  }

  popupAlreadyShown = true;

  const popupService = inject(ParkourPopupService);

  router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1),
    )
    .subscribe(() => (popupAlreadyShown = false));

  const popupResult = await popupService.showPopup({
    title: 'Wijzigingen annuleren',
    description: 'Je wijzigingen worden verwijderd. Wil je verder zonder opslaan?',
    icon: 'trash',
  });
  if (popupResult !== 'yes') {
    popupAlreadyShown = false;
  }
  return popupResult === 'yes';
};
