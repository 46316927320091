<div class="space-y-4">
  <!-- TODO: Remove this outdated form backup? -->
  <!--  <parkour-form-field class="block" inputId="email"-->
  <!--                      [label]="'profiel.emailadres' | translate"-->
  <!--                      [errorMessages]="{-->
  <!--                        pattern: 'Vul een geldig e-mailadres in.'-->
  <!--                      }"-->
  <!--                      [additionalErrors]="contactFormGroup.controls.email.errors"-->
  <!--                      [inputFormControl]="contactFormGroup.controls.email">-->
  <!--    <parkour-input-->
  <!--      class="block"-->
  <!--      type="email"-->
  <!--      inputId="email"-->
  <!--      inputPlaceholder="E-mail"-->
  <!--      pattern=".*@.*"-->

  <!--      [formControl]="contactFormGroup.controls.email"-->
  <!--    />-->
  <!--  </parkour-form-field>-->

  <!--  <parkour-form-field-->
  <!--    class="block"-->
  <!--    inputId="telefoon"-->
  <!--    [label]="'profiel.telefoonnummer' | translate"-->
  <!--    [inputFormControl]="contactFormGroup.controls.telefoonnummer"-->
  <!--    [errorMessages]="{-->
  <!--                        pattern: 'Vul een geldig GSM-nummer in. Voeg de landcode toe en gebruik geen spaties. Voorbeeld: +32482455642'-->
  <!--                      }"-->
  <!--    [additionalErrors]="contactFormGroup.controls.email.errors"-->
  <!--  >-->
  <!--    <parkour-input-->
  <!--      class="block"-->
  <!--      type="tel"-->
  <!--      inputId="telefoon"-->
  <!--      inputPlaceholder="Gsm-nummer"-->
  <!--      pattern="[+]?[0-9\s\-]*"-->
  <!--      [formControl]="contactFormGroup.controls.telefoonnummer"-->
  <!--    />-->
  <!--  </parkour-form-field>-->

  <!-- TODO: UX Copy Move errors to JSON -->
  <ion-radio-group [formControl]="contactFormGroup.controls.type" class="flex flex-col gap-y-4">
    <parkour-radio [inputId]="'EMAIL'" [label]="'E-mail'" [value]="'EMAIL'">
      <parkour-form-field-without-label
        [additionalErrors]="getEmailErrors()"
        [errorMessages]="{
          emailRequired: 'Vul een e-mailadres in.',
          pattern:
            'Vul een geldig e-mailadres in. Een e-mailadres bevat @ en een domein. Voorbeeld: email@domein.com',
        }"
        [inputFormControl]="contactFormGroup.controls.email"
        [required]="true"
        [accessibleLabel]="'E-mail'"
        class="w-full"
        errorLabel="e-mailadres"
        inputId="email"
      >
        <parkour-input
          *ngIf="contactFormGroup.controls.type.value === 'EMAIL'"
          [formControl]="contactFormGroup.controls.email"
          autocomplete="email"
          class="block mt-2"
          inputId="email"
          inputPlaceholder="E-mail"
          pattern=".*@.*"
          type="email"
        />
      </parkour-form-field-without-label>
    </parkour-radio>

    <parkour-radio [inputId]="'GSM'" [label]="'profiel.telefoonnummer' | translate" [value]="'GSM'">
      <parkour-form-field-without-label
        [additionalErrors]="getTelefoonnummerErrors()"
        [errorLabel]="'profiel.telefoonnummer' | translate"
        [errorMessages]="{
          telefoonnummerRequired: 'Vul een gsm-nummer in',
          pattern:
            'Vul een geldig GSM-nummer in. Voeg de landcode toe en gebruik geen spaties. Voorbeeld: +32482455642',
        }"
        [inputFormControl]="contactFormGroup.controls.telefoonnummer"
        [required]="true"
        [accessibleLabel]="'Gsm-nummer'"
        class="w-full"
        inputId="telefoon"
      >
        <parkour-input
          *ngIf="contactFormGroup.controls.type.value === 'GSM'"
          [formControl]="contactFormGroup.controls.telefoonnummer"
          autocomplete="tel"
          class="block mt-2"
          inputId="telefoon"
          pattern="^\+\d{8,15}$"
          type="tel"
        />
      </parkour-form-field-without-label>
    </parkour-radio>
  </ion-radio-group>
</div>
