import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of, ReplaySubject, skip, switchMap, take, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {
  ContextId,
  ContextOptionDto,
  MijnProfielDto,
  MyProfielUpsertDto,
  ProfielCreateOption,
  ProfielId,
} from 'parkour-web-app-dto';
import { asType, stripNullProperties } from '../../utils';
import { ContextOption } from '../model/context-option';
import { environment } from '../../../environments/environment';
import { ContextService } from '../../shared/services/context.service';
import { TeamService } from '../../team/service/team.service';
import { ActieveJongereProfiel, MijnProfiel } from '../model/profiel';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import { User } from '../../authentication/user';
import { ProfielInTeam } from '../model/profiel-in-team';

@Injectable({
  providedIn: 'root',
})
export class ProfielService {
  get userProfielOrUndefined$(): ReplaySubject<MijnProfiel | undefined> {
    return this._userProfielOrUndefined$;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
    private readonly teamService: TeamService,
  ) {
    this.userProfielOrUndefined$.pipe(skip(1)).subscribe(() => this.teamService.invalidateCache());
  }

  private retrieveProfielForCurrentUser(): Observable<void> {
    return this.http.get<MijnProfielDto>(`${environment.API_BASE_URL}/api/profiel/me`).pipe(
      map((profiel) => {
        this._userProfielOrUndefined$.next(profiel);
      }),
    );
  }
  public retrieveProfiel(user: User): Observable<void> {
    if (user.type === 'aangemeld') {
      return this.retrieveProfielForCurrentUser();
    } else {
      this._userProfielOrUndefined$.next(undefined);
      return of(undefined);
    }
  }

  private _userProfielOrUndefined$ = new ReplaySubject<MijnProfiel | undefined>(1);

  public getCurrentUserProfiel$(): Observable<MijnProfiel> {
    return this._userProfielOrUndefined$.pipe(
      take(1),
      map((profiel) => {
        if (profiel === undefined) {
          throw new Error('Profiel is niet geladen');
        }
        return profiel;
      }),
    );
  }

  updateMyProfiel(profiel: MyProfielUpsertDto): Observable<MijnProfiel> {
    return this.http
      .put<MijnProfielDto>(
        `${environment.API_BASE_URL}/api/profiel/me`,
        asType<MyProfielUpsertDto>(stripNullProperties(profiel)),
      )
      .pipe(tap((profiel) => this._userProfielOrUndefined$.next(profiel)));
  }

  uploadMyProfielFoto(profielFoto: File): Observable<void> {
    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadMyProfielFoto' };
    }
    const data: FormData = new FormData();
    data.append('file', profielFoto);

    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/profiel/me/foto`, data, { headers })
      .pipe(
        switchMap(() => {
          return this.retrieveProfielForCurrentUser();
        }),
      );
  }

  getMijnSortedContexten(): Observable<ContextOption[]> {
    return this.http
      .get<ContextOptionDto[]>(`${environment.API_BASE_URL}/api/profiel/me/contexten`)
      .pipe(
        map((contexten) => {
          contexten.sort((contextA, contextB) => {
            if (contextA.geblokkeerd !== contextB.geblokkeerd) {
              return contextA.geblokkeerd ? 1 : -1;
            }
            return contextA.teamOwnerNaam.localeCompare(contextB.teamOwnerNaam);
          });

          return contexten;
        }),
      );
  }

  public isActiefInContext(contextId: ContextId): Observable<boolean> {
    return this.getMijnSortedContexten().pipe(
      map((result) => {
        return (
          result.find(
            (contextOption) => contextOption.contextId === contextId && !contextOption.geblokkeerd,
          ) !== undefined
        );
      }),
    );
  }

  getMyProfielen(): Observable<MijnProfiel[]> {
    return this.http.get<MijnProfielDto[]>(`${environment.API_BASE_URL}/api/profiel/me/all`);
  }

  deleteMyProfielFoto() {
    return this.http.delete(`${environment.API_BASE_URL}/api/profiel/me/foto`).pipe(
      switchMap(() => {
        return this.retrieveProfielForCurrentUser();
      }),
    );
  }

  getCurrentUserProfielInTeam$(): Observable<ProfielInTeam> {
    return this.getCurrentUserProfiel$().pipe(
      switchMap((profiel) => this.getProfielInCurrentTeam(profiel.id)),
    );
  }

  getProfielInCurrentTeam(profielId: ProfielId): Observable<ProfielInTeam> {
    return this.contextService.contextWithJongere$().pipe(
      mergeMap((context) => {
        if (context.contextId === profielId) {
          switch (context.type) {
            case 'ex-jongere':
            case 'jongere':
              return this.getCurrentUserProfiel$().pipe(
                map((profiel) =>
                  asType<ActieveJongereProfiel>({
                    viewType: 'JONGERE',
                    ...profiel,
                  }),
                ),
              );
            case 'teamlid':
              return of(context.jongereProfiel);
          }
        } else {
          return this.teamService.getTeamlidInCurrentContext(profielId);
        }
      }),
    );
  }

  markeerMijnProfielVoorVerwijdering(): Observable<void> {
    return this.http
      .post<void>(`${environment.API_BASE_URL}/api/profiel/me/markeer-voor-verwijdering`, {})
      .pipe(switchMap(() => this.retrieveProfielForCurrentUser()));
  }

  onmarkeerMijnProfielVoorVerwijdering() {
    return this.http
      .post<void>(`${environment.API_BASE_URL}/api/profiel/me/onmarkeer-voor-verwijdering`, {})
      .pipe(switchMap(() => this.retrieveProfielForCurrentUser()));
  }

  getProfielCreateOptions() {
    return this.http.get<ProfielCreateOption[]>(
      `${environment.API_BASE_URL}/api/profiel/create-options`,
    );
  }
}
