<ion-header></ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="teamlidContext$ | profielFotoOfJongere | async"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <div class="container-1-col">
        <parkour-button class="w-full flex" iconAfter="paper-plane" link="suggereer">
          {{ 'verhaal.suggest-gebeurtenis' | translate }}
        </parkour-button>
      </div>

      <ng-container
        *parkourSectionContent="
          {
            data: mainPageData$ | async,
            errorDescription:
              'We kunnen sommige gebeurtenissen nu niet weergeven, probeer later opnieuw.',
          };
          let verhaal
        "
      >
        @if (verhaal.items.length === 0) {
          <p class="text-center container-1-col">
            @if (teamlidContext$ | async; as teamlidContext) {
              {{ teamlidContext.jongereProfiel | profielnaam }} heeft geen gebeurtenissen gedeeld.
            }
          </p>
        }

        <parkour-gebeurtenis-list
          [verhaal]="verhaal"
          [ongelezenGebeurtenisMeldingen]="(ongelezenGebeurtenisMeldingen$ | async) ?? []"
        />
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
