import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-page-layout',
  templateUrl: './page-layout.component.html',
  imports: [NgClass, AsyncPipe],
})
export class PageLayoutComponent {
  @HostBinding('class') class = 'h-full block';

  sidebarVisible$: Observable<boolean> = this.activatedRoute.data.pipe(
    map((data) => {
      return !(data['sidebarVisible'] === false || data['hideTabs'] === true);
    }),
  );

  constructor(private readonly activatedRoute: ActivatedRoute) {}
}
