import { Component, Input } from "@angular/core";
import { TParkourCardAlign, TParkourCardTheme } from "./parkour-card.types";
import {
  TParkourIconName,
  TParkourIconSizes,
} from "../parkour-icon/parkour-icon.types";
import { TParkourBadgeTheme } from "../parkour-badge/parkour-badge.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourNewIndicatorComponent } from "../parkour-new-indicator/parkour-new-indicator.component";
import { ParkourBadgeComponent } from "../parkour-badge/parkour-badge.component";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";

@Component({
  standalone: true,
  selector: "parkour-card",
  templateUrl: "./parkour-card.component.html",
  imports: [
    NgClass,
    ParkourNewIndicatorComponent,
    ParkourBadgeComponent,
    NgIf,
    ParkourImageComponent,
  ],
})
export class ParkourCardComponent {
  @Input() icon?: TParkourIconName;
  @Input() iconTheme?: TParkourBadgeTheme;
  @Input() image?: string | null;
  @Input() imageAlt?: string;
  @Input() theme: TParkourCardTheme = "n-300";
  @Input() iconSize: TParkourIconSizes = "3";
  @Input() aligned: TParkourCardAlign = "start";
  @Input() showIndicator: boolean | null = false;
  @Input() clickable = false
}
