import { IdOf } from '../branded-type';
import { Rol } from './persoon.dto';
import { PersoonId } from './user.dto';
import { ApiProperty } from '@nestjs/swagger';

export type ProfielId = IdOf<'profiel'>;
export type ContextId = ProfielId | 'anoniem';
export type ProfielFotoId = IdOf<'profielFoto'>;
export type ProfielNaamType = 'ROEPNAAM' | 'VOLLEDIGE_NAAM';
export type ProfielType = 'PERSOONLIJK_TEAMLID' | 'PROFESSIONEEL_TEAMLID' | 'JONGERE' | 'EX_JONGERE';
export type OldProfielType = 'PROFESSIONEEL' | 'PERSOONLIJK';

export class TeamDto {
  @ApiProperty({ type: String })
  readonly teamlidProfielId!: ProfielId;
  @ApiProperty({ type: String })
  readonly Rol!: Rol;
  @ApiProperty()
  readonly jongereProfielnaam!: string;
  @ApiProperty()
  readonly geblokkeerd!: boolean;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  @ApiProperty({ type: String })
  readonly profielFotoId!: ProfielFotoId;
}

export class RolesDto {
  @ApiProperty({type: [TeamDto]})
  readonly teams!: TeamDto[];
}

export type ContextOptionType = 'jongere' | 'voor-mezelf' | 'teamlid' | 'ex-jongere';

export class ActieveContextOptionDto {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly type!: ContextOptionType;
  @ApiProperty()
  readonly geblokkeerd!: false;
  @ApiProperty({ type: String, required: false })
  readonly rol?: Rol;
  @ApiProperty()
  readonly teamOwnerNaam!: string;
  @ApiProperty({ type: String })
  readonly profielFotoId!: ProfielFotoId;
}

export class GeblokkeerdeContextOptionDto {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly type!: ContextOptionType;
  @ApiProperty()
  readonly geblokkeerd!: true;
  @ApiProperty({ type: String, required: false })
  readonly rol?: Rol;
  @ApiProperty()
  readonly teamOwnerNaam!: string;
}

export type ContextOptionDto = ActieveContextOptionDto | GeblokkeerdeContextOptionDto;
export type ProfielCreateOption = "PROFESSIONEEL" | "PERSOONLIJK";

export class AangemeldRoleDto {
  readonly type!: 'AANGEMELD';
  @ApiProperty({ type: String })
  readonly profielId!: ProfielId;
}

export type RoleDto = AangemeldRoleDto;

export const avatars = [
  'AVATAR_1',
  'AVATAR_2',
  'AVATAR_3',
  'AVATAR_4',
  'AVATAR_5',
  'AVATAR_6',
  'AVATAR_7',
  'AVATAR_8',
  'AVATAR_9',
  'AVATAR_10',
  'AVATAR_11',
  'AVATAR_12',
  'AVATAR_13',
  'AVATAR_14',
  'AVATAR_15',
  'AVATAR_16',
  'AVATAR_17',
  'AVATAR_18',
  'AVATAR_19',
  'AVATAR_20',
] as const;

export type Avatar = (typeof avatars)[number];

export class AfwezigheidDto {
  @ApiProperty()
  readonly afwezig!: boolean;
  @ApiProperty({required: false})
  readonly van?: string;
  @ApiProperty({required: false})
  readonly tot?: string;
  @ApiProperty({required: false})
  readonly bericht?: string;
}

export class ProfielDto {
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  @ApiProperty({ type: String })
  readonly persoonId!: PersoonId;
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
  @ApiProperty({ type: String })
  readonly status!: 'ACTIEF';
  @ApiProperty({ type: String })
  readonly type!: ProfielType;
  @ApiProperty({ type: String })
  readonly profielnaamType!: ProfielNaamType;
  @ApiProperty({ type: String, required: false })
  readonly roepnaam?: string;
  @ApiProperty({ type: String, required: false })
  readonly bio?: string;
  @ApiProperty({ type: String, required: false })
  readonly adres?: string;
  @ApiProperty({type: [String]})
  readonly links!: string[];
  @ApiProperty({ type: String })
  readonly avatar!: Avatar;
  @ApiProperty()
  readonly hasProfielFoto!: boolean;
  @ApiProperty({ type: String })
  readonly profielFotoId!: ProfielFotoId;
  @ApiProperty({required: false})
  readonly gemarkeerdVoorVerwijderingSinds?: string;
  @ApiProperty()
  readonly afwezigheid!: AfwezigheidDto;

  // TODO: Backwards compatibility: 1.003, remove unneeded profielType && isJongere
  @ApiProperty()
  readonly isJongere!: boolean;
  @ApiProperty({ type: String })
  readonly profielType!: OldProfielType;
}

export class MijnProfielDto extends ProfielDto {
  @ApiProperty({required: false})
  readonly email?: string;
  @ApiProperty({required: false})
  readonly telefoonnummer?: string;
  @ApiProperty({ type: Boolean })
  readonly contactMethodeGeverifieerd!: boolean;
  @ApiProperty({required: false})
  readonly geboortedatum?: string;
}

export class MyProfielUpsertDto {
  @ApiProperty({required: false})
  readonly roepnaam?: string;
  @ApiProperty({ type: String })
  readonly type!: 'PROFESSIONEEL_TEAMLID' | 'JONGERE' | 'UNSPECIFIED';
  @ApiProperty({ type: String })
  readonly profielnaamType!: ProfielNaamType;
  @ApiProperty({ type: String })
  readonly avatar!: Avatar;
  @ApiProperty({required: false})
  readonly bio?: string;
  @ApiProperty({required: false})
  readonly adres?: string;
  @ApiProperty({type: [String]})
  readonly links!: string[];
  @ApiProperty({required: false})
  readonly email?: string;
  @ApiProperty({required: false})
  readonly telefoonnummer?: string;
  @ApiProperty({required: false})
  readonly afwezigheid?: AfwezigheidDto;
  @ApiProperty({required: false})
  readonly geboortedatum?: string;
  @ApiProperty({required: false})
  readonly professioneel?: boolean;
}

export class VerifieerContactMethodeDto {
  @ApiProperty({ type: String })
  readonly code!: string;
}

export function getProfielVolledigeNaam(profiel: { voornaam: string; naam: string }): string {
  return profiel.voornaam + ' ' + profiel.naam;
}

export function getProfielnaamWithoutFallback(profiel: {
  profielnaamType: ProfielNaamType;
  roepnaam?: string;
  voornaam: string;
  naam: string;
}) {
  switch (profiel.profielnaamType) {
    case 'ROEPNAAM':
      return profiel.roepnaam ?? getProfielVolledigeNaam(profiel);
    case 'VOLLEDIGE_NAAM':
      return getProfielVolledigeNaam(profiel);
  }
}

export function validatedProfielId(id?: string | null): ProfielId {
  if (!id) {
    throw new Error('ProfielId is undefined');
  }

  return id as ProfielId;
}
