<ion-header class="w-full transform-none"> </ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <div class="py-8 container container-space">
      <p class="container-1-col">Bedankt voor je feedback</p>
      <!-- TODO: UX Copy uitnodiging aan JSON toevoegen -->
    </div>
  </parkour-page-layout>
</ion-content>
