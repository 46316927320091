// eslint-disable-next-line @typescript-eslint/ban-types
import {getSchemaPath} from "@nestjs/swagger";

export function generateSchemaPaths(meldingen: (string | Function)[]): {
    $ref: string;
}[] {
    return meldingen.map(
        (melding) => ({
            $ref: getSchemaPath(melding),
        }),
    );
}