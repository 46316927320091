<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'over-parkour.aan-de-slag-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">{{ 'over-parkour.aan-de-slag-page.title' | translate }}</h1>

        <parkour-divider></parkour-divider>

        <p class="text-center mt-8">
          {{ 'over-parkour.aan-de-slag-page.description' | translate }}
        </p>

        <ul class="mt-8 space-y-20">
          <li *ngFor="let item of onboardingItems">
            <div class="flex flex-col gap-y-4 max-w-[75vw] mx-auto text-center">
              <parkour-image [alt]="item.image.alt" [src]="item.image.src" />

              <div class="flex flex-col gap-y-1">
                <div class="flex gap-x-2 items-center justify-center text-p-200">
                  <parkour-icon [name]="item.icon" class="flex" />

                  <h2 class="typo-h2">{{ item.title }}</h2>
                </div>

                <p>{{ item.description }}</p>
              </div>
            </div>
          </li>
        </ul>

        <div class="flex flex-col gap-y-4 mt-20">
          <h2 class="typo-h4">{{ 'over-parkour.aan-de-slag-page.hulp-nodig' | translate }}</h2>

          <parkour-button
            [link]="['..', 'veelgestelde-vragen']"
            iconAfter="chevron-right"
            variant="ghost"
          >
            {{ 'over-parkour.aan-de-slag-page.veelgestelde-vragen' | translate }}
          </parkour-button>
        </div>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
