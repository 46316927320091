import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable()
export class AppTitlePrefix extends TitleStrategy {
  private prefix = 'PARKOUR';

  constructor(
    private title: Title,
    private readonly translateService: TranslateService,
    private tracker: MatomoTracker,
  ) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (title) {
      this.translateService
        .get(title)
        .subscribe((translatedTitle) => this.setTitleWithPrefix(translatedTitle));
    }
  }

  setTitleWithPrefix(title: string): void {
    this.title.setTitle(`${title} - ${this.prefix}`);
  }

  setTitleWithCategory(title: string, category: string): void {
    this.tracker.trackPageView(category);
    this.setTitleWithPrefix(`${title} (${category})`);
  }
}
