<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['..']" />
    </ion-buttons>

    <ion-title>{{ 'doelen.doel-detail-page.title' | translate }}</ion-title>

    <ion-buttons *parkourShowIfSuccessful="mainPageData$ | async; let data" slot="end">
      <parkour-header-action
        [label]="'doelen.doel-detail-page.doel-bewerken' | translate"
        [link]="data.doel.isBehaald ? ['edit-behaald'] : ['edit']"
        form="doel-new-form"
        icon="edit"
        testId="edit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <article>
        <parkour-page-image
          [src]="themaMapping[data.doel.thema].largeImage.blur"
          classes="absolute top-0 left-0 z-0"
        />

        <div class="relative z-10 pt-4 pb-8 container container-space flex flex-col gap-y-8">
          <parkour-doel-details
            (actiesModified)="updateActies($event)"
            (ignoreGebeurtenisSuggestie)="ignoreGebeurtenisSuggestie($event)"
            [contextType]="'jongere'"
            [doel]="data.doel"
            [editable]="true"
          />

          @if (!data.doel.isBehaald) {
            <div class="flex flex-col items-center container-1-col">
              @if (data.doel.alleActiesZijnVoltooid()) {
                <p class="typo-body variant-body-fat mb-4">
                  {{ 'doelen.doel-detail-page.alle-acties-afgevinkt' | translate }}
                </p>
              }

              <parkour-button (clicked)="completeDoel(data.doel)" class="w-full" iconAfter="check">
                {{ 'doelen.doel-detail-page.doel-voltooien' | translate }}
              </parkour-button>
            </div>
          }

          @if (doelCompleteAnimationOpen) {
            <parkour-animation-modal
              (completed)="doelCompleteAnimationOpen = false"
              [src]="environment.API_BASE_URL + '/assets/doelen/animatie_doel_behaald.json'"
            />
          }

          <ng-container *parkourSectionContent="{ data: gesprekData$ | async }; let gesprekData">
            <parkour-reactie
              (reactieSelect)="onReactieSelect(data.doel.id, $event)"
              (reactieUnSelect)="onReactieUnSelect(data.doel.id, $event)"
              *parkourSectionContent="{ data: reacties$ | async }; let reacties"
              [reactieOverview]="reacties"
              [userProfielId]="gesprekData.user.profielId"
              class="block container-1-col"
            />

            <parkour-doel-berichten
              (openReactiesClicked)="onOpenReacties()"
              [doelId]="data.doel.id"
              [gesprek]="gesprekData.gesprek"
              [user]="gesprekData.user"
              [profiel]="gesprekData.profiel"
              class="block container-1-col"
            />
          </ng-container>

          <ng-container *parkourSectionContent="{ data: artikelsData$ | async }; let artikels">
            <div class="container-1-col mt-6">
              <div class="flex items-center gap-x-2 mb-4">
                <parkour-icon class="flex" name="book" size="3" />

                <h2 class="typo-h4">
                  {{
                    'doelen.doel-detail-page.articles-about'
                      | translate
                        : {
                            thema: themaMapping[data.doel.thema].label,
                          }
                  }}
                </h2>

                <div class="flex-1 h-[1.5px] w-full opacity-30 bg-n-600"></div>
              </div>

              <ul class="flex flex-col mt-6 gap-y-2">
                @for (artikel of artikels; track artikel.id) {
                  <li>
                    <parkour-article-card
                      (clickedBookmark)="onBookmarkClicked($event, artikel.id, artikel.bookmarkId)"
                      [image]="{ src: artikel.image, alt: '' }"
                      [link]="['artikel', String(artikel.id)]"
                      [themes]="artikel.themas"
                      [title]="artikel.title"
                      [isBookmarked]="!!artikel.bookmarkId"
                      titleTag="h3"
                    />
                  </li>
                }
              </ul>

              <parkour-button
                [link]="['artikels']"
                [queryParams]="{ thema: data.doel.thema }"
                class="self-center block w-full mt-4"
                iconAfter="chevron-right"
                variant="ghost"
              >
                {{
                  'doelen.doel-detail-page.meer-artikels-over'
                    | translate
                      : {
                          thema: themaMapping[data.doel.thema].label,
                        }
                }}
              </parkour-button>
            </div>
          </ng-container>
        </div>
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
