import { Injectable } from '@angular/core';
import { ParkourBeeldbellenAnchorDirective } from './directive/parkour-beeldbellen-anchor.directive';
import { JitsiComponent } from './component/jitsi.component';
import { HttpClient } from '@angular/common/http';
import { JitsiGesprekDto, VideogesprekId } from 'parkour-web-app-dto';
import { environment } from '../../environments/environment';
import { getProfielnaam } from '../profiel/model/profiel-in-team';
import { Observable, switchMap } from 'rxjs';
import { JongereProfiel, Profiel } from '../profiel/model/profiel';
import { TeamlidProfiel } from '../team/model/teamlid.model';
import { PermissionService } from '../shared/services/permission.service';

export type VideogesprekData = {
  jongere: JongereProfiel;
  teamlid: TeamlidProfiel;
  videogesprekId: VideogesprekId;
};

@Injectable({
  providedIn: 'root',
})
export class BeeldbellenService {
  private anchor: ParkourBeeldbellenAnchorDirective | undefined;

  constructor(
    private readonly http: HttpClient,
    private readonly permissionService: PermissionService,
  ) {}

  registerAnchor(anchor: ParkourBeeldbellenAnchorDirective) {
    this.anchor = anchor;
  }

  startVideogesprek(data: VideogesprekData, naam: string, profiel: Profiel): Observable<void> {
    return this.http
      .put<JitsiGesprekDto>(
        `${environment.API_BASE_URL}/api/beeldbellen/gesprekken/${data.videogesprekId}`,
        {
          naam,
        },
      )
      .pipe(
        switchMap(async (jitsiGesprekDto) =>
          this.openJitsiComponent(jitsiGesprekDto, profiel, data),
        ),
      );
  }

  private generateCustomRoomName(data: VideogesprekData) {
    return getProfielnaam(data.jongere) + ' - ' + getProfielnaam(data.teamlid);
  }

  private async openJitsiComponent(
    jitsiGesprekDto: JitsiGesprekDto,
    profiel: Profiel,
    data: VideogesprekData,
  ) {
    if (!this.anchor) {
      throw new Error('no anchor found to bind jitsi container');
    }

    await this.permissionService.checkJitsiPermisssions();

    const jitsiComponentRef = this.anchor.viewContainerRef.createComponent(JitsiComponent);
    jitsiComponentRef.instance.readyToClose.subscribe(() => {
      this.anchor?.viewContainerRef.clear();
    });

    jitsiComponentRef.setInput('customRoomName', this.generateCustomRoomName(data));
    jitsiComponentRef.setInput('roomId', jitsiGesprekDto.room);
    jitsiComponentRef.setInput('jwt', jitsiGesprekDto.jwt);
    jitsiComponentRef.setInput('profiel', profiel);
    jitsiComponentRef.setInput('data', data);
  }

  stopCall(videogesprekId: VideogesprekId, fullRoomName: string): Observable<void> {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/beeldbellen/gesprekken/${videogesprekId}/call/stop`,
      {},
      { params: { fullRoomName: encodeURIComponent(fullRoomName) } },
    );
  }
}
