import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkify-string';

export const linkify = (text: string) => {
  const formatValue = linkifyStr(text, {
    defaultProtocol: 'https',
    target: 'blank',
    className: 'break-all',
  });
  const phoneNumberRegex = /^(?:\+32\s?|0)(4\d{2}|\d{2})\s?\d{3}\s?\d{2}\s?\d{2}$/g;

  return formatValue.replace(phoneNumberRegex, (match) => {
    const cleanNumber = match.replace(/(?!^\+)\D/g, '');

    return `<a target="_blank" href="tel:${cleanNumber}">${match}</a>`;
  });
};

@Pipe({
  name: 'linkify',
  standalone: true,
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    return linkify(value);
  }
}
