import { Component, Input } from "@angular/core";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import {NgClass} from "@angular/common";

@Component({
  selector: "parkour-info-card",
  templateUrl: "./parkour-info-card.component.html",
  standalone: true,
  imports: [ParkourIconComponent, NgClass],
})
export class ParkourInfoCardComponent {
  @Input() public icon: TParkourIconName = "info-circle";
  @Input() public style: "bg-n-300" | "bg-n-200" | "bg-s-100" = "bg-n-300";
  @Input() public classes?: string;
}
