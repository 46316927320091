import {Component, input, Input, signal} from "@angular/core";
import {ParkourInfoCardComponent, TParkourIconName} from "@parkour/ui";

@Component({
  selector: "parkour-info-card-with-title",
  templateUrl: "./parkour-info-card-with-title.component.html",
  standalone: true,
  imports: [
    ParkourInfoCardComponent
  ],
})
export class ParkourInfoCardWithTitleComponent {
  public readonly icon = input<TParkourIconName>("info-circle");
  public readonly infoCardStyle = input<"bg-n-300" | "bg-s-100">("bg-n-300");
  public title = input.required<string>();
}
