<div class="flex flex-col items-center gap-y-4">
  <span class="typo-body variant-body-fat">{{
    'over-parkour.waarom-parkour-page.download-apps' | translate
  }}</span>

  <div class="flex items-center gap-x-3">
    <parkour-action
      href="https://play.google.com/store/apps/details?id=be.opgroeien.parkour&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
      <parkour-image
        alt="Ontdek het op Google Play"
        class="block h-[3.25rem] max-w-36"
        fit="contain"
        src="/assets/download/download_google_play.png"
      />
    </parkour-action>

    <parkour-action
      href="https://apps.apple.com/be/app/parkour/id6469707291?itsct=apps_box_badge&amp;itscg=30200"
    >
      <parkour-image
        alt="Download in de App Store"
        class="block h-[3.25rem] py-[0.375rem]"
        fit="contain"
        src="/assets/download/download_app_store.svg"
      />
    </parkour-action>
  </div>
</div>
