<ion-header></ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <parkour-contact-methode-niet-geverifieerd-card info-card />
      <ng-container
        *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let profiel"
      >
        <div class="container-1-col space-y-8">
          <p class="text-center">
            @if (profiel.type === 'PROFESSIONEEL_TEAMLID') {
              {{ 'verhaal.overview-page.no-team-text-professioneel' | translate }}
            } @else {
              {{ 'verhaal.overview-page.no-team-text' | translate }}
            }
          </p>

          <parkour-verhaal-placeholder />
        </div>
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
