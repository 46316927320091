import { Routes } from '@angular/router';
import { BerichtProbleemAddPage } from '../../bericht/page/bericht-probleem-add-page/bericht-probleem-add.page';
import { NotitieAddPage } from '../../profiel/page/notitie-add-page/notitie-add.page';
import { artikelOverviewRoutes, specificArtikelRoute } from '../../watwat/routes';
import { DoelenTeamlidPage } from './doelen-teamlid-page/doelen-teamlid.page';
import { DoelSuggestiePage } from './doel-suggestie-page/doel-suggestie.page';
import { DoelDetailsTeamlidPage } from './doel-details-teamlid-page/doel-details-teamlid.page';
import { ContactMethodeBevestigenPageComponent } from '../../profiel/page/contact-methode-bevestigen-page/contact-methode-bevestigen-page.component';

export const doelTeamlidRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DoelenTeamlidPage,
    data: { tabRoot: true },
  },
  {
    path: 'suggereer',
    component: DoelSuggestiePage,
    title: 'doelen.header.suggestie',
  },
  {
    path: 'contact-methode-bevestigen',
    component: ContactMethodeBevestigenPageComponent,
    data: { hideTabs: true },
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DoelDetailsTeamlidPage,
      },
      artikelOverviewRoutes,
      specificArtikelRoute,
      {
        path: 'probleem',
        component: BerichtProbleemAddPage,
      },
    ],
  },
  {
    path: ':id/notitie',
    component: NotitieAddPage,
  },
];
