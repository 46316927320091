import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { Doel, DoelId } from '../../model/doel';
import { combineLatest, map, switchMap, take, takeUntil } from 'rxjs';
import { validateDoelId } from '../../../utils';
import { Component, inject, viewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ReactieService } from '../../service/reactie.service';
import { DoelenService } from '../../service/doelen.service';
import { BerichtenService } from '../../../../bericht/service/berichten.service';
import AuthService from '../../../../authentication/service/auth.service';
import { ProfielService } from '../../../../profiel/service/profiel.service';
import { ArtikelId, BookmarkId, InteractieEmoji, ReactieDto } from 'parkour-web-app-dto';
import { SheetService } from 'src/app/shared/services/parkour-sheet.service';
import { themaMapping } from 'src/app/home/model/thema.const';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { DoelBerichtenComponent } from '../../../../bericht/component/doel-berichten/doel-berichten.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { BookmarkService } from '../../../../shared/services/bookmark.service';
import { WatwatService } from '../../../../shared/services/watwat.service';

@Component({ template: '' })
export abstract class DoelDetailPage
  extends ParkourDataPage<{
    doel: Doel;
    sheetOpen: boolean;
  }>
  implements ViewWillEnter, ViewWillLeave
{
  protected readonly route = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly reactieService = inject(ReactieService);
  protected readonly profielService = inject(ProfielService);
  protected readonly authService = inject(AuthService);
  protected readonly berichtenService = inject(BerichtenService);
  protected readonly doelenService = inject(DoelenService);
  protected readonly sheetService = inject(SheetService);
  protected readonly bookmarkService = inject(BookmarkService);
  protected readonly watwatService = inject(WatwatService);
  protected readonly themaMapping = themaMapping;
  private readonly doelBerichten = viewChild<DoelBerichtenComponent>(DoelBerichtenComponent);
  private readonly doelBerichten$ = toObservable(this.doelBerichten);

  doelId$ = this.route.paramMap.pipe(
    take(1),
    map((params) => validateDoelId(params.get('id'))),
  );

  private sheetOpen$ = this.route.queryParamMap.pipe(
    map((params) => {
      const sheet = params.get('sheet');
      return sheet === 'true';
    }),
  );

  reacties$ = this.extraPageData(
    this.doelId$.pipe(switchMap((id) => this.reactieService.getReactiesForDoel(id))),
  );

  gesprekData$ = this.extraPageData(
    this.doelId$.pipe(
      switchMap((id) =>
        combineLatest({
          profiel: this.profielService.getCurrentUserProfiel$(),
          user: this.authService.getAangemeldeUser$(),
          gesprek: this.berichtenService.getGesprekOfDoel(id),
        }),
      ),
    ),
  );

  doel$ = this.doelId$.pipe(
    switchMap((doelId) => {
      return this.doelenService.getDoel(doelId);
    }),
  );

  artikelsData$ = this.extraPageData(
    this.doel$.pipe(switchMap((doel) => this.watwatService.getSuggestedArtikels(doel.thema))),
  );

  override retrieveMainData() {
    return combineLatest({ sheetOpen: this.sheetOpen$, doel: this.doel$ });
  }

  override ionViewWillLeave() {
    super.ionViewWillLeave();
    this.doelBerichten()?.pageViewWillLeave();

    this.sheetService.close();
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();
    this.doelBerichten()?.pageViewWillEnter();

    combineLatest([this.mainPageData$, this.doelBerichten$])
      .pipe(takeUntil(this.pageWillLeave$))
      .subscribe(([data, doelBerichten]) => {
        if (!data.success) return;

        if (data.value.sheetOpen && doelBerichten) {
          this.sheetService.open(doelBerichten.getPortal());
        } else {
          this.sheetService.close();
        }
      });

    this.router.events.pipe(takeUntil(this.pageWillLeave$)).subscribe((event) => {
      if (event instanceof NavigationStart && !event.url.includes('sheet')) {
        this.sheetService.close(); // Safeguard to ensure the sheet is closed when navigating away
      }
    });

    this.sheetService.closeTrigger$.pipe(takeUntil(this.pageWillLeave$)).subscribe(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          sheet: false,
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  onReactieSelect(doelId: DoelId, reactieEmoji: InteractieEmoji) {
    this.reactieService.updateReactie(reactieEmoji, doelId).pipe(take(1)).subscribe();
  }

  onReactieUnSelect(doelId: DoelId, reactie: ReactieDto) {
    this.reactieService.verwijderReactie(reactie, doelId).pipe(take(1)).subscribe();
  }

  onOpenReacties() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sheet: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  onBookmarkClicked(
    hasBookmarked: boolean,
    artikelId: ArtikelId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.bookmarkService
        .addBookmark({
          itemId: String(artikelId),
          itemType: 'ARTIKEL',
        })
        .subscribe(() => this.refreshMainData());
    } else {
      if (bookmarkId) {
        this.bookmarkService.deleteBookmark(bookmarkId).subscribe(() => this.refreshMainData());
      }
    }
  }
}
