import { Component } from '@angular/core';
import { forkJoin, map, Observable, shareReplay, Subscription, take } from 'rxjs';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import {
  ParkourButtonComponent,
  ParkourInfoCardComponent,
  ParkourPageGradientComponent,
  ParkourPageImageComponent,
  ParkourPopupComponent,
} from '@parkour/ui';
import { DoelDetailsHeroComponent } from '../../doel-shared/component/doel-details-hero/doel-details-hero.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DoelEditDelenSectionComponent } from '../../doel-shared/component/doel-edit-delen-section/doel-edit-delen-section.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular/standalone';
import { combineResults, Result } from '../../../utils';
import { Doel } from '../../doel-shared/model/doel';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { DelenSectionGroup } from '../../../delen/model/delen-form-group';
import { themaMapping } from 'src/app/home/model/thema.const';
import { DoelenService } from '../../doel-shared/service/doelen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DelenFormService } from '../../../delen/service/delen-form.service';
import { TeamService } from '../../../team/service/team.service';
import { AppTitlePrefix } from '../../../app-title.prefix';
import { validateDoelId } from '../../utils';

@Component({
  standalone: true,
  templateUrl: './behaald-doel-edit.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    ParkourPageImageComponent,
    DoelDetailsHeroComponent,
    ParkourInfoCardComponent,
    ReactiveFormsModule,
    DoelEditDelenSectionComponent,
    ParkourButtonComponent,
    ParkourPopupComponent,
    TranslateModule,
    AsyncPipe,
    PageLayoutComponent,
    PageContentDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonBackButton,
    ParkourPageGradientComponent,
  ],
})
export class BehaaldDoelEditPage implements ViewWillEnter, ViewWillLeave {
  data$!: Observable<
    Result<{
      doel: Doel;
      teamleden: TeamlidProfiel[];
    }>
  >;
  id!: string | null;

  delenForm?: DelenSectionGroup;

  isVerwijderBehaaldDoelPopupOpen = false;
  subscription?: Subscription;
  protected readonly themaMapping = themaMapping;

  constructor(
    private doelenService: DoelenService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly delenFormService: DelenFormService,
    private readonly teamService: TeamService,
    private readonly appTitlePrefix: AppTitlePrefix,
    private readonly translate: TranslatePipe,
  ) {}

  ionViewWillLeave(): void {
    this.subscription?.unsubscribe();
    this.delenForm = undefined;
  }

  async ionViewWillEnter(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');

    this.data$ = forkJoin({
      doel: this.doelenService.getDoel(validateDoelId(this.id)),
      teamleden: this.teamService.teamleden$.pipe(take(1)),
    }).pipe(map(combineResults), shareReplay(1));

    this.subscription = this.data$.subscribe((data) => {
      if (data.success) {
        this.delenForm = this.delenFormService.createDelenFormGroup(
          data.value.teamleden,
          data.value.doel,
        );
      }
    });

    this.data$.subscribe((data) => {
      const categoryName = this.translate.transform('doelen.behaalde-doelen-edit-page.title');

      if (data.success) {
        this.appTitlePrefix.setTitleWithCategory(data.value.doel.naam, categoryName);
      } else {
        this.appTitlePrefix.setTitleWithPrefix(categoryName);
      }
    });
  }

  verwijderDoel() {
    this.isVerwijderBehaaldDoelPopupOpen = false;

    this.doelenService.deleteDoel(validateDoelId(this.id)).subscribe(async () => {
      await this.router.navigate(['../../'], { relativeTo: this.route });
    });
  }

  onFormSubmit(doel: Doel, teamleden: TeamlidProfiel[]) {
    const delenForm = this.delenForm;

    if (!delenForm) {
      throw new Error('Form niet herkend');
    }

    this.doelenService
      .updateDoel(doel.id, {
        ...doel,
        deelMode: delenForm.getRawValue().deelMode,
        gedeeldMet: teamleden
          .filter((teamLid, index) => delenForm.getRawValue().gedeeldMet[index].gedeeldMet)
          .map((teamlid) => teamlid.id),
      })
      .subscribe(() => this.router.navigate(['..'], { relativeTo: this.route }));
  }
}
