import {
  GebeurtenisDto,
  GebeurtenisId as GebeurtenisIdDto,
  GebeurtenisUpsertDto,
  ParkourGebeurtenisUpsertDto,
} from 'parkour-web-app-dto';
import { GebeurtenisSuggestieDto, SuggestieId } from 'parkour-web-app-dto';

export type Gebeurtenis = GebeurtenisDto;
export type GebeurtenisId = GebeurtenisIdDto;
export type GebeurtenisUpsert = GebeurtenisUpsertDto;
export type ParkourGebeurtenisUpsert = ParkourGebeurtenisUpsertDto;
export type GebeurtenisSuggestie = GebeurtenisSuggestieDto;

export function validatedSuggestieId(id: string | undefined | null): SuggestieId {
  if (id === null || id === undefined) {
    throw new Error('SuggestieId cannot be undefined');
  }

  return id as SuggestieId;
}
