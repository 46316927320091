<ul>
  @for (
    helpline of helplines;
    track helpline;
    let helplineFirst = $first;
    let helplineLast = $last
  ) {
    @for (
      method of helpline.methods;
      track method;
      let methodFirst = $first;
      let methodLast = $last
    ) {
      <li *ngIf="getHelplineMethodView(helpline, method) as methodView">
        <article
          class="relative w-full p-3 border-n-400 border rounded-tl flex gap-3 card-hover-state-300"
          [ngClass]="{
            'rounded-tl rounded-tr': helplineFirst && methodFirst,
            'rounded-bl rounded-br border-b': helplineLast && methodLast,
            'border-b-0': !helplineLast || !methodLast,
          }"
        >
          <parkour-icon
            [name]="methodView.icon"
            size="2"
            class="w-8 h-8 rounded flex self-start justify-center items-center"
            [ngClass]="{
              'bg-n-400': !method.nowOpen,
              'bg-n-200 text-s-100': method.nowOpen,
            }"
          />

          <div class="flex flex-col items-start flex-1 text-left">
            <span class="typo-body variant-body-fat mb-1">
              <parkour-action
                [ariaLabel]="methodView.ariaLabel"
                [fillContainer]="true"
                [href]="methodView.href"
              >
                {{ methodView.title }}
              </parkour-action>
            </span>

            <p class="typo-body text-n-700">
              {{ method.description }}
            </p>

            @if (method.nowOpen) {
              <parkour-tag variant="cyan" size="large" class="block mt-3">
                {{ 'watwat.hulplijnen-page.open' | translate }}
              </parkour-tag>
            }
          </div>

          <div class="justify-self-end mt-auto mb-auto">
            <parkour-icon name="chevron-right"></parkour-icon>
          </div>
        </article>
      </li>
    }
  }
</ul>
