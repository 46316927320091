import { Route, Routes } from '@angular/router';
import { actiefWithProfielMatcher } from '../core/matchers';
import { ProfielHomePage } from './page/profiel-home-page/profiel-home.page';
import { ProbleemAddPage } from './page/probleem-add-page/probleem-add.page';
import { dirtyFormGuard } from '../shared/components/dirty-form-guard';
import { MeldingenPage } from './page/meldingen-page/meldingen.page';
import { KalenderPage } from './page/kalender-page/kalender.page';
import { KalenderAfspraakDetailPage } from './page/kalender-afspraak-detail-page/kalender-afspraak-detail.page';
import { KalenderAfpraakAddPage } from './page/kalender-afpraak-add-page/kalender-afpraak-add.page';
import {
  artikelOverviewRoutes,
  hulplijnOverviewRoutes,
  specificArtikelRoute,
  specificHulplijnRoute,
} from '../watwat/routes';
import { BewaardeItemsPage } from './page/bewaarde-items-page/bewaarde-items.page';
import { NotitieEditPage } from './page/notitie-edit-page/notitie-edit.page';
import { NotitieAddPage } from './page/notitie-add-page/notitie-add.page';
import { MijnProfielPage } from './page/mijn-profiel-page/mijn-profiel.page';
import { MijnProfielEditPage } from './page/mijn-profiel-edit/mijn-profiel-edit.page';
import { ProfielVerwijderAanvraagAanmakenPage } from './page/profiel-verwijder-aanvraag-aanmaken/profiel-verwijder-aanvraag-aanmaken.page';
import { aboutParkourRoutes } from '../about/about.routes';
import { WisselTeamPage } from './page/wissel-team-page/wissel-team.page';
import { ProfielEmptyStateNoUserPage } from './page/profiel-empty-state-no-user/profiel-empty-state-no-user.page';
import { KalenderAfpraakEditPage } from './page/kalender-afpraak-edit-page/kalender-afpraak-edit-page.component';
import { InstellingenPage } from './page/instellingen-page/instellingen.page';

const kalenderRoute: Route = {
  path: 'kalender',
  title: 'profiel.kalender-page.title',
  children: [
    {
      path: '',
      component: KalenderPage,
      pathMatch: 'full',
    },
    {
      path: 'afspraak',
      children: [
        {
          path: ':afspraakId/detail',
          component: KalenderAfspraakDetailPage,
        },
        {
          path: ':afspraakId/wijzig',
          title: 'kalender.kalender-afspraak-edit-page.title',
          component: KalenderAfpraakEditPage,
          canDeactivate: [dirtyFormGuard],
        },
        {
          path: 'nieuw',
          title: 'kalender.kalender-afspraak-add-page.title-basic',
          component: KalenderAfpraakAddPage,
          canDeactivate: [dirtyFormGuard],
        },
      ],
    },
  ],
};

const bewaardeItemsRoute: Route = {
  path: 'bewaard',
  title: 'profiel.bewaarde-items-page.title',
  children: [
    specificArtikelRoute,
    specificHulplijnRoute,
    artikelOverviewRoutes,
    hulplijnOverviewRoutes,
    {
      path: '',
      component: BewaardeItemsPage,
      pathMatch: 'full',
    },
    {
      path: 'notitie',
      children: [
        {
          path: ':notitieId/wijzig',
          title: 'profiel.notitie-edit-page.title',
          component: NotitieEditPage,
          canDeactivate: [dirtyFormGuard],
        },
        {
          path: 'add',
          title: 'profiel.notitie-add-page.title',
          component: NotitieAddPage,
          canDeactivate: [dirtyFormGuard],
        },
      ],
    },
  ],
};

const mijnProfielRoute: Route = {
  path: 'mijn',
  children: [
    {
      path: '',
      title: 'profiel.mijn-profiel-page.title',
      component: MijnProfielPage,
      pathMatch: 'full',
    },
    {
      path: 'wijzig',
      children: [
        {
          path: '',
          title: 'profiel.mijn-profiel-edit-page.title',
          component: MijnProfielEditPage,
          pathMatch: 'full',
        },
        {
          path: 'verwijderen',
          title: 'profiel.verwijderen-pagina.titel',
          component: ProfielVerwijderAanvraagAanmakenPage,
        },
      ],
    },
  ],
};

export const profielRoutes: Routes = [
  {
    path: '',
    canMatch: [actiefWithProfielMatcher],
    component: ProfielHomePage,
    pathMatch: 'full',
  },
  {
    path: 'probleem',
    title: 'profiel.probleem-page.title',
    pathMatch: 'full',
    component: ProbleemAddPage,
    canDeactivate: [dirtyFormGuard],
  },
  {
    path: 'instellingen',
    title: 'profiel.instellingen.title',
    pathMatch: 'full',
    component: InstellingenPage,
  },
  {
    path: 'meldingen',
    title: 'profiel.meldingen-page.title',
    children: [
      {
        path: '',
        component: MeldingenPage,
        pathMatch: 'full',
      },
    ],
  },
  kalenderRoute,
  bewaardeItemsRoute,
  mijnProfielRoute,
  {
    path: 'team',
    loadChildren: () =>
      import('../team/team.module').then((m) => {
        return m.TeamModule;
      }),
  },
  {
    path: 'over',
    children: aboutParkourRoutes,
  },
  {
    path: 'wissel-team',
    children: [
      {
        path: '',
        title: 'profiel.wissel-team-page.title',
        component: WisselTeamPage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    component: ProfielEmptyStateNoUserPage,
  },
];
