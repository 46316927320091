@if (active) {
  <div class="flex flex-col gap-y-8" role="tabpanel" tabindex="0">
    @if (notities$ | async; as notities) {
      @if (notities.length > 0) {
        <parkour-button
          [link]="['notitie', 'add']"
          class="w-full"
          testId="notitie-add"
          iconAfter="plus-circle"
        >
          {{ 'bewaard.notitie-add-button' | translate }}
        </parkour-button>

        <ul class="space-y-4">
          @for (notitie of notities; track notitie) {
            <li>
              <parkour-notitie [notitie]="notitie" />
            </li>
          }
        </ul>
      } @else {
        <p class="text-center">{{ 'bewaard.no-notes' | translate }}</p>

        <parkour-button [link]="['notitie', 'add']" class="w-full" iconAfter="plus-circle">
          {{ 'bewaard.notitie-add-button' | translate }}
        </parkour-button>

        <parkour-block-placeholder
          [sources]="{
            default: {
              src: '/assets/bewaard/placeholder/placeholder_bewaarde_links_default.svg',
            },
            md: {
              src: '/assets/bewaard/placeholder/placeholder_bewaarde_links_md.svg',
            },
          }"
          alt=""
          class="block"
        />
      }
    } @else {
      <parkour-loading-spinner />
    }
  </div>
}
