import { SucceservaringTypeDto } from 'parkour-web-app-dto';

export type SucceservaringType = SucceservaringTypeDto | string;
const supportedSucceservaringTypes: {
  [key in SucceservaringTypeDto]: boolean;
} = {
  TEAMLID_GEACTIVEERD: true,
  UITNODIGING_AANVAARD: true,
  ARTIKEL_BEWAARD: true,
  HULPLIJN_BEWAARD: true,
  DOEL_VOORGESTELD: true,
  DOEL_TOEGEVOEGD: true,
  GEBEURTENIS_VOORGESTELD: true,
  GEBEURTENIS_TOEGEVOEGD: true,
  EMOJI_REACTIE_DOEL_TOEGEVOEGD: true,
  AFSPRAAK_TOEGEVOEGD: true,
  BEELDBELAFSPRAAK_GEPLAND: true,
  DOEL_STAP_AFGEVINKT: true,
  NOTITIE_BEWAARD: true,
  PROFIEL_OUDER_DAN_3_DAGEN: true,
};

export type SupportedSucceservaringType = keyof typeof supportedSucceservaringTypes;
export function isSupportedSucceservaringType(type: string): type is SucceservaringType {
  return Object.keys(supportedSucceservaringTypes).includes(type);
}
