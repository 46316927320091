import { Component } from '@angular/core';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ParkourBlockPlaceholderComponent, ParkourButtonComponent } from '@parkour/ui';
import { GebeurtenisErrorCardComponent } from '../../verhaal-shared/component/gebeurtenis-error-card/gebeurtenis-error-card.component';
import { GebeurtenisListItemComponent } from '../../verhaal-shared/component/gebeurtenis-list-item/gebeurtenis-list-item.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { GebeurtenisVoorstelComponent } from '../../verhaal-shared/component/gebeurtenis-voorstel/gebeurtenis-voorstel.component';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { VerhaalDto } from 'parkour-web-app-dto';
import { meldingenForPage } from '../../../meldingen/config';
import { combineLatest, map, Observable, of, repeat, Subject } from 'rxjs';
import { VerhalenService } from '../../verhaal-shared/service/verhalen.service';
import { SuggestieService } from '../../../suggestie/service/suggestie.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { verhaalHeroSources } from '../../constants';
import { GebeurtenisSuggestie } from '../../../suggestie/model/suggestie';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { GebeurtenisListComponent } from '../../verhaal-shared/component/gebeurtenis-list/gebeurtenis-list.component';

@Component({
  imports: [
    PageWithHeroComponent,
    TranslateModule,
    AsyncPipe,
    ParkourButtonComponent,
    GebeurtenisErrorCardComponent,
    GebeurtenisListItemComponent,
    ParkourBlockPlaceholderComponent,
    PageLayoutComponent,
    PageSectionDirective,
    IonContent,
    IonHeader,
    ParkourRefreshDirective,
    IonRefresher,
    IonRefresherContent,
    GebeurtenisVoorstelComponent,
    ShowIfSuccesfulDirective,
    GebeurtenisListComponent,
  ],
  standalone: true,
  templateUrl: './verhaal-jongere-page.component.html',
})
export class VerhaalForJongerePage
  extends ParkourDataPage<VerhaalDto>
  implements MarksMeldingenAsReadOnView
{
  ongelezenGebeurtenisMeldingen$ = this.meldingenService.getMeldingenInCurrentContextByType({
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.DRIE_KOLOMMEN_DOCUMENT_PAGE.allMeldingen,
  });

  refreshSuggesties$ = new Subject<void>();
  gebeurtenisSuggesties$ = this.extraPageData(
    combineLatest([
      this.suggestieService
        .getGebeurtenisSuggesties()
        .pipe(repeat({ delay: () => this.refreshSuggesties$ })),
      this.contextService.context$,
    ]).pipe(
      map(([suggesties, context]) =>
        suggesties.filter((suggestie) => suggestie.suggestedById !== context.contextId),
      ),
    ),
  );

  protected readonly verhaalHeroSources = verhaalHeroSources;

  constructor(
    private readonly verhalenService: VerhalenService,
    private readonly suggestieService: SuggestieService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.specificMeldingen),
    );
  }

  override retrieveMainData(): Observable<VerhaalDto> {
    return this.verhalenService.getVerhaalTijdlijn();
  }

  onSuggestieConfirm(suggestie: GebeurtenisSuggestie) {
    this.router.navigate(['nieuw'], {
      queryParams: { suggestieId: suggestie.id },
      relativeTo: this.route,
    });
  }

  onSuggestieReject(suggestie: GebeurtenisSuggestie) {
    this.suggestieService.rejectSuggestie(suggestie.id).subscribe(() => {
      this.refreshSuggesties$.next();
    });
  }
}
