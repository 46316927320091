import { tap } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { verhaalEvents } from '../verhaal/verhaal.analytics-events';
import { doelenEvents } from '../doel/doel.analytics-event';
import { teamAnalyticsEvents } from '../team/team.analytics-events';
import { berichtenAnalyticsEvents } from '../bericht/berichten.analytics-events';
import { beeldbellenAnalyticsEvents } from '../beeldbellen/beeldbellen.analytics-events';
import { authAnalyticsEvents } from '../authentication/auth.analytics-events';
import { meldingenAnalyticsEvents } from '../profiel/meldingen.analytics-events';
import { afsprakenAnalyticsEvents } from '../profiel/afspraken-analytics.events';
import { bewaardeItemsAnalyticsEvents } from '../profiel/bewaarde-items.analytics-events';
import { profielAnalyticsEvents } from '../profiel/profiel.analytics-events';
import { probleemAnalyticsEvents } from '../profiel/probleem.analytics-events';
import { cookiesAnalyticsEvents } from '../profiel/cookies.analytics-events';
import { homeAnalyticsEvents } from '../home/home.analytics-events';
import { succeservaringAnalyticsEvents } from '../succeservaring/succeservaringen.analytics-events';

const allEvents = {
  verhaal: verhaalEvents,
  doelen: doelenEvents,
  team: teamAnalyticsEvents,
  berichten: berichtenAnalyticsEvents,
  beeldbellen: beeldbellenAnalyticsEvents,
  auth: authAnalyticsEvents,
  meldingen: meldingenAnalyticsEvents,
  afspraken: afsprakenAnalyticsEvents,
  bewaardeItems: bewaardeItemsAnalyticsEvents,
  probleem: probleemAnalyticsEvents,
  profiel: profielAnalyticsEvents,
  cookies: cookiesAnalyticsEvents,
  home: homeAnalyticsEvents,
  succeservaringen: succeservaringAnalyticsEvents,
} as const;

type AllEventsType = typeof allEvents;
export type EventCategory = keyof AllEventsType;
export type EventCategoryMappings = { [K in keyof AllEventsType]: AllEventsType[K][number] };

export class AnalyticsEvent<CategoryType extends EventCategory> {
  constructor(
    public readonly category: CategoryType,
    public readonly event: EventCategoryMappings[CategoryType],
    public readonly name?: string,
  ) {}
}

export const trackAnalyticsEvent = <T, C extends EventCategory>(
  analyticsService: AnalyticsService,
  eventOrFactory: AnalyticsEvent<C> | (() => AnalyticsEvent<C>),
) =>
  tap<T>(() => {
    const event = typeof eventOrFactory === 'function' ? eventOrFactory() : eventOrFactory;
    analyticsService.trackEvent(event);
  });

type CategoriesWithEvent<EventName extends string> = {
  [K in keyof AllEventsType]: AllEventsType[K] extends readonly (infer U)[]
    ? U extends EventName
      ? K
      : never
    : never;
}[keyof AllEventsType];

export type CategoriesContainingBijlageToegevoegd = CategoriesWithEvent<'bijlageToegevoegd'>;
export type CategoriesContainingAfbeeldingToegevoegd = CategoriesWithEvent<'afbeeldingToegevoegd'>;
