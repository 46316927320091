import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ContextService } from '../../services/context.service';
import { ParkourCookiesService } from '../../services/parkour-cookies.service';
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourModalComponent,
  ParkourToggleComponent,
} from '@parkour/ui';
import { MatomoTracker } from 'ngx-matomo-client';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { IonAccordion, IonAccordionGroup } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.css'],
  imports: [
    ParkourModalComponent,
    ParkourIconComponent,
    RouterLink,
    ParkourToggleComponent,
    FormsModule,
    ParkourButtonComponent,
    AsyncPipe,
    IonAccordion,
    IonAccordionGroup,
  ],
})
export class CookiesModalComponent implements AfterViewInit, OnInit {
  @ViewChild(ParkourModalComponent) parkourModal!: ParkourModalComponent;

  marketingCookiesEnabled = false;
  analyticsCookiesEnabled = true;

  private _confirmCallback?: () => void;

  cookiesUrl$ = this.contextService.getAbsoluteUrl(['home', 'over', 'cookiebeleid']);

  constructor(
    private readonly contextService: ContextService,
    private readonly parkourCookiesService: ParkourCookiesService,
    private readonly tracker: MatomoTracker,
  ) {}

  ngOnInit() {
    this.marketingCookiesEnabled = this.parkourCookiesService.isMarketingCookiesEnabled();
    this.tracker.isUserOptedOut().then((isOptedOut) => {
      this.analyticsCookiesEnabled = !isOptedOut;
    });
  }

  ngAfterViewInit(): void {
    this.parkourCookiesService.registerCookiePopup(this);
  }

  onBevestigenClick() {
    this.parkourCookiesService.setMarketingCookiesEnabled(this.marketingCookiesEnabled);
    this.parkourCookiesService.setAnalyticsCookiesEnabled(this.analyticsCookiesEnabled);
    this.parkourModal.close().then(() => this._confirmCallback && this._confirmCallback());
  }

  public set confirmCallback(value: () => void) {
    this._confirmCallback = value;
  }

  public open() {
    this.parkourModal.open();
  }
}
