import { Component, computed, Input, Signal } from '@angular/core';
import { CmsFaqContent, CmsFaqValue } from 'parkour-web-app-dto';
import { TAccordionItems } from 'src/app/shared/components/parkour-accordion/parkour-accordion.types';
import { ParkourAccordionComponent } from '../../shared/components/parkour-accordion/parkour-accordion.component';
import { VideoContentType } from '../../shared/components/parkour-video-content/parkour-video-content.component';

@Component({
  selector: 'parkour-faq-content',
  standalone: true,
  imports: [ParkourAccordionComponent],
  templateUrl: './faq-content.component.html',
})
export class FaqContentComponent {
  @Input({ required: true }) content!: CmsFaqContent;
  accordionItems: Signal<TAccordionItems> = computed(() =>
    this.mapFaqToAccordionItems(this.content.faqValues),
  );

  mapFaqToAccordionItems(values: CmsFaqValue[]): TAccordionItems {
    return values.map((value) => ({
      header: value.question || '',
      content: value.answer || '',
      imageUrl: value.image?.fullUrl,
      imageAlt: value.image?.alt,
      ...this.mapVideo(value),
    }));
  }

  mapVideo(faqValue: CmsFaqValue): {
    videoTitle?: string;
    videoType?: VideoContentType;
    videoUrl?: string;
  } {
    const videoType = faqValue.video?.type?.toLowerCase();
    if (
      faqValue.video &&
      videoType !== undefined &&
      (videoType === 'vimeo' || videoType === 'youtube')
    ) {
      return {
        videoTitle: faqValue.video.title,
        videoType: videoType as VideoContentType,
        videoUrl: faqValue.video.url,
      };
    } else {
      return {};
    }
  }
}
