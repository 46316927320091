import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ImagesService } from '../../../shared/services/images.service';

import { FotoId, GebeurtenisImageVariant, SuggestieId } from 'parkour-web-app-dto';
import { SuggestieService } from '../../../suggestie/service/suggestie.service';

type SuggestieWithImage = {
  id: SuggestieId;
  imageId: FotoId;
};

@Pipe({
  name: 'suggestieImage',
  standalone: true,
})
export class SuggestieImagePipe implements PipeTransform {
  constructor(
    private readonly suggestieService: SuggestieService,
    private readonly imageService: ImagesService,
  ) {}

  transform(
    suggestieWithImage: SuggestieWithImage,
    gebeurtenisImageVariant: GebeurtenisImageVariant = 'ORIGINAL',
  ): Observable<string> {
    return this.imageService.getImage(
      this.suggestieService.getImageUrl(
        suggestieWithImage.id,
        suggestieWithImage.imageId,
        gebeurtenisImageVariant,
      ),
    );
  }
}
