<parkour-page-image [src]="background" classes="absolute top-0 left-0 z-0" />

<div class="relative z-10 pt-4 pb-8 container container-space flex flex-col gap-y-8">
  <div>
    <ng-content select="[info-card]"></ng-content>

    <parkour-hero [image]="heroProps">
      <div class="flex flex-col items-center gap-y-4 text-center container-1-col">
        <div class="flex flex-col items-center gap-y-1">
          <ng-content select="[hero-above-title]"></ng-content>

          @if (showTitle) {
            <h1>{{ title }}</h1>
          }

          <ng-content select="[hero-content]"></ng-content>
        </div>

        @if (showTitle) {
          <parkour-divider />
        }
      </div>
    </parkour-hero>
  </div>

  <ng-content></ng-content>
</div>
