export class SysteemConfigHistoriekItemDto {
    readonly actie!: 'SYSTEEM_BOODSCHAP_INGESCHAKELD' | 'SYSTEEM_BOODSCHAP_UITGESCHAKELD';
    readonly aangemaaktOpTijdstip!: string;
    readonly auteur!: string;
}

export class SysteemConfigDto {
    readonly titel!: string;
    readonly beschrijving!: string;
    readonly zichtbaar!: boolean;
    readonly historiekItems!: SysteemConfigHistoriekItemDto[]
}