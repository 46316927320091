<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'over-parkour.veelgestelde-vragen-page.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'over-parkour.veelgestelde-vragen-page.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        <section class="mt-8">
          @if (faqPagina | async; as faqPagina) {
            @for (content of faqPagina.content; track content) {
              @switch (content.type) {
                @case ('faq') {
                  <parkour-faq-content [content]="content" />
                }
              }
            }
          }
        </section>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
