import { Injectable } from '@angular/core';
import { DoelDto, DoelId, DoelUpsertDto } from 'parkour-web-app-dto';
import { map, mergeMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Doel } from '../model/doel';
import { DoelUpsert } from '../model/doel-upsert';
import { asType, stripNullProperties } from '../../../utils';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../../shared/services/context.service';

@Injectable({
  providedIn: 'root',
})
export class DoelenService {
  constructor(
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
  ) {}

  doelFromDto(dto: DoelDto): Doel {
    return new Doel(
      dto.id,
      dto.naam,
      dto.omschrijving,
      dto.thema,
      dto.aanmaakDatum,
      dto.eigenaarId,
      dto.streefDatum ? new Date(dto.streefDatum) : undefined,
      dto.eindDatum ? new Date(dto.eindDatum) : undefined,
      dto.acties,
      dto.gedeeldMet,
      dto.deelMode,
      dto.activeGebeurtenisSuggestie,
    );
  }

  doelUpsertToDto(doel: DoelUpsert): DoelUpsertDto {
    return {
      ...doel,
      streefDatum: doel.streefDatum ? doel.streefDatum.toISOString() : undefined,
    };
  }

  getBehaaldeDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/behaald`,
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getGedeeldeBehaaldeDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/behaald`,
          {
            params: { gedeeldMet: contextId },
          },
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getActieveDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/actief`,
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  getGedeeldeActieveDoelen(): Observable<Doel[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelDto>>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/actief`,
          {
            params: { gedeeldMet: contextId },
          },
        ),
      ),
      map((dtos) => dtos.map((dto) => this.doelFromDto(dto))),
    );
  }

  addDoel(newDoel: DoelUpsert): Observable<void> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) => {
        return this.http.post<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen`,
          asType<DoelUpsertDto>(stripNullProperties(this.doelUpsertToDto(newDoel))),
        );
      }),
    );
  }

  getDoel(id: DoelId): Observable<Doel> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<DoelDto>(`${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${id}`),
      ),
      map((dto) => this.doelFromDto(dto)),
    );
  }

  updateDoel(doelId: DoelId, doel: DoelUpsert): Observable<void> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.put<void>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}`,
            asType<DoelUpsertDto>(stripNullProperties(this.doelUpsertToDto(doel))),
          ),
        ),
      );
  }

  deleteDoel(doelId: DoelId): Observable<void> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.delete<void>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}`,
          ),
        ),
      );
  }

  completeDoel(doelId: DoelId) {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.put<DoelDto>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}/behaal`,
          {},
          {},
        ),
      ),
      map((dto) => this.doelFromDto(dto)),
    );
  }
}
