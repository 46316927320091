<div
  class="flex items-center justify-center rounded aspect-square bg-n-400 {{
    width
  }}"
  [ngClass]="{
    'bg-n-100': theme === 'n-100',
    'bg-n-400': theme === 'n-400',
    'bg-n-300': theme === 'n-300',
    'bg-negative': theme === 'negative',
    'bg-s-100': theme === 's-100',
  }"
>
  <parkour-icon
          class="flex" [name]="icon" [size]="iconSize"
          [ngClass]="{
            'text-n-1000': theme !== 's-100',
            'text-n-200': theme === 's-100',
          }"
  />
</div>
