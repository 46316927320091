import { RefresherCustomEvent } from '@ionic/angular/standalone';
import {
  combineLatest,
  filter,
  map,
  merge,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { asResult, Result } from '../utils';
import { PageTitle } from './custom-page-title';

import { ParkourPage } from './parkour-page';
import { HumanReadableError } from '../core/human-readable-error';

export abstract class ParkourDataPage<PageDataType> extends ParkourPage {
  private readonly manualRefresh$ = new Subject<RefresherCustomEvent>();
  protected readonly dataRefresh$ = merge(this.manualRefresh$, this.pageWillEnter$).pipe(
    filter(() => this.pageActive),
  );
  private readonly mainDataRefresh$ = new Subject<void>();

  public readonly mainPageData$: Observable<Result<PageDataType>> = merge(
    this.dataRefresh$,
    this.mainDataRefresh$,
  ).pipe(
    switchMap((event) =>
      asResult(
        this.retrieveMainData().pipe(
          tap({
            next: () => event && event.detail.complete(),
            error: () => event && event.detail.complete(),
          }),
        ),
      ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  override refreshPageDataOnly = (event: RefresherCustomEvent) => {
    this.manualRefresh$.next(event);
  };

  protected refreshMainData() {
    this.mainDataRefresh$.next();
  }

  protected getCurrentMainDataOrThrow(): Observable<PageDataType> {
    return this.mainPageData$.pipe(
      take(1),
      map((result) =>
        result.unwrapOrThrow(new HumanReadableError('De data is niet correct ingeladen')),
      ),
    );
  }

  // Helper function to generate a title from the main data in the form "mainData.titel (extra)"
  protected generateDefaultTitle(
    extractTitle: (data: PageDataType) => string,
    detailTranslationKey: string,
    anonymousTracking?: boolean,
  ): Observable<PageTitle> {
    return combineLatest([
      this.mainPageData$,
      this.translateService.get(detailTranslationKey),
    ]).pipe(
      map(([data, detail]) => {
        if (data.success) {
          return {
            category: detail,
            title: extractTitle(data.value),
            anonymousTracking,
          };
        } else {
          return detail;
        }
      }),
    );
  }

  // Helper function to get additional data to use in the page which does not need to be included in the main data
  protected extraPageData<Type>(fetchData: Observable<Type>): Observable<Result<Type>> {
    return this.dataRefresh$.pipe(
      switchMap(() => asResult(fetchData)),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  // Implement this method to retrieve the main data of the page
  abstract retrieveMainData(): Observable<PageDataType>;
}
