<div class="mt-4 space-y-4">
  <parkour-form-field
    [inputFormControl]="overJezelfFormGroup.controls.bio"
    [label]="'profiel.bio' | translate"
    class="block"
    inputId="bio"
  >
    <parkour-textarea
      [formControl]="overJezelfFormGroup.controls.bio"
      [inputPlaceholder]="'profiel.bio-placeholder' | translate"
      class="block"
      inputId="bio"
    />
  </parkour-form-field>

  <div>
    <div class="typo-body variant-body-fat flex items-center gap-x-1">
      {{ 'profiel.links' | translate }}

      <span class="typo-body variant-body-small text-n-1000-rgb-7">
        {{ 'forms.optional' | translate }}
      </span>
    </div>

    <ul class="space-y-2 mt-1">
      <li
        *ngFor="let linkControl of overJezelfFormGroup.controls.links.controls; let i = index"
        class="flex"
      >
        <parkour-form-field-without-label
          [additionalErrors]="linkControl.errors"
          [errorMessages]="{ required: 'Vul een link in.', maxlength: 'Deze link is te lang.' }"
          [inputFormControl]="linkControl"
          [accessibleLabel]="'profiel.link-accessible-label' | translate"
          class="flex w-full"
          classes="flex flex-col flex-1"
          [inputId]="'link-' + i"
        >
          <div class="flex flex-row flex-1 gap-x-2 items-center">
            <parkour-input
              [formControl]="linkControl"
              [inputPlaceholder]="'profiel.link-placeholder' | translate"
              class="block flex-1"
              autocomplete="url"
              [inputId]="'link-' + i"
              slotPlacement="left"
            >
              <parkour-icon class="flex p-2" name="link" />
            </parkour-input>

            <parkour-button
              [ariaLabel]="'buttons.delete-link' | translate"
              (clicked)="onDeleteLink(i)"
              format="iconButton"
              icon="trash"
              size="default"
            />
          </div>
        </parkour-form-field-without-label>
      </li>
    </ul>

    <parkour-button
      (clicked)="onLinkAdd()"
      class="mt-2 block"
      iconBefore="plus-circle"
      size="none"
      variant="ghost"
    >
      {{ 'profiel.link-toevoegen' | translate }}
    </parkour-button>
  </div>

  <parkour-form-field
    [inputFormControl]="overJezelfFormGroup.controls.adres"
    [label]="'profiel.contactadres' | translate"
    class="block"
    inputId="adres"
  >
    <parkour-input
      autocomplete="street-address"
      [formControl]="overJezelfFormGroup.controls.adres"
      class="block"
      inputId="adres"
      inputPlaceholder="Contactadres"
    />
  </parkour-form-field>
</div>
