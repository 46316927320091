import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  DoelSuggestieDto,
  DoelSuggestieUpsertDto,
  FileData,
  FotoId,
  GebeurtenisImageVariant,
  GebeurtenisSuggestieDto,
  GebeurtenisSuggestieUpsertDto,
  ProfielId,
  SuggestieId,
  SuggestieStatus,
} from 'parkour-web-app-dto';
import { map, mergeMap, Observable } from 'rxjs';
import { GebeurtenisSuggestie } from '../../verhaal/verhaal-shared/model/gebeurtenis';
import { asType } from '../../utils';
import {
  DoelSuggestie,
  doelSuggestieFromDto,
  DoelSuggestieUpsert,
  GebeurtenisSuggestieUpsert,
} from '../model/suggestie';
import { environment } from 'src/environments/environment';
import { ContextService } from '../../shared/services/context.service';
import { FileService } from '../../shared/services/file.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class SuggestieService {
  constructor(
    private http: HttpClient,
    private readonly contextService: ContextService,
    private readonly fileService: FileService,
  ) {}

  getGebeurtenisSuggestie(suggestieId: SuggestieId): Observable<GebeurtenisSuggestie> {
    return this.http.get<GebeurtenisSuggestieDto>(
      `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${suggestieId}`,
    );
  }

  getGebeurtenisSuggesties(): Observable<GebeurtenisSuggestie[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<GebeurtenisSuggestieDto>>(
          `${environment.API_BASE_URL}/api/suggesties/gebeurtenis`,
          {
            params: {
              to: contextId,
            },
          },
        ),
      ),
    );
  }

  getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<DoelSuggestieDto>>(`${environment.API_BASE_URL}/api/suggesties/doel`, {
          params: {
            to: contextId,
          },
        }),
      ),
      map((dto) => dto.map((d) => doelSuggestieFromDto(d))),
    );
  }

  getDoelSuggestiesBy(by: ProfielId, to: ProfielId): Observable<DoelSuggestie[]> {
    return this.http
      .get<Array<DoelSuggestieDto>>(`${environment.API_BASE_URL}/api/suggesties/doel`, {
        params: {
          by,
          to,
        },
      })
      .pipe(map((dto) => dto.map((d) => doelSuggestieFromDto(d))));
  }

  updateGebeurtenisSuggestieStatus(
    suggestieId: SuggestieId,
    status: SuggestieStatus,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${suggestieId}/status`,
      { status },
    );
  }

  rejectSuggestie(suggestieId: SuggestieId): Observable<void> {
    return this.http.put<void>(
      `${environment.API_BASE_URL}/api/suggesties/${suggestieId}/reject`,
      {},
    );
  }

  addDoelSuggestie(doelSuggestieUpsert: DoelSuggestieUpsert): Observable<void> {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/suggesties/doel`,
      asType<DoelSuggestieUpsertDto>(doelSuggestieUpsert),
    );
  }

  getDoelSuggestie(suggestieId: SuggestieId): Observable<DoelSuggestie> {
    return this.http
      .get<DoelSuggestieDto>(`${environment.API_BASE_URL}/api/suggesties/doel/${suggestieId}`)
      .pipe(map((dto) => doelSuggestieFromDto(dto)));
  }

  addGebeurtenisSuggestie(gebeurtenisSuggestieUpsert: GebeurtenisSuggestieUpsert) {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/suggesties/gebeurtenis`,
      asType<GebeurtenisSuggestieUpsertDto>(gebeurtenisSuggestieUpsert),
    );
  }

  getImageUrl(id: SuggestieId, imageId: FotoId, variant: GebeurtenisImageVariant) {
    return `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${id}/image/${imageId}?variant=${variant}`;
  }

  downloadBijlage(id: SuggestieId, fileData: FileData) {
    return fromPromise(
      this.fileService.downloadFileFromUrl(
        `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${id}/bijlage/${fileData.id}`,
        fileData.naam,
      ),
    );
  }
}
