import { Routes } from '@angular/router';
import { specificHulplijnRoute } from '../../watwat/routes';
import { VerhaalExJongerePage } from './verhaal-ex-jongere-page/verhaal-ex-jongere.page';
import { DrieKolommenGebeurtenisDetailsPage } from '../verhaal-shared/page/drie-kolommen-gebeurtenis-details-page/drie-kolommen-gebeurtenis-details.page';
import { GebeurtenisDetailsExJongerePage } from './gebeurtenis-details-ex-jongere-page/gebeurtenis-details-ex-jongere.page';

export const verhaalExJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VerhaalExJongerePage,
  },
  {
    path: 'gebeurtenis/:gebeurtenis-id',
    children: [
      {
        path: '',
        component: GebeurtenisDetailsExJongerePage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'eyouth/:gebeurtenis-id',
    children: [
      {
        path: '',
        component: GebeurtenisDetailsExJongerePage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'document/:gebeurtenis-id',
    children: [
      {
        path: '',
        component: DrieKolommenGebeurtenisDetailsPage,
        pathMatch: 'full',
      },
      specificHulplijnRoute,
    ],
  },
];
