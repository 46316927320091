import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourButtonComponent,
  ParkourCardComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielService } from '../../service/profiel.service';
import { finalize, map, Subscription, switchMap, tap, timer } from 'rxjs';
import {
  ContactMethodeType,
  getContactEmailOrTelefoonnummer,
  getContactMethodeType,
} from '../../model/profiel';
import { AsyncPipe, Location } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnimationService } from '../../../shared/services/animation.service';

@Component({
  selector: 'parkour-contact-methode-bevestigen-page',
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    PageLayoutComponent,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    TranslateModule,
    ParkourCardComponent,
    ParkourInputComponent,
    ParkourFormFieldComponent,
    ParkourButtonComponent,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './contact-methode-bevestigen-page.component.html',
})
export class ContactMethodeBevestigenPageComponent implements OnInit, OnDestroy {
  private cooldown = false;
  private subscription?: Subscription;

  constructor(
    private readonly profielService: ProfielService,
    private readonly toastService: ParkourToastService,
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly animationService: AnimationService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.formControl.valueChanges.subscribe((value) => {
      const onlyDigits = value.replace(/\D/g, '');
      if (onlyDigits !== value) {
        this.formControl.setValue(onlyDigits);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected readonly formControl = this.fb.nonNullable.control('', Validators.required);

  protected readonly titel$ = this.profielService.userProfielOrUndefined$.pipe(
    map((profiel) => {
      if (!profiel) {
        return '';
      }

      switch (getContactMethodeType(profiel)) {
        case 'email':
          return 'E-mailadres bevestigen';
        case 'gsm-nummer':
          return 'Gsm-nummer bevestigen';
      }
    }),
  );

  protected readonly contactEmailOrTelefoonnummer$ =
    this.profielService.userProfielOrUndefined$.pipe(
      map((profiel) => (profiel ? getContactEmailOrTelefoonnummer(profiel) : undefined)),
    );

  protected readonly contactMethodeType$ = this.profielService.userProfielOrUndefined$.pipe(
    map((profiel) => (profiel ? getContactMethodeType(profiel) : undefined)),
  );

  onResendClick(contactEmailOrTelefoonnummer: string) {
    if (this.cooldown) {
      return;
    }

    this.cooldown = true;
    this.profielService
      .stuurVerificatieCode()
      .pipe(
        tap(() =>
          this.toastService.showToast({
            header: 'Code opnieuw verzonden',
            content: `We stuurden een code naar ${contactEmailOrTelefoonnummer}`,
          }),
        ),
        switchMap(() => timer(30000)), // Start the 30-second cooldown timer
        finalize(() => (this.cooldown = false)),
      )
      .subscribe();
  }

  onSubmit(type: ContactMethodeType) {
    this.profielService
      .verifieerContactMethode(this.formControl.value)
      .pipe(
        switchMap(() => this.profielService.getCurrentUserProfiel$()),
        tap((profiel) => {
          if (profiel.contactMethodeGeverifieerd) {
            this.animationService.playSuccessAnimation();
            this.toastService.showToast({
              header: `${type === 'email' ? 'E-mailadres' : 'Gsm-nummer'} bevestigd`,
              content: `Bedankt voor het bevestigen van je ${type === 'email' ? 'e-mailadres' : 'gsm-nummer'}!`,
            });
            this.location.back();
          } else {
            this.toastService.showToast({
              error: true,
              header: 'Fout bij bevestigen',
              content: `De code die je hebt ingevoerd is niet juist. Probeer het opnieuw.`,
            });
          }
        }),
      )
      .subscribe();
  }
}
