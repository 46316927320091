<parkour-form
  [formGroup]="afspraakForm"
  [formId]="formId"
  [submitObservableGenerator]="submitAfspraakForm"
  [errorLabelMapping]="false"
  class="flex flex-col gap-y-8"
>
  <div class="flex flex-col gap-y-8">
    @if (!isJongere) {
      <parkour-form-field
        [inputFormControl]="afspraakForm.controls.jongere"
        [label]="'kalender.kalender-afspraak-add-page.form.persoon' | translate"
        [required]="true"
        class="block"
        inputId="jongereId"
      >
        <parkour-persoon-select
          [required]="true"
          [formControl]="afspraakForm.controls.jongere"
          [options]="mijnTeamlidContexten"
          [placeholder]="'kalender.kalender-afspraak-add-page.form.persoon-placeholder' | translate"
        />
      </parkour-form-field>
    } @else {
      <parkour-form-field
        [inputFormControl]="afspraakForm.controls.titel"
        [label]="'kalender.kalender-afspraak-add-page.form.titel' | translate"
        [required]="true"
        class="block"
        inputId="titel"
      >
        <parkour-input
          [autocomplete]="null"
          [formControl]="afspraakForm.controls.titel"
          class="block"
          inputId="titel"
          inputPlaceholder="Titel"
          type="text"
          [required]="true"
        />
      </parkour-form-field>
    }

    <parkour-form-field
      [inputFormControl]="afspraakForm.controls.datum"
      [label]="'kalender.kalender-afspraak-add-page.form.datum' | translate"
      [required]="true"
      class="block"
      inputId="datum"
    >
      <parkour-input
        [autocomplete]="null"
        [formControl]="afspraakForm.controls.datum"
        class="block"
        inputId="datum"
        inputPlaceholder="datum"
        type="date"
      >
      </parkour-input>
    </parkour-form-field>

    <div class="flex gap-x-3">
      <parkour-form-field
        [inputFormControl]="afspraakForm.controls.van"
        [label]="'kalender.kalender-afspraak-add-page.form.van' | translate"
        [required]="true"
        class="flex-1"
        inputId="vanUur"
      >
        <parkour-input
          (change)="updateTimes()"
          [autocomplete]="null"
          [formControl]="afspraakForm.controls.van"
          class="block"
          inputId="vanUur"
          inputPlaceholder="van"
          type="time"
        >
          <parkour-icon
            class="absolute top-1/2 -translate-y-2/4 right-[0.7rem] pointer-events-none"
            name="clock"
            size="3"
          />
        </parkour-input>
      </parkour-form-field>

      <span class="text-n-600 pt-5 mt-3">-</span>
      <!-- TODO: Add error messages to JSON -->
      <parkour-form-field
        [additionalErrors]="getTotErrors()"
        [errorMessages]="{
          totMustBeGreaterThanVan: 'De eindtijd moet na de starttijd liggen.',
        }"
        [inputFormControl]="afspraakForm.controls.tot"
        [label]="'kalender.kalender-afspraak-add-page.form.tot' | translate"
        [required]="true"
        class="flex-1"
        inputId="totUur"
      >
        <parkour-input
          [autocomplete]="null"
          [formControl]="afspraakForm.controls.tot"
          class="block"
          inputId="totUur"
          inputPlaceholder="tot"
          type="time"
        >
          <parkour-icon
            class="absolute top-1/2 -translate-y-2/4 right-[0.7rem] pointer-events-none"
            name="clock"
            size="3"
          />
        </parkour-input>
      </parkour-form-field>
    </div>

    <parkour-form-field
      [inputFormControl]="afspraakForm.controls.omschrijving"
      [label]="'kalender.kalender-afspraak-add-page.form.beschrijving' | translate"
      class="block"
      inputId="omschrijving"
    >
      <parkour-textarea
        [formControl]="afspraakForm.controls.omschrijving"
        class="block"
        inputId="omschrijving"
      />
    </parkour-form-field>

    @if (mode === 'edit') {
      <parkour-button [disabled]="busy" class="block w-full" iconAfter="disk" type="submit">
        {{ 'kalender.kalender-afspraak-edit-page.form.submit-basic' | translate }}
      </parkour-button>
    } @else if (mode === 'add' && isJongere) {
      <parkour-button [disabled]="busy" class="block w-full" iconAfter="disk" type="submit">
        {{ 'kalender.kalender-afspraak-add-page.form.submit-basic' | translate }}
      </parkour-button>
    } @else if (mode === 'add' && !isJongere) {
      <parkour-button [disabled]="busy" class="block w-full" iconAfter="paper-plane" type="submit">
        {{ 'kalender.kalender-afspraak-add-page.form.submit-videogesprek' | translate }}
      </parkour-button>
    }
  </div>
</parkour-form>
