import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BewaardeItemsService } from '../../service/bewaarde-items.service';
import { NotitieComponent } from '../notitie-card/notitie.component';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { Notitie } from '../../model/notitie';

@Component({
  selector: 'parkour-bewaarde-notities',
  standalone: true,
  imports: [
    AsyncPipe,
    CoreModule,
    NotitieComponent,
    ParkourButtonComponent,
    ParkourBlockPlaceholderComponent,
    ParkourLoadingSpinnerComponent,
  ],
  templateUrl: './bewaarde-notities.component.html',
})
export class BewaardeNotitiesComponent {
  @Input({ required: true }) active!: boolean;
  notities$ = new Observable<Notitie[]>();

  constructor(private readonly bewaardeItemsService: BewaardeItemsService) {}

  initializeData() {
    this.notities$ = this.bewaardeItemsService.getNotities();
  }
}
