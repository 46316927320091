import { Routes } from '@angular/router';
import { HomePage } from './page/home-page/home.page';
import { aboutParkourRoutes } from '../about/about.routes';
import {
  artikelOverviewRoutes,
  hulplijnOverviewRoutes,
  specificArtikelRoute,
  specificHulplijnRoute,
} from '../watwat/routes';

export const homeRoutes: Routes = [
  {
    path: '',
    component: HomePage,
    pathMatch: 'full',
  },
  specificHulplijnRoute, // You can navigate directly to an hulplijn or using the 'Alle hulplijnen' button
  hulplijnOverviewRoutes,
  specificArtikelRoute, // You can navigate directly to an artikel or using the artikel overview page
  artikelOverviewRoutes,
  {
    path: 'over',
    title: 'over-parkour.waarom-parkour-page.title',
    children: aboutParkourRoutes,
  },
];
