import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GebeurtenisUpsert } from '../../model/gebeurtenis';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';
import { Observable } from 'rxjs';
import { ParkourFormComponent } from '../../../../shared/components/parkour-form/parkour-form.component';
import { ParkourButtonComponent } from '@parkour/ui';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-jeugdhulp-historiek-form',
  templateUrl: './jeugdhulp-historiek-form.component.html',
  imports: [ParkourFormComponent, ParkourButtonComponent, DelenSectionComponent, TranslateModule],
})
export class JeugdhulpHistoriekFormComponent implements OnInit {
  @Input({ required: true }) formId!: string;
  @Input() initialState: Partial<GebeurtenisUpsert> = {};
  @Input({ required: true }) teamleden!: TeamlidProfiel[];
  @Input({ required: true }) submitJeugdhulpHistoriekForm!: (
    gebeurtenisUpsert: GebeurtenisUpsert,
  ) => Observable<unknown>;

  eyouthGebeurtenisUpdateForm!: FormGroup<{
    delenSection: DelenSectionGroup;
  }>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit() {
    this.eyouthGebeurtenisUpdateForm = this.formBuilder.nonNullable.group({
      delenSection: this.delenFormService.createDelenFormGroup(this.teamleden, this.initialState),
    });
  }

  submitForm = (formValue: RawValueOfFormGroup<FormGroup<{ delenSection: DelenSectionGroup }>>) => {
    return this.submitJeugdhulpHistoriekForm({
      deelMode: formValue.delenSection.deelMode,
      gedeeldMet: this.teamleden
        .filter((teamLid, index) => formValue.delenSection.gedeeldMet[index].gedeeldMet)
        .map((teamlid) => teamlid.id),
      type: 'EYOUTH',
    });
  };
}
