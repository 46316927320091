<ion-app>
  @if (!isNativeApp()) {
    <a
      [href]="currentUrl + '#main-content-' + currentContextId"
      class="fixed top-[calc(2rem+var(--app-plus-global-header-height))] z-[9999] translate-x-[-110%] focus:translate-x-[5%] outline-[3px] outline-[yellow] outline-dashed bg-n-100 p-4 typo-body variant-body-fat w-fit transition-transform"
    >
      {{ 'skip-to-content' | translate }}
    </a>
  }

  <div>
    @if (showGlobalHeader) {
      @if (detailedUser$ | async; as detailedUser) {
        <parkour-vo-header
          [globalHeaderEmbedScriptSrc]="environment.globalHeaderEmbedCode"
          [userAuthenticated]="detailedUser.type !== 'anoniem'"
          [mock]="environment.mockGlobalHeader"
        />
      }
    }
    <div class="relative">
      <div id="toast-anchor"></div>
    </div>
  </div>

  <ion-router-outlet
    [swipeGesture]="(canGoBack$ | async) ?? true"
    [ngClass]="showGlobalHeader ? 'ion-app-plus-global-header' : ''"
  ></ion-router-outlet>

  <div parkourBeeldbellenAnchor></div>

  <parkour-cookies-modal />

  <parkour-sheet />
</ion-app>
