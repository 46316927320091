import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParkourButtonComponent, ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, NgForOf } from '@angular/common';
import { ProfielFotoBadgeComponent } from '../../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { ProfielnaamPipe } from '../../../../shared/pipes/profielnaam.pipe';
import { GebeurtenisImagePipe } from '../../pipes/gebeurtenis-image.pipe';
import { GebeurtenisSuggestie } from '../../model/gebeurtenis';
import { ProfielPipe } from '../../../../shared/pipes/profiel.pipe';
import { SuggestieImagePipe } from '../../pipes/suggestie-image.pipe';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-voorstel',
  templateUrl: './gebeurtenis-voorstel.component.html',
  imports: [
    ParkourButtonComponent,
    TranslateModule,
    ParkourIconComponent,
    NgForOf,
    ProfielFotoBadgeComponent,
    ProfielnaamPipe,
    AsyncPipe,
    GebeurtenisImagePipe,
    ParkourImageComponent,
    DatePipe,
    ProfielPipe,
    SuggestieImagePipe,
  ],
})
export class GebeurtenisVoorstelComponent {
  @Input({ required: true }) gebeurtenisSuggestie!: GebeurtenisSuggestie;

  @Output() confirm = new EventEmitter<void>();
  @Output() reject = new EventEmitter<void>();

  onYes() {
    this.confirm.emit();
  }

  onNo() {
    this.reject.emit();
  }
}
