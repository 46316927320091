<div
  class="h-[calc(100vh-var(--app-plus-global-header-height)-var(--app-toolbar-height))] container container-space flex items-center justify-center"
>
  <div class="py-8 flex flex-col items-center gap-y-10 w-full container-1-col">
    <parkour-lottie-player
      [loop]="true"
      [src]="environment.API_BASE_URL + '/assets/general/animatie_404.json'"
      class="block relative w-[10rem] h-[10rem] p-8 rounded-[100vmax] bg-n-300 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[100vmax] before:bg-n-300 before:blur-[32px] before:scale-125"
    />

    <div class="w-full">
      <div class="flex flex-col gap-y-3 text-center">
        <h1 class="typo-h2">Oeps!</h1>

        <p>
          Deze pagina lijkt niet te bestaan. <br />
          Zoek je weg terug via de startpagina.
        </p>
      </div>

      <parkour-button (clicked)="onClickNavigateHome()" class="block w-full mt-6">
        @switch (activeTab) {
          @case ('home') {
            Ga naar home
          }
          @case ('profiel') {
            Terug naar profiel
          }
          @case ('doelen') {
            Terug naar doelen
          }
          @case ('verhaal') {
            Terug naar verhaal
          }
          @case ('berichten') {
            Terug naar berichten
          }
          @default {
            Ga naar home
          }
        }
      </parkour-button>
    </div>
  </div>
</div>
