import { Component } from '@angular/core';
import { ParkourButtonComponent, ParkourSegmentComponent } from '@parkour/ui';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { DoelVoorstelComponent } from '../../doel-shared/component/doel-voorstel/doel-voorstel.component';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { DoelListComponent } from '../../doel-shared/component/doel-list/doel-list.component';
import { DoelenPlaceholderComponent } from '../../doel-shared/component/doelen-placeholder/doelen-placeholder.component';
import { DoelOverviewPage } from '../../doel-shared/page/doel-overview/doel-overview-page';
import { MarksMeldingenAsReadOnView } from '../../../shared/marks-meldingen-as-read';
import { ActivatedRoute, Router } from '@angular/router';
import { doelHeroSources } from '../../doel-shared/constants';
import { Observable } from 'rxjs';
import { repeatOn } from '../../../utils';
import { Doel, DoelSuggestie } from '../../doel-shared/model/doel';
import { ContactMethodeNietGeverifieerdCardComponent } from '../../../profiel/component/contact-methode-niet-geverifieerd-card/contact-methode-niet-geverifieerd-card.component';

@Component({
  standalone: true,
  templateUrl: './doelen-jongere.page.html',
  imports: [
    PageWithHeroComponent,
    ParkourSegmentComponent,
    ParkourButtonComponent,
    TranslateModule,
    DoelVoorstelComponent,
    AsyncPipe,
    PageLayoutComponent,
    PageSectionDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonContent,
    IonHeader,
    DoelListComponent,
    DoelenPlaceholderComponent,
    ContactMethodeNietGeverifieerdCardComponent,
  ],
})
export class DoelenJongerePage extends DoelOverviewPage implements MarksMeldingenAsReadOnView {
  protected readonly doelHeroSources = doelHeroSources;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  override getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return this.doelenService.getDoelSuggesties().pipe(repeatOn(this.refreshVoorstellen));
  }

  override getActieveDoelen(): Observable<Doel[]> {
    return this.doelenService.getActieveDoelen();
  }

  override getBehaaldeDoelen(): Observable<Doel[]> {
    return this.doelenService.getBehaaldeDoelen();
  }

  onVoorstelConfirm(voorstel: DoelSuggestie) {
    this.router.navigate(['add'], {
      queryParams: { suggestieId: voorstel.id },
      relativeTo: this.activatedRoute,
    });
  }

  onVoorstelReject(voorstel: DoelSuggestie) {
    this.doelenService.rejectSuggestie(voorstel.id).subscribe(() => this.refreshVoorstellen.next());
  }
}
