import { Injectable } from '@angular/core';
import { createEvents, EventAttributes } from 'ics';

const ICS_TIMEZONE_CONFIG = `BEGIN:VTIMEZONE
TZID:Europe/Brussels
LAST-MODIFIED:20230407T050750Z
TZURL:https://www.tzurl.org/zoneinfo-outlook/Europe/Brussels
X-LIC-LOCATION:Europe/Brussels
BEGIN:DAYLIGHT
TZNAME:CEST
TZOFFSETFROM:+0100
TZOFFSETTO:+0200
DTSTART:19700329T020000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU
END:DAYLIGHT
BEGIN:STANDARD
TZNAME:CET
TZOFFSETFROM:+0200
TZOFFSETTO:+0100
DTSTART:19701025T030000
RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU
END:STANDARD
END:VTIMEZONE`;

@Injectable({
  providedIn: 'root',
})
export class ExportKalenderService {
  constructor() {}

  async exportAfsprakenToIcsBlob(events: EventAttributes[], filename: string): Promise<Blob> {
    return await new Promise((resolve, reject) => {
      createEvents(events, (error, value) => {
        if (error) {
          reject(error);
        }

        const timezone = ICS_TIMEZONE_CONFIG;
        const tzPosition = value.indexOf('X-PUBLISHED-TTL:PT1H');

        if (tzPosition !== -1) {
          value = value.replace(/DTSTART:/g, 'DTSTART;TZID=Europe/Brussels:');
          value = value.replace(/DTEND:/g, 'DTEND;TZID=Europe/Brussels:');
          value = value.slice(0, tzPosition + 20) + '\n' + timezone + value.slice(tzPosition + 20);
        }

        resolve(new File([value], filename, { type: 'text/calendar' }));
      });
    });
  }
}
