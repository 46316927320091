import { ContextId, LastContextDto, ProfielId } from 'parkour-web-app-dto';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { asType } from '../../utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root',
})
export class LastContextService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly contextService: ContextService,
  ) {}

  addLastContext(lastContext: LastContextDto): Observable<LastContextDto> {
    return this.httpClient.put<LastContextDto>(
      `${environment.API_BASE_URL}/api/last-context`,
      asType<LastContextDto>(lastContext),
    );
  }

  private getLastContext(): Observable<LastContextDto | null> {
    return this.httpClient.get<LastContextDto>(`${environment.API_BASE_URL}/api/last-context`);
  }

  getLastContextId(profielId: ProfielId): Observable<ContextId | undefined> {
    return this.getLastContext().pipe(
      switchMap((lastContext) => {
        if (!lastContext) {
          return of(undefined);
        }

        if (lastContext.profielId !== profielId) {
          return of(undefined);
        }

        return this.contextService.canAccessContextId(lastContext.contextId).pipe(
          switchMap((canAccess) => {
            if (canAccess) {
              return of(lastContext.contextId);
            } else {
              return of(undefined);
            }
          }),
        );
      }),
    );
  }
}
