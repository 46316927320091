import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DoelListItemComponent } from '../doel-list-item/doel-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { Doel } from '../../model/doel';
import { MeldingOfType } from 'parkour-web-app-dto';
import { Observable } from 'rxjs';
import { HasMeldingenForDoelPipe } from '../../pipe/has-meldingen-for-doel.pipe';

@Component({
  selector: 'parkour-doel-list',
  standalone: true,
  imports: [AsyncPipe, DoelListItemComponent, HasMeldingenForDoelPipe, TranslateModule],
  templateUrl: './doel-list.component.html',
})
export class DoelListComponent {
  @Input({ required: true }) doelen!: Doel[];
  @Input({ required: true }) ongelezenDoelMeldingen$!: Observable<
    MeldingOfType<
      | 'DOEL_GEDEELD'
      | 'DOEL_EMOJI_REACTIE_ADDED'
      | 'DOEL_VOLTOOID'
      | 'DOEL_NIEUW_BERICHT'
      | 'DOEL_NIEUW_BERICHT_REACTIE'
    >[]
  >;
}
