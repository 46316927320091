<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['../..']" />
    </ion-buttons>

    <ion-title aria-level="1" role="heading">{{ 'over-parkour.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10">
      <parkour-hero
        [fullScreen]="true"
        [image]="{
          sources: {
            lg: {
              src: '/assets/about/background/lg/background_about_lg.webp',
              fallback: '/assets/about/background/lg/background_about_lg.jpg',
            },
            md: {
              src: '/assets/about/background/md/background_about_md.webp',
              fallback: '/assets/about/background/md/background_about_md.jpg',
            },
            default: {
              src: '/assets/about/background/default/background_about_default.webp',
              fallback: '/assets/about/background/default/background_about_default.jpg',
            },
          },
        }"
      >
        <div class="container container-space flex flex-col gap-y-4">
          <div class="container-1-col flex flex-col gap-y-4">
            <div class="wysiwyg text-center px-4">
              <h2 class="typo-h3">Voor jongeren en hun team</h2>

              <p>
                PARKOUR is het Opgroeien-platform voor jongeren tussen 13 en 25 jaar. Ze vinden er
                makkelijk informatie terug over zichzelf en over de thema&rsquo;s die hen
                bezighouden.
              </p>

              <p>
                In PARKOUR schrijven jongeren hun eigen verhaal en formuleren ze zelf doelen en
                acties. Ze kunnen deze delen met het team dat ze zelf samenstellen. Met die
                teamleden kunnen ze ook veilig chatten en beeldbellen.
              </p>
            </div>

            @if (!isNativeApp()) {
              <parkour-download-apps />
            }

            <ul class="space-y-4">
              <li>
                <parkour-action [link]="['..', 'aan-de-slag']" classes="flex gap-x-3 items-center">
                  <parkour-icon class="text-p-100" name="arrow-right" size="3" />

                  <span class="typo-body variant-body-fat"> Aan de slag </span>
                </parkour-action>
              </li>

              <li>
                <parkour-action
                  [link]="['..', 'waarom-parkour']"
                  classes="flex gap-x-3 items-center"
                >
                  <parkour-icon class="text-p-100" name="arrow-right" size="3" />

                  <span class="typo-body variant-body-fat">
                    {{ 'over-parkour.waarom-parkour-page.title' | translate }}
                  </span>
                </parkour-action>
              </li>

              <li>
                <parkour-action
                  classes="flex gap-x-3 items-center"
                  href="https://www.opgroeien.be/over-opgroeien/kansrijk-opgroeien-in-vlaanderen-en-brussel"
                >
                  <parkour-icon class="text-p-100" name="arrow-right" size="3" />

                  <span class="typo-body variant-body-fat">
                    {{ 'over-parkour.over-opgroeien.title' | translate }}
                  </span>
                </parkour-action>
              </li>

              <li>
                <parkour-action
                  classes="flex gap-x-3 items-center"
                  [link]="['..', 'veelgestelde-vragen']"
                >
                  <parkour-icon class="text-p-100" name="arrow-right" size="3" />

                  <span class="typo-body variant-body-fat">
                    {{ 'over-parkour.veelgestelde-vragen-page.title' | translate }}
                  </span>
                </parkour-action>
              </li>
            </ul>
          </div>
        </div>
      </parkour-hero>
    </div>

    <div class="relative z-10 py-8 container-space flex flex-col gap-y-6">
      <ul class="space-y-4 container-1-col">
        <li>
          <parkour-button [link]="['..', 'voorwaarden']" size="none" variant="ghost">
            {{ 'over-parkour.gebruiksvoorwaarden-page.title' | translate }}
          </parkour-button>
        </li>

        <li>
          <parkour-button [link]="['..', 'privacy']" size="none" variant="ghost">
            {{ 'over-parkour.privacy-verklaring-page.title' | translate }}
          </parkour-button>
        </li>

        <li>
          <parkour-button [link]="['..', 'cookiebeleid']" size="none" variant="ghost">
            Cookiebeleid
          </parkour-button>
        </li>

        <li>
          <parkour-button
            (clicked)="parkourCookiesService.openCookiePopup()"
            size="none"
            variant="ghost"
          >
            Cookievoorkeuren
          </parkour-button>
        </li>

        <li>
          <parkour-button [link]="['..', 'toegankelijkheid']" size="none" variant="ghost">
            {{ 'over-parkour.toegankelijkheids-verklaring-page.title' | translate }}
          </parkour-button>
        </li>
      </ul>
    </div>
  </parkour-page-layout>
</ion-content>
