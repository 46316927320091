import {UitnodigingId} from './uitnodiging.dto';
import {ContextId, ProfielId} from './profiel.dto';
import {AfspraakId} from './kalender.dto';
import {GesprekId} from './bericht.dto';
import {Page} from './page.dto';
import {ApiProperty} from '@nestjs/swagger';
import {DoelId} from './doel.dto';
import {InteractieEmoji} from './reactie.dto';
import {GebeurtenisId} from './gebeurtenis.dto';
import {SuggestieId} from './gebeurtenis-suggestie.dto';
import {generateSchemaPaths} from "../utils";

export class RegistreerVoorMeldingenDto {
    @ApiProperty()
    readonly token!: string;
}

class MeldingBase {
    @ApiProperty()
    readonly id!: string;
    @ApiProperty()
    readonly tijdstip!: string;
    @ApiProperty()
    readonly gelezen!: boolean;
}

export class MeldingEvent {
    @ApiProperty()
    readonly meldingId!: string;
    @ApiProperty()
    readonly timestamp!: string;
}

class TeamVerlatenMeldingMetaData {
    @ApiProperty()
    readonly naam!: string;
}

export class TeamVerlatenMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'TEAM_VERLATEN';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: TeamVerlatenMeldingMetaData;
}


class UitnodigingAanvaardMeldingMetaData {
    @ApiProperty({type: String})
    uitnodigingId!: UitnodigingId;
    @ApiProperty({type: String})
    uitgenodigdeId!: ProfielId;
}

export class UitnodigingAanvaardMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'UITNODIGING_AANVAARD';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: UitnodigingAanvaardMeldingMetaData;
}

class UitnodigingGeweigerdMeldingMetaData {
    @ApiProperty({type: String})
    uitnodigingId!: UitnodigingId;
    @ApiProperty()
    uitgenodigdeNaam!: string;
}

export class UitnodigingGeweigerdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'UITNODIGING_GEWEIGERD';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: UitnodigingGeweigerdMeldingMetaData;
}


class UitnodigingBevestigdMeldingMetaData {
    @ApiProperty({type: String})
    uitnodigingId!: UitnodigingId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
}

export class UitnodigingBevestigdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'UITNODIGING_BEVESTIGD';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: UitnodigingBevestigdMeldingMetaData;
}


class UitnodigingBevestigingGeweigerdMetaData {
    @ApiProperty({type: String})
    uitnodigingId!: UitnodigingId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
}

export class UitnodigingBevestigingGeweigerd extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'BEVESTIGING_GEWEIGERD';
    @ApiProperty()
    readonly metaData!: UitnodigingBevestigingGeweigerdMetaData;
}

class UitgenodigdMeldingMetaData {
    @ApiProperty({type: String})
    uitnodigingId!: UitnodigingId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
}

export class UitgenodigdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'UITGENODIGD';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: UitgenodigdMeldingMetaData;
}


class NieuwDrieKolommenDocumentMeldingMetaData {
    @ApiProperty()
    documentId!: string;
}

export class NieuwDrieKolommenDocumentMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'NIEUW_DRIE_KOLOMMEN_DOCUMENT';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: NieuwDrieKolommenDocumentMeldingMetaData;
}


class NieuwEyouthGebeurtenisMeldingMetaData {
    @ApiProperty()
    gebeurtenisId!: string;
}

export class NieuwEyouthGebeurtenisMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'NIEUWE_EYOUTH_GEBEURTENIS';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: NieuwEyouthGebeurtenisMeldingMetaData;
}


class AfspraakHerinneringMeldingMetaData {
    @ApiProperty({type: String})
    afspraakId!: AfspraakId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
    @ApiProperty()
    titel!: string;
    @ApiProperty()
    van!: string;
}

export class AfspraakHerinneringMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'AFSPRAAK_HERINNERING';
    @ApiProperty()
    readonly metaData!: AfspraakHerinneringMeldingMetaData;
}


class AfspraakAangemaaktMeldingMetaData {
    @ApiProperty({type: String})
    afspraakId!: AfspraakId;
    @ApiProperty({type: String})
    with!: ProfielId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
    @ApiProperty()
    van!: string;
    @ApiProperty()
    tot!: string;
}

export class AfspraakAangemaaktMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'AFSPRAAK_AANGEMAAKT';
    @ApiProperty()
    readonly metaData!: AfspraakAangemaaktMeldingMetaData;
}


class AfspraakVerwijderdMeldingMetaData {
    @ApiProperty()
    van!: string;
    @ApiProperty()
    tot!: string;
    @ApiProperty({type: String})
    with!: ProfielId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
}

export class AfspraakVerwijderdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'AFSPRAAK_VERWIJDERD';
    @ApiProperty()
    readonly metaData!: AfspraakVerwijderdMeldingMetaData;
}


class AfspraakGestartMeldingMetaData {
    @ApiProperty({type: String})
    with!: ProfielId;
    @ApiProperty({type: String})
    jongereProfielId!: ProfielId;
    @ApiProperty({type: String})
    initiator!: ProfielId;
    @ApiProperty({type: String})
    afspraakId!: AfspraakId;
}

export class AfspraakGestartMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'AFSPRAAK_GESTART';
    @ApiProperty()
    readonly metaData!: AfspraakGestartMeldingMetaData;
}


class NiewChatBerichtMetaData {
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty({type: String})
    readonly verzenderId!: ProfielId;
    @ApiProperty()
    readonly groepsgesprek!: string;
    @ApiProperty({type: String})
    readonly gesprekOwnerId!: ProfielId;
    @ApiProperty({
        type: 'array',
        items: {
            type: 'string',
        },
    })
    readonly deelnemers!: string;
}

export class NieuwChatBerichtMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'NIEUW_CHAT_BERICHT';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: NiewChatBerichtMetaData;
}


class NiewChatReactieMetaData {
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty({type: String})
    readonly verzenderId!: ProfielId;
    @ApiProperty({type: String})
    readonly reactieType!: InteractieEmoji;
    @ApiProperty()
    readonly groepsgesprek!: string;
    @ApiProperty({type: String})
    readonly gesprekOwnerId!: ProfielId;
    @ApiProperty({
        type: 'array',
        items: {
            type: 'string',
        },
    })
    readonly deelnemers!: string;
}

export class NieuwChatReactieMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'NIEUW_CHAT_REACTIE';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: NiewChatReactieMetaData;
}


class DoelGedeeldMeldingMetaData {
    @ApiProperty()
    readonly doelNaam!: string;
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
}

export class DoelGedeeldMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_GEDEELD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelGedeeldMeldingMetaData;
}


class DoelGesuggereerdMeldingMetaData {
    @ApiProperty({type: String})
    readonly suggestieId!: SuggestieId;
    @ApiProperty({type: String})
    readonly suggestedById!: ProfielId;
}

export class DoelGesuggereerdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_GESUGGEREERD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelGesuggereerdMeldingMetaData;
}


class GebeurtenisGesuggereerdMeldingMetaData {
    @ApiProperty({type: String})
    readonly suggestieId!: SuggestieId;
    @ApiProperty({type: String})
    readonly suggestedById!: ProfielId;
}

export class GebeurtenisGesuggereerdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'GEBEURTENIS_GESUGGEREERD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: GebeurtenisGesuggereerdMeldingMetaData;
}


class DoelEmojiReactieAddedMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    readonly doelNaam!: string;
    @ApiProperty({type: String})
    readonly reactie!: InteractieEmoji;
    @ApiProperty({type: String})
    readonly reactieOwnerId!: ProfielId;
}

export class DoelEmojiReactieAddedMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_EMOJI_REACTIE_ADDED';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelEmojiReactieAddedMetaData;
}


class DoelVoltooidMeldingMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    @ApiProperty()
    readonly doelNaam!: string;
}

export class DoelVoltooidMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_VOLTOOID';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelVoltooidMeldingMetaData;
}


class GebeurtenisGedeeldMeldingMetaData {
    @ApiProperty({type: String})
    readonly gebeurtenisId!: GebeurtenisId;
}

export class GebeurtenisGedeeldMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'GEBEURTENIS_GEDEELD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: GebeurtenisGedeeldMeldingMetaData;
}


class JeugdhulphistoriekGedeeldMeldingMetaData {
    @ApiProperty({type: String})
    readonly gebeurtenisId!: GebeurtenisId;
}

export class JeugdhulpHistoriekGedeeldMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'JEUGDHULPHISTORIEK_GEDEELD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: JeugdhulphistoriekGedeeldMeldingMetaData;
}


class DoelBerichtAddedMetaData {
    @ApiProperty({type: String})
    readonly verzenderId!: ProfielId;
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    @ApiProperty()
    readonly doelNaam!: string;
}

export class DoelBerichtAddedMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_NIEUW_BERICHT';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelBerichtAddedMetaData;
}


class DoelBerichtReactieAddedMetaData {
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty({type: String})
    readonly verzenderId!: ProfielId;
    @ApiProperty({type: String})
    readonly reactieType!: InteractieEmoji;
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    readonly doelNaam!: string;
}

export class DoelBerichtReactieAddedMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_NIEUW_BERICHT_REACTIE';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelBerichtReactieAddedMetaData;
}


export class DoelReactie extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'DOEL_REACTIE';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: DoelBerichtReactieAddedMetaData;
}

class VideogesprekGestartMeldingMetaData {
    @ApiProperty({type: String})
    readonly gesprekId!: GesprekId;
    @ApiProperty({type: String})
    readonly with!: ProfielId;
}

export class VideogesprekGestartMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'VIDEOGESPREK_GESTART';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: VideogesprekGestartMeldingMetaData;
}


class GeblokkeerdMeldingMetaData {
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
}

export class GeblokkeerdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'GEBLOKKEERD';
    @ApiProperty()
    readonly metaData!: GeblokkeerdMeldingMetaData;
}

class GedeblokkeerdMeldingMetaData {
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
}

export class GedeblokkeerdMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'GEDEBLOKKEERD';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: GedeblokkeerdMeldingMetaData;
}

class VerwijderdUitTeamMeldingMetaData {
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
}

export class VerwijderdUitTeamMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'VERWIJDERD_UIT_TEAM';
    @ApiProperty()
    readonly metaData!: VerwijderdUitTeamMeldingMetaData;
}

class VerwijderdUitTeamDoorJongere25MeldingMetaData {
    @ApiProperty()
    readonly jongereNaam!: string;
}

export class VerwijderdUitTeamDoorJongere25 extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'VERWIJDERD_UIT_TEAM_DOOR_JONGERE_25';
    @ApiProperty()
    readonly metaData!: VerwijderdUitTeamDoorJongere25MeldingMetaData;
}

class ReminderOneWeekMeldingMetaData {
}

export class ReminderOneWeekMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'JONGERE_REMINDER_ONE_WEEK';
    @ApiProperty()
    readonly metaData!: ReminderOneWeekMeldingMetaData;
}

class ReminderOneDayMeldingMetaData {
}

export class ReminderOneDayMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'JONGERE_REMINDER_ONE_DAY';
    @ApiProperty()
    readonly metaData!: ReminderOneDayMeldingMetaData;
}

class ProfielWordtVerwijderdHerinneringMetaData {
    @ApiProperty()
    verwijderDatum!: string;
}

export class ProfielWordtVerwijderdHerinneringMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'PROFIEL_WORDT_VERWIJDERD_HERINNERING';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: ProfielWordtVerwijderdHerinneringMetaData;
}

class EersteTeamlidToevoegenMetaData {
}

export class EersteTeamlidToevoegenMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'EERSTE_TEAMLID_TOEVOEGEN';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: EersteTeamlidToevoegenMetaData;
}

class EersteTeamUitnodigingMetaData {
}

export class EersteTeamUitnodigingMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'EERSTE_TEAM_UITNODIGING';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: EersteTeamUitnodigingMetaData;
}

class EersteDoelDelenMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
}

export class EersteDoelDelenMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'EERSTE_DOEL_DELEN';
    @ApiProperty({type: String})
    readonly contextId!: ContextId;
    @ApiProperty()
    readonly metaData!: EersteDoelDelenMetaData;
}

class EersteDoelVoorstellenMetaData {
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
}

export class EersteDoelVoorstellenMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'EERSTE_DOEL_VOORSTELLEN';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: EersteDoelVoorstellenMetaData;
}

class EersteBerichtSturenMetaData {
    @ApiProperty({type: String})
    readonly teamlidProfielId!: ProfielId;
}

export class EersteBerichtSturenMelding extends MeldingBase {
    @ApiProperty({type: String})
    readonly type!: 'EERSTE_BERICHT_STUREN';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    @ApiProperty()
    readonly metaData!: EersteBerichtSturenMetaData;
}

class WerkenAanDoelMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    readonly doelNaam!: string;
}

export class WerkenAanDoelMelding extends MeldingBase {
    readonly type!: 'WERKEN_AAN_DOEL';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    readonly metaData!: WerkenAanDoelMetaData;
}

class HelpenBijDoelEersteMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    @ApiProperty()
    readonly doelNaam!: string;
    @ApiProperty()
    readonly jongereNaam!: string;
}

export class HelpenBijDoelEersteMelding extends MeldingBase {
    readonly type!: 'HELPEN_BIJ_DOEL_1';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    readonly metaData!: HelpenBijDoelEersteMetaData;
}

class HelpenBijDoelTweedeMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    @ApiProperty()
    readonly doelNaam!: string;
    @ApiProperty()
    readonly jongereNaam!: string;
}

export class HelpenBijDoelTweedeMelding extends MeldingBase {
    readonly type!: 'HELPEN_BIJ_DOEL_2';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    readonly metaData!: HelpenBijDoelTweedeMetaData;
}

class EersteStapAfgevinktMetaData {
    @ApiProperty({type: String})
    readonly doelId!: DoelId;
    @ApiProperty()
    readonly doelNaam!: string;
    @ApiProperty()
    readonly jongereNaam!: string;
}

export class EersteStapAfgevinktMelding extends MeldingBase {
    readonly type!: 'EERSTE_STAP_AFGEVINKT';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    readonly metaData!: EersteStapAfgevinktMetaData;
}


export function hasContextId(melding: MeldingDto): melding is Extract<MeldingDto, { contextId: ProfielId }> {
    return "contextId" in melding;
}

export const meldingenTypes = [
    TeamVerlatenMelding,
    UitnodigingAanvaardMelding,
    UitnodigingGeweigerdMelding,
    UitnodigingBevestigdMelding,
    UitnodigingBevestigingGeweigerd,
    UitgenodigdMelding,
    NieuwDrieKolommenDocumentMelding,
    NieuwEyouthGebeurtenisMelding,
    AfspraakHerinneringMelding,
    AfspraakAangemaaktMelding,
    AfspraakVerwijderdMelding,
    AfspraakGestartMelding,
    NieuwChatBerichtMelding,
    NieuwChatReactieMelding,
    DoelGedeeldMelding,
    DoelEmojiReactieAddedMelding,
    DoelGesuggereerdMelding,
    DoelVoltooidMelding,
    GebeurtenisGedeeldMelding,
    JeugdhulpHistoriekGedeeldMelding,
    DoelBerichtAddedMelding,
    DoelBerichtReactieAddedMelding,
    VideogesprekGestartMelding,
    GeblokkeerdMelding,
    GedeblokkeerdMelding,
    GebeurtenisGesuggereerdMelding,
    VerwijderdUitTeamMelding,
    VerwijderdUitTeamDoorJongere25,
    ReminderOneDayMelding,
    ReminderOneWeekMelding,
    ProfielWordtVerwijderdHerinneringMelding,
    EersteTeamlidToevoegenMelding,
    EersteTeamUitnodigingMelding,
    EersteDoelDelenMelding,
    EersteDoelVoorstellenMelding,
    EersteBerichtSturenMelding,
    WerkenAanDoelMelding,
    HelpenBijDoelEersteMelding,
    HelpenBijDoelTweedeMelding,
    EersteStapAfgevinktMelding,
];

export type MeldingDto = InstanceType<(typeof meldingenTypes)[number]>;

export const meldingSchemas = generateSchemaPaths(meldingenTypes);

export class MeldingenPageDto implements Page<MeldingDto> {
    @ApiProperty({
        type: 'array',
        items: {
            anyOf: meldingSchemas,
        },
    })
    readonly content!: MeldingDto[];
    @ApiProperty()
    readonly totalPages!: number;
    @ApiProperty()
    readonly number!: number;
    @ApiProperty()
    readonly last!: boolean;
}

export type MeldingType = MeldingDto['type'];

export type MeldingOfType<T extends MeldingType> = Extract<
    MeldingDto,
    {
        type: T;
    }
>;
