import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import {
  GebeurtenisSuggestie,
  ParkourGebeurtenisUpsert,
  validatedSuggestieId,
} from '../../model/gebeurtenis';
import { Component, ViewChild } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { GebeurtenisFormComponent } from '../../component/gebeurtenis-form/gebeurtenis-form.component';
import { ShowIfSuccesfulDirective } from '../../../../shared/directives/show-if-succesful.directive';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { DirtyFormCheck } from '../../../../shared/components/dirty-form-guard';
import { VerhalenService } from '../../service/verhalen.service';
import { SuggestieService } from '../../../../suggestie/service/suggestie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamService } from '../../../../team/service/team.service';
import { combineLatest, EMPTY, map, of, switchMap } from 'rxjs';
import { FileData, FotoId, GebeurtenisDto } from 'parkour-web-app-dto';
import { getSecureImage } from '../../../../shared/pipes/secure-image.pipe';

type PageData = {
  teamleden: TeamlidProfiel[];
  gebeurtenisSuggestie?: GebeurtenisSuggestie;
};

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-add.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    TranslateModule,
    IonContent,
    PageLayoutComponent,
    AsyncPipe,
    GebeurtenisFormComponent,
    NgIf,
    ParkourHeaderActionComponent,
    ShowIfSuccesfulDirective,
    PageContentDirective,
  ],
})
export class GebeurtenisAddPage extends ParkourDataPage<PageData> implements DirtyFormCheck {
  @ViewChild('gebeurtenisForm') gebeurtenisForm?: GebeurtenisFormComponent;

  constructor(
    private readonly verhalenService: VerhalenService,
    private readonly suggestieService: SuggestieService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly teamService: TeamService,
  ) {
    super();
  }

  override retrieveMainData() {
    const suggestie$ = this.activatedRoute.queryParamMap.pipe(
      switchMap((params) => {
        const suggestieId = params.get('suggestieId');
        if (suggestieId) {
          return this.suggestieService.getGebeurtenisSuggestie(validatedSuggestieId(suggestieId));
        } else {
          return of(undefined);
        }
      }),
    );

    return combineLatest({
      teamleden: this.teamService.getMyTeamleden(),
      gebeurtenisSuggestie: suggestie$,
    });
  }

  getInitialState(suggestie?: GebeurtenisSuggestie): Partial<GebeurtenisDto> {
    return suggestie
      ? {
          titel: suggestie.gebeurtenisData.titel,
          omschrijving: suggestie.gebeurtenisData.omschrijving,
          startDatum: suggestie.gebeurtenisData.startDatum,
          bijlagen: suggestie.gebeurtenisData.bijlagen,
          fotoId: suggestie.gebeurtenisData.imageId,
          thema: suggestie.gebeurtenisData.thema,
        }
      : {};
  }

  protected getExistingGebeurtenisImage = (imageId: FotoId) =>
    this.mainPageData$.pipe(
      switchMap((data) => {
        if (data.success && data.value.gebeurtenisSuggestie) {
          return getSecureImage(
            this.suggestieService.getImageUrl(data.value.gebeurtenisSuggestie.id, imageId, 'LARGE'),
          );
        } else {
          return EMPTY;
        }
      }),
    );

  getSubmitGebeurtenisOfSuggestie = (suggestie?: GebeurtenisSuggestie) => {
    return (gebeurtenisUpsert: ParkourGebeurtenisUpsert) => {
      if (suggestie) {
        gebeurtenisUpsert = {
          ...gebeurtenisUpsert,
          originSuggestie: suggestie.id,
        };
      }

      return this.verhalenService.addGebeurtenis(gebeurtenisUpsert).pipe(
        map(() => undefined),
        switchMap(() => {
          return this.router.navigate(['..'], { relativeTo: this.activatedRoute });
        }),
      );
    };
  };

  isFormDirty() {
    return this.gebeurtenisForm?.registerGebeurtenisForm.dirty ?? false;
  }

  onExistingFileDownload(
    fileData: FileData,
    gebeurtenisSuggestie: GebeurtenisSuggestie | undefined,
  ) {
    if (!gebeurtenisSuggestie) {
      throw new Error('GebeurtenisSuggestie should exist when existing file is present');
    }

    this.suggestieService.downloadBijlage(gebeurtenisSuggestie.id, fileData).subscribe();
  }
}
