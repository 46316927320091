import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Player from '@vimeo/player';
import { YouTubePlayer } from '@angular/youtube-player';
import { ParkourButtonComponent, ParkourInfoCardWithTitleComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourCookiesService } from '../../services/parkour-cookies.service';

export type VideoContentType = 'vimeo' | 'youtube';

export type VideoContent = {
  type: VideoContentType;
  url?: string;
  id?: string;
};

@Component({
  selector: 'parkour-video-content',
  templateUrl: './parkour-video-content.component.html',
  styleUrls: ['./parkour-video-component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    YouTubePlayer,
    ParkourButtonComponent,
    ParkourInfoCardWithTitleComponent,
    TranslateModule,
  ],
})
export class ParkourVideoContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) content!: VideoContent;
  videoId: string | undefined | null = '';
  videoPlayerScript?: HTMLElement;
  @ViewChild('vimeoPlayer') vimeoPlayer!: ElementRef;

  cookiesEnabled = false;
  playerVars = {
    disablekb: 1,
  };

  constructor(protected readonly parkourCookiesService: ParkourCookiesService) {}

  ngOnInit() {
    this.cookiesEnabled = this.parkourCookiesService.isMarketingCookiesEnabled();

    if (this.cookiesEnabled && this.content.type === 'youtube') {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      this.videoPlayerScript = document.body.appendChild(tag);

      this.videoId = this.getYoutubeVideoId(this.content);
    }
  }

  getYoutubeVideoId(content: VideoContent): string | null {
    if (content.id) {
      return content.id;
    } else if (content.url) {
      const urlObj = new URL(content.url);

      // Links can either be of format: https://www.youtube.com/watch?v=videoId or https://www.youtube.com/embed/videoId or https://youtu.be/cQuaabTvv4o

      if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname === '/watch') {
        return urlObj.searchParams.get('v');
      } else if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname.includes('/embed/')) {
        return urlObj.pathname.split('/embed/')[1];
      } else if (urlObj.hostname === 'youtu.be') {
        return urlObj.pathname.slice(1);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  ngAfterViewInit() {
    if (this.cookiesEnabled && this.content.type === 'vimeo' && this.vimeoPlayer.nativeElement) {
      new Player(this.vimeoPlayer.nativeElement, {
        url: this.content.id,
        keyboard: false,
      });
    }
  }

  ngOnDestroy() {
    if (this.videoPlayerScript) {
      document.body.removeChild(this.videoPlayerScript);
    }
  }

  onOpenCookieVoorkeuren() {
    this.parkourCookiesService.callback = () => window.location.reload();
    this.parkourCookiesService.openCookiePopup();
  }
}
