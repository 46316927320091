<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let data">
      <parkour-page-with-hero
        [heroImage]="doelHeroSources"
        [title]="'doelen.doelen-page.title' | translate"
      >
        <parkour-ex-jongere-info-card info-card />

        @if (data.actieveDoelen.length > 0 || data.behaaldeDoelen.length > 0) {
          <ng-container *parkourSectionContent="{ data: segmentItems$ | async }; let segmentItems">
            <div class="container-1-col">
              <parkour-segment
                (changed)="segmentChanged($event)"
                [defaultValue]="activeSegmentItem"
                [items]="segmentItems"
              />
            </div>

            <div class="container-1-col">
              @switch (activeSegmentItem) {
                @case ('actieveDoelen') {
                  <div class="flex flex-col gap-y-6">
                    @if (data.actieveDoelen.length === 0) {
                      <p class="text-center my-2">
                        {{ 'doelen.doelen-page.geen-doelen' | translate }}
                      </p>
                    } @else {
                      <parkour-doel-list
                        [doelen]="data.actieveDoelen"
                        [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      />
                    }
                  </div>
                }
                @case ('behaaldeDoelen') {
                  <div class="flex flex-col gap-y-6">
                    @if (data.behaaldeDoelen.length === 0) {
                      <p class="text-center my-2">
                        {{ 'doelen.behaalde-doelen-page.geen-doelen' | translate }}
                      </p>
                    } @else {
                      <parkour-doel-list
                        [doelen]="data.behaaldeDoelen"
                        [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      />
                    }
                  </div>
                }
              }
            </div>
          </ng-container>
        } @else {
          <div class="container-1-col space-y-8">
            <p class="text-center">{{ 'doelen.doelen-page.geen-doelen' | translate }}</p>

            <parkour-doelen-placeholder />
          </div>
        }
      </parkour-page-with-hero>
    </ng-container>
  </parkour-page-layout>
</ion-content>
