<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['..']" />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'verhaal.gebeurtenis-add-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ng-container *parkourShowIfSuccessful="mainPageData$ | async">
        <parkour-header-action
          [label]="'verhaal.gebeurtenis-add-page.gebeurtenis-save' | translate"
          [loading]="gebeurtenisForm?.form()?.busy ?? false"
          form="gebeurtenis-form"
          icon="disk"
          testId="save-gebeurtenis"
          type="submit"
        />
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-gebeurtenis-form
        #gebeurtenisForm
        [initialState]="getInitialState(data.gebeurtenisSuggestie)"
        [submitGebeurtenisForm]="getSubmitGebeurtenisOfSuggestie(data.gebeurtenisSuggestie)"
        [teamleden]="data.teamleden"
        [getExistingImage]="getExistingGebeurtenisImage"
        (existingFileDownload)="onExistingFileDownload($event, data.gebeurtenisSuggestie)"
        formId="gebeurtenis-form"
      />
    </ng-container>
  </parkour-page-layout>
</ion-content>
