import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CdkPortalOutlet, Portal } from '@angular/cdk/portal';
import { SheetService } from '../../services/parkour-sheet.service';
import { ParkourButtonComponent, ParkourIconComponent, TParkourIconName } from '@parkour/ui';
import { Subscription } from 'rxjs';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-sheet',
  templateUrl: './parkour-sheet.component.html',
  imports: [
    ParkourIconComponent,
    CdkTrapFocus,
    NgClass,
    ParkourButtonComponent,
    TranslateModule,
    CdkPortalOutlet,
  ],
})
export class ParkourSheetComponent implements OnInit, OnDestroy {
  isOpen = false;
  @HostBinding('class.invisible') hiddenFromDom = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedPortal: Portal<any> | null = null;
  title!: string;
  icon!: TParkourIconName;
  id: string = 'sheet';
  routerSubscription!: Subscription;
  isOpenSubscription!: Subscription;
  portalSubscription!: Subscription;

  constructor(private readonly sheetService: SheetService) {}

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.isOpenSubscription.unsubscribe();
    this.portalSubscription.unsubscribe();
  }

  ngOnInit() {
    this.isOpenSubscription = this.sheetService.isOpen.subscribe((isOpen) => {
      this.isOpen = isOpen;

      if (this.isOpen) {
        this.hiddenFromDom = false;
      }
    });

    this.portalSubscription = this.sheetService.portal.subscribe((portal) => {
      if (portal) {
        const { id, title, icon } = portal.context;

        this.id = id;
        this.title = title;
        this.icon = icon;
        this.selectedPortal = portal;
      } else {
        this.selectedPortal = null;
      }
    });

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.isOpen && event.key === 'Escape') {
        this.onClose();
      }
    });
  }

  onClose() {
    if (this.isOpen) {
      this.sheetService.triggerClose();
    }
  }

  onTransitionEnd() {
    if (!this.isOpen) {
      this.hiddenFromDom = true;
    }
  }
}
