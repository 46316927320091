import { GetPageFunction, Pagination } from './parkour-pagination';
import { ParkourPage } from './parkour-page';
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@ionic/angular/standalone';
import { merge, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { LoggingService } from '../core/logging.service';

export abstract class ParkourPaginatedPage<T> extends ParkourPage {
  private readonly dataRefresh$ = new Subject<RefresherCustomEvent>();
  protected loading = false;

  constructor(private readonly loggingService: LoggingService) {
    // injecting using Inject only works within angular component
    super();
  }

  protected override refreshPageDataOnly = (event: RefresherCustomEvent) => {
    this.dataRefresh$.next(event);
  };

  protected abstract retrievePage: GetPageFunction<T>;
  private currentPagination: Pagination<T> | undefined;

  private createPagination(): Pagination<T> {
    this.currentPagination = new Pagination(this.retrievePage, (e) => {
      this.loggingService.error('Error fetching page', e);
    });
    return this.currentPagination;
  }

  public readonly paginatedData$ = merge(this.dataRefresh$, this.pageWillEnter$).pipe(
    switchMap((event) => this.createPagination().pipe(tap(() => event && event.detail.complete()))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  fetchNextPage(event: InfiniteScrollCustomEvent) {
    if (this.currentPagination === undefined) {
      throw new Error('No pagination available');
    }

    this.loading = true;
    this.currentPagination.fetchNextPage(() => {
      this.loading = false;
      event.target.complete();
    });
  }

  removeElementsFromCollection(condition: (element: T) => boolean) {
    if (this.currentPagination === undefined) {
      throw new Error('No pagination available');
    }

    this.currentPagination.removeElementsFromCollection(condition);
  }
}
