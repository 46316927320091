import { ActieDto, DeelMode, DoelId as DoelIdDto, ProfielId, Thema } from 'parkour-web-app-dto';
import { GebeurtenisSuggestie } from '../../../verhaal/verhaal-shared/model/gebeurtenis';

export class Doel {
  constructor(
    public readonly id: DoelId,
    public readonly naam: string,
    public readonly omschrijving: string,
    public readonly thema: Thema,
    public readonly aanmaakDatum: string,
    public readonly eigenaarId: ProfielId,
    public readonly streefDatum: Date | undefined,
    public readonly eindDatum: Date | undefined,
    public readonly acties: Actie[],
    public readonly gedeeldMet: ProfielId[],
    public readonly deelMode: DeelMode,
    public readonly activeGebeurtenisSuggestie?: GebeurtenisSuggestie,
  ) {}

  get isBehaald(): boolean {
    return !!this.eindDatum;
  }

  hasActies(): boolean {
    return this.acties.length !== 0;
  }

  alleActiesZijnVoltooid() {
    return this.acties.every((actie) => actie.voltooid) && this.hasActies();
  }
}

export type DoelId = DoelIdDto;
export type Actie = ActieDto;
