import { Component, Input, OnInit } from '@angular/core';
import { WatWatFaqContent } from 'parkour-web-app-dto';
import { MakeReferencesAbsolutePipe } from '../../pipes/make-references-absolute.pipe';
import { ParkourAccordionComponent } from '../../../shared/components/parkour-accordion/parkour-accordion.component';
import { TAccordionItems } from '../../../shared/components/parkour-accordion/parkour-accordion.types';

@Component({
  selector: 'parkour-artikel-faq-content',
  templateUrl: './artikel-faq-content.component.html',
  standalone: true,
  imports: [ParkourAccordionComponent, ParkourAccordionComponent],
})
export class ArtikelFaqContentComponent implements OnInit {
  @Input({ required: true }) content!: WatWatFaqContent;

  public accordionItems: TAccordionItems = [];

  constructor(private readonly makeReferencesAbsolutePipe: MakeReferencesAbsolutePipe) {}

  ngOnInit() {
    this.mapWatWatFaqContent();
  }

  mapWatWatFaqContent() {
    this.accordionItems = (this.content?.content?.items || []).map((item) => {
      return {
        header: this.makeReferencesAbsolutePipe.transform((item.question || '') as string),
        content: this.makeReferencesAbsolutePipe.transform((item.answer || '') as string),
      };
    });
  }
}
