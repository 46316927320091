import { Component, ViewChild } from '@angular/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import {
  ParkourButtonComponent,
  ParkourPageGradientComponent,
  ParkourPageImageComponent,
  ParkourPopupComponent,
} from '@parkour/ui';
import { DoelEditFormComponent } from '../../doel-shared/component/doel-edit-form/doel-edit-form.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { Doel } from '../../doel-shared/model/doel';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { CustomPageTitle } from '../../../shared/custom-page-title';
import { combineLatest, Observable, switchMap, tap } from 'rxjs';
import { Result } from '../../../utils';
import { themaMapping } from 'src/app/home/model/thema.const';
import { DoelenService } from '../../doel-shared/service/doelen.service';
import { TeamService } from '../../../team/service/team.service';
import { ActivatedRoute, Router } from '@angular/router';
import { validateDoelId } from '../../utils';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';
import { DoelForm } from '../../doel-shared/component/doel-form/doel-form.component';
import { DoelUpsert } from '../../doel-shared/model/doel-upsert';

@Component({
  standalone: true,
  templateUrl: './doel-edit.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    ParkourPageImageComponent,
    DoelEditFormComponent,
    ParkourButtonComponent,
    AsyncPipe,
    ParkourPopupComponent,
    TranslateModule,
    PageLayoutComponent,
    PageContentDirective,
    IonBackButton,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonTitle,
    IonContent,
    ParkourPageGradientComponent,
  ],
})
export class DoelEditPage
  extends ParkourDataPage<{
    doel: Doel;
    teamleden: TeamlidProfiel[];
  }>
  implements ViewWillEnter, DirtyFormCheck, CustomPageTitle
{
  id: string | null = null;
  data$: Observable<
    Result<{
      doel: Doel;
      teamleden: TeamlidProfiel[];
    }>
  > = new Observable();

  @ViewChild('doelForm') doelForm?: DoelEditFormComponent;

  isVerwijderDoelPopupOpen = false;
  protected readonly themaMapping = themaMapping;

  constructor(
    private doelenService: DoelenService,
    private teamService: TeamService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  override retrieveMainData() {
    this.id = this.route.snapshot.paramMap.get('id');

    return combineLatest({
      doel: this.doelenService.getDoel(validateDoelId(this.id)),
      teamleden: this.teamService.getMyTeamleden(),
    });
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle((data) => data.doel.naam, 'doelen.doel-edit-page.title');
  }

  submitDoel = (formValues: RawValueOfFormGroup<DoelForm>) => {
    return this.getCurrentMainDataOrThrow().pipe(
      switchMap((data) => {
        if (formValues.doelDataSection.thema === '') {
          throw new Error('Thema is verplicht');
        }

        const doelUpsert: DoelUpsert = {
          naam: formValues.doelDataSection.naam,
          omschrijving: formValues.doelDataSection.omschrijving,
          thema: formValues.doelDataSection.thema,
          streefDatum:
            formValues.doelDataSection.streefDatum !== ''
              ? new Date(formValues.doelDataSection.streefDatum)
              : undefined,
          acties: formValues.actieSection.acties.map((actie) => ({
            naam: actie.naam,
            voltooid: actie.voltooid,
            order: actie.order,
          })),
          deelMode: formValues.gedeeldMetSection.deelMode,
          gedeeldMet: data.teamleden
            .filter((_, index) => formValues.gedeeldMetSection.gedeeldMet[index].gedeeldMet)
            .map((teamlid) => teamlid.id),
        };

        return this.doelenService
          .updateDoel(validateDoelId(this.id), { ...doelUpsert })
          .pipe(tap(() => this.router.navigate(['..'], { relativeTo: this.route })));
      }),
    );
  };

  isFormDirty() {
    return this.doelForm?.doelForm.dirty ?? false;
  }

  verwijderDoel() {
    this.isVerwijderDoelPopupOpen = false;

    this.doelenService.deleteDoel(validateDoelId(this.id)).subscribe({
      complete: () => {
        this.doelForm?.doelForm.markAsPristine();
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      error: (e) => {
        throw e;
      },
    });
  }
}
