import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { asType } from '../../utils';
import { NotitieId } from 'parkour-web-app-dto';
import { environment } from 'src/environments/environment';
import { Notitie, NotitieUpsert } from '../model/notitie';

@Injectable({
  providedIn: 'root',
})
export class BewaardeItemsService {
  constructor(private readonly http: HttpClient) {}

  getNotities(): Observable<Notitie[]> {
    return this.http.get<Notitie[]>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie`,
    );
  }

  addNotitie(notitie: NotitieUpsert): Observable<Notitie> {
    return this.http.post<Notitie>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie`,
      asType<NotitieUpsert>(notitie),
    );
  }

  deleteNotitie(id: NotitieId) {
    return this.http.delete<void>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${id}`,
    );
  }

  getNotitie(notitieId: NotitieId): Observable<Notitie> {
    return this.http.get<Notitie>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${notitieId}`,
    );
  }

  updateNotitie(notitieId: NotitieId, notitie: NotitieUpsert): Observable<Notitie> {
    return this.http.put<Notitie>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${notitieId}`,
      asType<NotitieUpsert>(notitie),
    );
  }
}
