import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ActieSectionGroup,
  DoelDataSectionGroup,
} from '../../doel-shared/component/doel-form/doel-form.component';
import { Component } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourPageGradientComponent,
  ParkourPageImageComponent,
  ParkourTextareaComponent,
  ParkourToastService,
} from '@parkour/ui';
import { DoelDataSectionComponent } from '../../doel-shared/component/doel-data-section/doel-data-section.component';
import { ActiesSelectSectionComponent } from '../../doel-shared/component/acties-select-section/acties-select-section.component';
import { ParkourFormComponent } from '../../../shared/components/parkour-form/parkour-form.component';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { DoelFormService } from '../../doel-shared/service/doel-form.service';
import { SuggestieService } from '../../../suggestie/service/suggestie.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import AuthService from '../../../authentication/service/auth.service';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';
import { combineLatest, switchMap, tap } from 'rxjs';
import { DoelSuggestieUpsert } from '../../../suggestie/model/suggestie';
import { getProfielnaam } from '../../../profiel/model/profiel-in-team';

export type DoelForm = FormGroup<{
  doelDataSection: DoelDataSectionGroup;
  actieSection: ActieSectionGroup;
}>;

@Component({
  standalone: true,
  templateUrl: './doel-suggestie.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    TranslateModule,
    ParkourHeaderActionComponent,
    IonContent,
    PageLayoutComponent,
    ParkourPageImageComponent,
    ReactiveFormsModule,
    DoelDataSectionComponent,
    ActiesSelectSectionComponent,
    ParkourFormFieldComponent,
    ParkourButtonComponent,
    ParkourTextareaComponent,
    ParkourPageGradientComponent,
    ParkourFormComponent,
  ],
})
export class DoelSuggestiePage implements DirtyFormCheck {
  doelForm: DoelForm;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly doelFormService: DoelFormService,
    private readonly suggestieService: SuggestieService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly toastService: ParkourToastService,
    private readonly profielService: ProfielService,
  ) {
    this.doelForm = this.formBuilder.group({
      doelDataSection: this.doelFormService.createDoelDataFormGroup(),
      actieSection: this.doelFormService.createActieSectionFormGroup(),
    });
  }

  submitDoel = (formValues: RawValueOfFormGroup<DoelForm>) => {
    return this.contextService.teamlidContext$().pipe(
      switchMap((currentContext) =>
        combineLatest([
          this.profielService.getProfielInCurrentTeam(currentContext.contextId),
          this.authService.getAangemeldeUser$(),
        ]).pipe(
          switchMap(([currentContextProfiel, user]) => {
            if (formValues.doelDataSection.thema === '') {
              throw new Error('Thema is verplicht');
            }

            const doelSuggestieUpsert: DoelSuggestieUpsert = {
              titel: formValues.doelDataSection.naam,
              bericht: formValues.doelDataSection.omschrijving || '',
              suggestedById: user.profielId,
              suggestedToId: currentContext.contextId,
              doelUpsertData: {
                naam: formValues.doelDataSection.naam,
                omschrijving: formValues.doelDataSection.omschrijving || '',
                thema: formValues.doelDataSection.thema,
                streefDatum:
                  formValues.doelDataSection.streefDatum !== ''
                    ? formValues.doelDataSection.streefDatum
                    : undefined,
                acties: formValues.actieSection.acties,
                gedeeldMet: [currentContext.contextId],
                deelMode: 'SPECIFIEK',
              },
            };

            return this.suggestieService.addDoelSuggestie(doelSuggestieUpsert).pipe(
              tap(() => {
                this.toastService.showToast({
                  header: 'Voorstel doel verzonden',
                  content: `${getProfielnaam(currentContextProfiel)} ontvangt een melding van je voorstel.`,
                });
                this.router.navigate(['..'], { relativeTo: this.route });
              }),
            );
          }),
        ),
      ),
    );
  };

  isFormDirty(): boolean {
    return this.doelForm.dirty;
  }
}
