import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { homeRoutes } from '../home/routes';
import { verhaalRoutes } from '../verhaal/routes';
import { berichtRoutes } from '../bericht/routes';
import { doelRoutes } from '../doel/routes';
import { profielRoutes } from '../profiel/routes';

export type Tab = 'home' | 'verhaal' | 'berichten' | 'doelen' | 'profiel';
export function getActiveTab(route: ActivatedRouteSnapshot): Tab | undefined {
  if (route.data['tab']) {
    return route.data['tab'];
  }

  return undefined;
}

export const tabsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: 'home',
        title: 'tabs.home',
        children: homeRoutes,
        data: { tab: 'home' },
      },
      {
        path: 'verhaal',
        title: 'tabs.verhaal',
        children: verhaalRoutes,
        data: { tab: 'verhaal' },
      },
      {
        path: 'berichten',
        title: 'tabs.berichten',
        children: berichtRoutes,
        data: { tab: 'berichten' },
      },
      {
        path: 'doelen',
        title: 'tabs.doelen',
        children: doelRoutes,
        data: { tab: 'doelen' },
      },
      {
        path: 'profiel',
        title: 'tabs.profiel',
        children: profielRoutes,
        data: { tab: 'tab' },
      },
    ],
  },
];
