import { Component } from '@angular/core';
import { AangemeldeUser, GeenProfielUser } from '../../../authentication/user';
import { combineLatest, map, Observable } from 'rxjs';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { AsyncPipe } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourPictureComponent,
} from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import AuthService from '../../../authentication/service/auth.service';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { ProfielCreateOption } from 'parkour-web-app-dto';

type PageData = {
  user: AangemeldeUser | GeenProfielUser;
  createOptions: ProfielCreateOption[];
};

@Component({
  standalone: true,
  templateUrl: './profiel-create.page.html',
  imports: [
    AsyncPipe,
    ParkourDividerComponent,
    ParkourPictureComponent,
    ParkourButtonComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    PageContentDirective,
  ],
})
export class ProfielCreatePage extends ParkourDataPage<PageData> implements ViewWillEnter {
  constructor(
    private readonly authService: AuthService,
    private readonly profielService: ProfielService,
    private readonly profielCreateService: ProfielCreateService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<PageData> {
    return combineLatest([
      this.authService.getGeenProfielOrAangemeldeUser$(),
      this.profielService.getProfielCreateOptions(),
    ]).pipe(map(([user, options]) => ({ user, createOptions: options })));
  }

  async onPersoonlijkProfielClick() {
    this.profielCreateService.submitProfielType('persoonlijk');
  }

  async onProfessioneelProfielClick() {
    this.profielCreateService.submitProfielType('professioneel');
  }
}
