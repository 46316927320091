import {
  DoelSuggestieDto,
  DoelSuggestieUpsertDto,
  GebeurtenisSuggestieDto,
  GebeurtenisSuggestieUpsertDto,
  SuggestieId,
} from 'parkour-web-app-dto';
import { DoelUpsert, doelUpsertFromDto } from '../../doel/doel-shared/model/doel-upsert';
import { TeamlidProfiel } from '../../team/model/teamlid.model';

export type GebeurtenisSuggestie = GebeurtenisSuggestieDto;
export type DoelSuggestieUpsert = DoelSuggestieUpsertDto;
export type GebeurtenisSuggestieUpsert = GebeurtenisSuggestieUpsertDto;

export function doelSuggestieFromDto(doelSuggestieDto: DoelSuggestieDto): DoelSuggestie {
  return {
    ...doelSuggestieDto,
    suggestedBy: doelSuggestieDto.suggestedBy,
    doelData: doelUpsertFromDto(doelSuggestieDto.doelData),
    aangemaaktOpTijdstip: new Date(doelSuggestieDto.aangemaaktOpTijdstip),
  };
}

export type Suggestie = {
  readonly id: SuggestieId;
  readonly status: string;
  readonly titel: string;
  readonly bericht: string;
  readonly aangemaaktOpTijdstip: Date;
};

export type DoelSuggestie = {
  doelData: DoelUpsert;
  suggestedBy: TeamlidProfiel;
} & Suggestie;
