<ion-header class="full-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading">{{ titel$ | async }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />
    <div class="relative z-10 py-8 container container-space flex flex-col gap-y-8">
      <parkour-card class="relative" theme="n-150">
        @if (contactMethodeType$ | async; as contactMethodeType) {
          <form (ngSubmit)="onSubmit(contactMethodeType)">
            <h1 class="typo-h4 mb-2">Bevestig met de code</h1>
            <p class="mb-4">
              We stuurden een code naar
              <span class="font-medium">{{ contactEmailOrTelefoonnummer$ | async }}</span>
            </p>
            <parkour-form-field label="Code" inputId="code" class="block mb-8" [required]="true">
              <parkour-input
                autocomplete="one-time-code"
                inputId="code"
                [formControl]="formControl"
              ></parkour-input>
            </parkour-form-field>

            @if (contactEmailOrTelefoonnummer$ | async; as contactEmailOrTelefoonnummer) {
              <p class="mb-8">
                Code niet ontvangen?
                <parkour-button
                  (clicked)="onResendClick(contactEmailOrTelefoonnummer ?? '')"
                  classes="pl-0"
                  variant="ghost-light"
                >
                  Opnieuw verzenden
                </parkour-button>
              </p>
            }

            <parkour-button class="w-full" iconAfter="check" type="submit"
              >Bevestigen</parkour-button
            >
          </form>
        }
      </parkour-card>
    </div>
  </parkour-page-layout>
</ion-content>
