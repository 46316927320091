import { ApplicationRef, ComponentRef, createComponent, Injectable } from '@angular/core';
import { ParkourAnimationModalComponent } from '@parkour/ui';

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  private modalRef: ComponentRef<ParkourAnimationModalComponent> | null = null;

  constructor(private appRef: ApplicationRef) {}

  playSuccessAnimation(): void {
    if (this.modalRef) {
      this.closeModal();
    }

    this.modalRef = createComponent(ParkourAnimationModalComponent, {
      environmentInjector: this.appRef.injector,
    });

    this.modalRef.instance.src = '/assets/animations/success.json';

    this.modalRef.instance.completed.subscribe(() => {
      this.closeModal();
    });

    this.appRef.attachView(this.modalRef.hostView);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domElem = (this.modalRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  closeModal(): void {
    if (this.modalRef) {
      this.appRef.detachView(this.modalRef.hostView);
      this.modalRef.destroy();
      this.modalRef = null;
    }
  }
}
