import { Routes } from '@angular/router';
import { artikelOverviewRoutes, specificArtikelRoute } from '../../watwat/routes';
import { verhaalCreateRoutes } from '../../verhaal/verhaal-create-routes';
import { DoelenJongerePage } from './doelen-jongere-page/doelen-jongere.page';
import { DoelAddPage } from './doel-add-page/doel-add.page';
import { DoelDetailsJongerePage } from './doel-details-jongere-page/doel-details-jongere.page';
import { BerichtProbleemAddPage } from '../../bericht/page/bericht-probleem-add-page/bericht-probleem-add.page';
import { NotitieAddPage } from '../../profiel/page/notitie-add-page/notitie-add.page';
import { DoelEditPage } from './doel-edit-page/doel-edit.page';
import { BehaaldDoelEditPage } from './behaald-doel-edit-page/behaald-doel-edit.page';
import { ContactMethodeBevestigenPageComponent } from '../../profiel/page/contact-methode-bevestigen-page/contact-methode-bevestigen-page.component';

export const doelJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DoelenJongerePage,
    data: { tabRoot: true },
  },
  {
    path: 'add',
    component: DoelAddPage,
    title: 'doelen.doel-add-page.title',
  },
  {
    path: 'contact-methode-bevestigen',
    component: ContactMethodeBevestigenPageComponent,
    data: { hideTabs: true },
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DoelDetailsJongerePage,
      },
      artikelOverviewRoutes,
      specificArtikelRoute,
      {
        path: 'probleem',
        component: BerichtProbleemAddPage,
      },
    ],
  },
  {
    path: ':id/notitie',
    component: NotitieAddPage,
  },
  {
    path: ':id/edit',
    component: DoelEditPage,
  },
  {
    path: ':id/edit-behaald',
    component: BehaaldDoelEditPage,
  },
  {
    path: ':id/verhaal-suggestie',
    children: verhaalCreateRoutes,
  },
];
