<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title
      aria-level="1"
      role="heading"
      *parkourShowIfSuccessful="mainPageData$ | async; let data"
      >{{ 'kalender.kalender-afspraak-edit-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
        <parkour-header-action
          [label]="'kalender.kalender-afspraak-edit-page.form.submit-basic' | translate"
          form="afspraak-add-form"
          icon="disk"
          type="submit"
        />
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="content">
  <parkour-page-layout>
    <parkour-page-gradient />
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <div class="relative z-10 py-8 container container-space">
        <parkour-afspraak-form
          mode="edit"
          [initialState]="data.afspraak"
          [submitAfspraakForm]="submitAfspraakForm"
          #editAfspraakForm
          [isJongere]="data.context.type === 'jongere'"
          [mijnTeamlidContexten]="data.mijnTeamlidContexten"
          [profielId]="data.user.profielId"
          class="block container-1-col"
          formId="afspraak-add-form"
        />
      </div>

      <div class="full-bleed container-space bg-n-200 pt-6 pb-8 mt-8">
        <div class="flex flex-col gap-y-8 container-1-col">
          <div class="space-y-2">
            <h3>
              {{ 'kalender.kalender-afspraak-edit-page.form.verwijder-afspraak-title' | translate }}
            </h3>
            <p>
              {{
                'kalender.kalender-afspraak-edit-page.form.verwijder-afspraak-description'
                  | translate
              }}
            </p>
          </div>

          <parkour-button
            (clicked)="removeAfspraak(data.afspraak.id)"
            class="block w-full"
            iconAfter="trash"
            variant="tertiary"
          >
            {{ 'kalender.kalender-afspraak-edit-page.form.verwijder-afspraak-button' | translate }}
          </parkour-button>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
