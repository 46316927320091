import { Injectable } from '@angular/core';
import { SucceservaringDto, SucceservaringPusherMeldingDto } from 'parkour-web-app-dto';
import { ToastController } from '@ionic/angular';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import AuthService from '../authentication/service/auth.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { PusherService } from '../bericht/service/pusher.service';
import { LoggingService } from '../core/logging.service';
import { isSupportedSucceservaringType, SucceservaringType } from '../shared/model/succeservaring';
import { environment } from '../../environments/environment';
import { AnalyticsEvent } from '../analytics/analytics-event.model';
import { isNativeApp } from '../utils';
import { succeservaringVisualizations } from './succeservaring-visualization';

export type SucceservaringVisualization = {
  readonly icon: string;
  readonly titel: string;
  readonly beschrijving: string;
};

@Injectable({
  providedIn: 'root',
})
export class SucceservaringenService {
  private readonly _succeservaringCounts = new ReplaySubject<SucceservaringDto[]>(1);

  constructor(
    private readonly pusherService: PusherService,
    private readonly loggingService: LoggingService,
    private readonly toastController: ToastController,
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  get succeservaringCounts(): Observable<SucceservaringDto[]> {
    return this._succeservaringCounts;
  }

  public initialize() {
    this.pusherService
      .createPusherObservableForEventOnProfielChannel<SucceservaringPusherMeldingDto>(
        'nieuwe-succeservaring',
      )
      .subscribe((succesErvaringMelding) => this.onSucceservaringReceived(succesErvaringMelding));

    this.authService.user$
      .pipe(
        filter((user) => user.type === 'aangemeld'),
        switchMap(() => this.retrieveSucceservaringen()),
        catchError((error) => {
          this.loggingService.error('Failed to get succeservaringen', error);
          return [];
        }),
      )
      .subscribe((succeservaringen) => {
        this.showNewSucceservaringen(succeservaringen);
      });
  }

  private showNewSucceservaringen(succeservaringen: SucceservaringDto[]) {
    succeservaringen
      .filter(
        (succeservaring) =>
          succeservaring.lastReadCount !== succeservaring.count &&
          succeservaring.lastReadCount === 0,
      )
      .forEach((succeservaring) => this.onSucceservaringReceived({ type: succeservaring.type }));
  }

  private markeerSucceservaringTypeAlsGelezen(type: SucceservaringType) {
    return this.httpClient.put<void>(
      `${environment.API_BASE_URL}/api/succeservaringen/${type}/markeer-als-gelezen`,
      {},
    );
  }

  private retrieveSucceservaringen(): Observable<SucceservaringDto[]> {
    return this.getSucceservaringen().pipe(
      tap((succeservaringen) => this._succeservaringCounts.next(succeservaringen)),
    );
  }

  private getSucceservaringen(): Observable<SucceservaringDto[]> {
    return this.httpClient.get<SucceservaringDto[]>(
      `${environment.API_BASE_URL}/api/succeservaringen`,
    );
  }

  private onSucceservaringReceived = async (event: SucceservaringPusherMeldingDto) => {
    if (!isSupportedSucceservaringType(event.type)) {
      this.loggingService.error(`Received unsupported succeservaring type: ${event.type}`);
      return;
    }

    this.markeerSucceservaringTypeAlsGelezen(event.type).subscribe();

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait a bit to make it not appear immediately, better ux and ensure new page and its headers are loaded.

    const visualization = succeservaringVisualizations[event.type];

    let headerHeight = 0;

    document.querySelectorAll('.ion-page:not(.ion-page-hidden) > ion-header').forEach((header) => {
      if (header instanceof HTMLElement && header.offsetHeight > headerHeight) {
        headerHeight = header.offsetHeight;
      }
    }); // On mobile there is always an empty header on the page?

    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

    const toast = await this.toastController.create({
      cssClass: 'ion-toast--succeservaring',
      header: `${visualization.icon} ${visualization.titel}`,
      message: visualization.beschrijving,
      duration: 7000,
      position: 'top',
      positionAnchor: 'toast-anchor',
      swipeGesture: 'vertical',
    });

    toast.present();

    this.retrieveSucceservaringen().subscribe();

    this.analyticsService.trackEvent(
      new AnalyticsEvent('succeservaringen', 'succeservaringGetoond', event.type),
    );

    if (isNativeApp()) {
      await Haptics.notification({ type: NotificationType.Success });
    }
  };
}
