<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title
      aria-level="1"
      role="heading"
      *parkourShowIfSuccessful="mainPageData$ | async; let data"
      >{{
        data.context.type === 'jongere'
          ? ('kalender.kalender-afspraak-add-page.title-basic' | translate)
          : ('kalender.kalender-afspraak-add-page.title-videogesprek' | translate)
      }}
    </ion-title>

    <ion-buttons slot="end">
      <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
        <parkour-header-action
          [label]="
            data.context.type === 'jongere'
              ? ('kalender.kalender-afspraak-add-page.form.submit-basic' | translate)
              : ('kalender.kalender-afspraak-add-page.form.submit-videogesprek' | translate)
          "
          form="afspraak-add-form"
          [icon]="data.context.type === 'jongere' ? 'disk' : 'paper-plane'"
          type="submit"
        />
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="content">
  <parkour-page-layout>
    <parkour-page-gradient />
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <div class="relative z-10 py-8 container container-space">
        <parkour-afspraak-form
          mode="add"
          [submitAfspraakForm]="submitAfspraakForm"
          #addAfspraakForm
          [isJongere]="data.context.type === 'jongere'"
          [mijnTeamlidContexten]="data.mijnTeamlidContexten"
          [profielId]="data.user.profielId"
          class="block container-1-col"
          formId="afspraak-add-form"
        />
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
