import { Routes } from '@angular/router';
import { ToegankelijkheidsVerklaringPage } from './page/toegankelijkheids-verklaring-page/toegankelijkheids-verklaring.page';
import { GebruiksvoorwaardenPage } from './page/gebruiksvoorwaarden-page/gebruiksvoorwaarden.page';
import { PrivacyVerklaringPage } from './page/privacy-verklaring-page/privacy-verklaring.page';
import { OverParkourPage } from './page/over-parkour-page/over-parkour.page';
import { AanDeSlagPage } from './page/aan-de-slag-page/aan-de-slag.page';
import { CookieBeleidPageComponent } from './page/cookie-beleid-page/cookie-beleid.page';
import { WaaromParkourPage } from './page/waarom-parkour-page/waarom-parkour.page';
import { VeelgesteldeVragenPage } from './page/veelgestelde-vragen-page/veelgestelde-vragen.page';

export const aboutParkourRoutes: Routes = [
  {
    path: 'voorwaarden',
    title: 'over-parkour.gebruiksvoorwaarden-page.title',
    component: GebruiksvoorwaardenPage,
  },
  {
    path: 'privacy',
    title: 'over-parkour.privacy-verklaring-page.title',
    component: PrivacyVerklaringPage,
  },
  {
    path: 'toegankelijkheid',
    title: 'over-parkour.toegankelijkheids-verklaring-page.title',
    component: ToegankelijkheidsVerklaringPage,
  },
  {
    path: 'parkour',
    title: 'over-parkour.title',
    component: OverParkourPage,
  },
  {
    path: 'aan-de-slag',
    title: 'over-parkour.aan-de-slag-page.title',
    component: AanDeSlagPage,
  },
  {
    path: 'cookiebeleid',
    title: 'over-parkour.cookie-beleid-page.title',
    component: CookieBeleidPageComponent,
  },
  {
    path: 'waarom-parkour',
    title: 'over-parkour.waarom-parkour-page.title',
    component: WaaromParkourPage,
  },
  {
    path: 'veelgestelde-vragen',
    title: 'over-parkour.veelgestelde-vragen-page.title',
    component: VeelgesteldeVragenPage,
  },
];
