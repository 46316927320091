import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Avatar, avatars } from 'parkour-web-app-dto';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProfielFotoService } from '../../service/profiel-foto.service';
import { PermissionService } from '../../../shared/services/permission.service';
import { isNativeApp } from '../../../utils';
import { isFileValid } from '../../../shared/file-validation';
import { HumanReadableError } from '../../../core/human-readable-error';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ParkourAvatarComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { ProfielAvatarSelectComponent } from '../profiel-avatar-select/profiel-avatar-select.component';
import { SecureImagePipe } from '../../../shared/pipes/secure-image.pipe';
import { LoggingService } from 'src/app/core/logging.service';

@Component({
  standalone: true,
  selector: 'parkour-profiel-details-foto-modal',
  templateUrl: './profiel-details-foto-modal.component.html',
  imports: [
    FormsModule,
    ParkourAvatarComponent,
    AsyncPipe,
    TranslateModule,
    ImageCropperComponent,
    ParkourButtonComponent,
    ProfielAvatarSelectComponent,
    ReactiveFormsModule,
    SecureImagePipe,
    ParkourLoadingSpinnerComponent,
  ],
})
export class ProfielDetailsFotoModalComponent implements OnChanges {
  @Input({ required: true }) initialAvatar!: Avatar;
  @Input({ required: true }) initialProfielFoto: string | undefined;
  @Input({ required: true }) initialProfielFotoFile: File | undefined;

  @Output() fotoDetailsSaved = new EventEmitter<{
    avatar: Avatar | undefined;
    profielFoto: string | undefined;
    profielFotoFile: File | undefined;
  }>();

  fotoDetailsFormgroup!: FormGroup<{
    avatar: FormControl<Avatar | undefined>;
    profielFoto: FormControl<string | undefined>;
    profielFotoFile: FormControl<File | undefined>;
  }>;

  protected readonly avatars = avatars;

  imageLoading = false;
  isImageCropperHidden = true;
  imageToCrop: string | undefined = '';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly profielFotoService: ProfielFotoService,
    private readonly permissionService: PermissionService,
    private readonly loggingService: LoggingService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges() {
    this.fotoDetailsFormgroup = this.formBuilder.nonNullable.group({
      avatar: this.formBuilder.nonNullable.control<Avatar | undefined>(
        this.initialProfielFoto ? undefined : this.initialAvatar,
      ),
      profielFoto: this.formBuilder.nonNullable.control(this.initialProfielFoto),
      profielFotoFile: this.formBuilder.nonNullable.control(this.initialProfielFotoFile),
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;
    if (!blob || !event.objectUrl) {
      throw new Error('Image could not be cropped');
    }

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      this.fotoDetailsFormgroup.patchValue({
        avatar: undefined,
        profielFoto: base64data as string,
        profielFotoFile: new File([blob], 'profielfoto', { type: blob.type }),
      });
    };
  }

  getProfielFoto() {
    return this.fotoDetailsFormgroup.value.profielFoto
      ? this.fotoDetailsFormgroup.value.profielFoto
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.profielFotoService.getAvatarUrl(this.fotoDetailsFormgroup.getRawValue().avatar!);
  }

  async handleClickTakeSelfie() {
    await this.permissionService.withPermissions(
      Camera,
      'camera',
      async () => {
        try {
          const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera,
            quality: 100,
          });

          this.imageToCrop = capturedPhoto.base64String;
          this.imageLoading = true;
        } catch (e) {
          this.loggingService.log('Closed phone camera on error', e);
        }
      },
      {
        errorTitle: 'PARKOUR wil je camera gebruiken',
        errorDescription: 'Geef toestemming voor toegang tot je camera in je instellingen.',
      },
    );
  }

  async handleClickOpenImageLibrary() {
    await this.permissionService.withPermissions(
      Camera,
      'photos',
      async () => {
        try {
          const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            source: CameraSource.Photos,
            quality: 100,
          });
          this.imageToCrop = capturedPhoto.base64String;
          this.imageLoading = true;
        } catch (e) {
          this.loggingService.log('Closed phone camera', e);
        }
      },
      {
        errorTitle: 'PARKOUR wil je fotobibliotheek gebruiken',
        errorDescription:
          'Geef toestemming voor toegang tot je fotobibliotheek in je instellingen.',
      },
    );
  }

  imageLoaded() {
    this.isImageCropperHidden = false;
    this.imageLoading = false;
  }

  loadImageFailed() {
    this.isImageCropperHidden = true;
    this.imageLoading = false;

    this.revertFotoInForm();
    this.translateService.get('file.load-image-failed').subscribe((label) => {
      throw new HumanReadableError(label);
    });
  }

  onAvatarChange(avatar: Avatar | undefined) {
    if (avatar) {
      this.imageToCrop = '';
      this.isImageCropperHidden = true;
      this.fotoDetailsFormgroup.patchValue({
        profielFoto: undefined,
        profielFotoFile: undefined,
      });
    }
  }

  onFotoSubmit() {
    this.imageToCrop = '';
    this.isImageCropperHidden = true;

    if (this.fotoDetailsFormgroup.value.profielFotoFile) {
      if (!isFileValid(this.fotoDetailsFormgroup.value.profielFotoFile)) {
        this.revertFotoInForm();
        this.translateService.get('file.image-too-large').subscribe((label) => {
          throw new HumanReadableError(label);
        });
      }
    }

    this.fotoDetailsSaved.emit({
      avatar: this.fotoDetailsFormgroup.value.avatar,
      profielFoto: this.fotoDetailsFormgroup.value.profielFoto,
      profielFotoFile: this.fotoDetailsFormgroup.value.profielFotoFile,
    });
  }

  private revertFotoInForm() {
    this.fotoDetailsFormgroup.patchValue({
      avatar: this.initialProfielFoto ? undefined : this.initialAvatar,
      profielFoto: this.initialProfielFoto,
      profielFotoFile: this.initialProfielFotoFile,
    });
    this.imageToCrop = '';
    this.isImageCropperHidden = true;
  }

  protected readonly isNativeApp = isNativeApp;
}
