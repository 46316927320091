import { Component, Input } from '@angular/core';
import { TAccordionItems } from './parkour-accordion.types';
import { NgForOf } from '@angular/common';
import { IonAccordion, IonAccordionGroup } from '@ionic/angular/standalone';
import { ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { ParkourVideoContentComponent } from '../parkour-video-content/parkour-video-content.component';

@Component({
  standalone: true,
  selector: 'parkour-accordion',
  styleUrls: ['./parkour-accordion.component.css'],
  templateUrl: './parkour-accordion.component.html',
  imports: [
    NgForOf,
    ParkourIconComponent,
    IonAccordion,
    IonAccordionGroup,
    ParkourImageComponent,
    ParkourVideoContentComponent,
  ],
})
export class ParkourAccordionComponent {
  @Input({ required: true }) items: TAccordionItems = [];
}
