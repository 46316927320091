@if (mijnProfiel() && !mijnProfiel()?.contactMethodeGeverifieerd) {
  <parkour-info-card-with-title
    [title]="titel()"
    infoCardStyle="bg-s-100"
    class="block mb-4"
    [ngClass]="{
      'mt-4': includeTopMargin(),
    }"
  >
    <p>
      We stuurden een code naar <span class="font-medium">{{ contactMethodeString() }}</span>
    </p>
    <a class="underline cursor-pointer font-medium" [routerLink]="['contact-methode-bevestigen']">
      @switch (contactMethode()) {
        @case ('email') {
          E-mailadres bevestigen
        }
        @case ('gsm-nummer') {
          Gsm-nummer bevestigen
        }
      }
    </a>
  </parkour-info-card-with-title>
}
