import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DoelSuggestie } from '../../../../suggestie/model/suggestie';
import { ProfielFotoBadgeComponent } from '../../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { ParkourButtonComponent, ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../../../shared/pipes/profielnaam.pipe';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ProfielPipe } from '../../../../shared/pipes/profiel.pipe';
import { SuggestieImagePipe } from '../../../../verhaal/verhaal-shared/pipes/suggestie-image.pipe';
import { themaMapping } from '../../../../home/model/thema.const';
import { DoelProgresComponent } from '../doel-progress/doel-progres.component';

@Component({
  standalone: true,
  selector: 'parkour-doel-voorstel',
  templateUrl: './doel-voorstel.component.html',
  imports: [
    ProfielFotoBadgeComponent,
    ParkourButtonComponent,
    TranslateModule,
    ProfielnaamPipe,
    DatePipe,
    AsyncPipe,
    ParkourIconComponent,
    ParkourImageComponent,
    ProfielPipe,
    SuggestieImagePipe,
    DoelProgresComponent,
  ],
})
export class DoelVoorstelComponent {
  @Input({ required: true }) doelSuggestie!: DoelSuggestie;
  @Input() canConfirm = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() reject = new EventEmitter<void>();
  protected readonly themaMapping = themaMapping;

  onYes() {
    this.confirm.emit();
  }

  onNo() {
    this.reject.emit();
  }
}
