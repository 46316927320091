<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'verhaal.eyouth-gebeurtenis-edit-page.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        *parkourShowIfSuccessful="mainPageData$ | async"
        [label]="'verhaal.eyouth-gebeurtenis-edit-page.gebeurtenis-save' | translate"
        form="gebeurtenis-form"
        icon="disk"
        testId="save-gebeurtenis"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-page-image
        classes="absolute top-0 left-0 z-0"
        src="/assets/verhaal/background/background_verhaal_blur.jpg"
      />
      <parkour-page-with-hero [heroImage]="verhaalHeroSources" [title]="data.gebeurtenis.titel">
        <div class="flex flex-col items-center" hero-above-title>
          <div class="flex items-center gap-x-2 mb-4 bg-n-200 rounded p-2">
            <parkour-image
              alt="Vlaamse Overheid"
              class="block w-[1.75rem] h-[1.75rem] bg-n-1000 p-1 rounded-sm"
              fit="contain"
              src="/assets/header/vlaamse-overheid-logo.svg"
            />

            <span class="typo-body variant-body-small variant-body-fat"> Opgroeien </span>
          </div>
          <p class="typo-body variant-body-fat">
            {{ 'verhaal.detail-page.sub-titel-jeugdhulphistoriek' | translate }}
          </p>
        </div>
        <div class="container-1-col mt-4">
          <div class="mb-8">
            @if (data.context.type === 'jongere') {
              <parkour-info-card>
                {{ 'verhaal.detail-page.info-jongere-edit' | translate }}
              </parkour-info-card>
            }
          </div>

          <parkour-jeugdhulp-historiek-form
            [initialState]="{
              gedeeldMet: data.gebeurtenis.gedeeldMet,
              deelMode: data.gebeurtenis.deelMode,
            }"
            [teamleden]="data.teamleden"
            [submitJeugdhulpHistoriekForm]="getSubmitGeneratorForId(data.gebeurtenis.id)"
            formId="gebeurtenis-form"
          />
        </div>
      </parkour-page-with-hero>
    </ng-container>
  </parkour-page-layout>
</ion-content>
