<div
        class="flex items-baseline justify-between gap-2 p-4 rounded {{ style }} {{
    classes
  }}"
        [ngClass]="{
    'text-n-100': style === 'bg-s-100',
  }"
>
    <parkour-icon
            [ngClass]="{
    'text-n-600': style === 'bg-n-300' || style === 'bg-n-200',
    }"
            [name]="icon" size="3"/>

    <div class="flex-1">
        <ng-content></ng-content>
    </div>
</div>
