<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.wissel-team-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-page-image
        [src]="data.profiel | profielFoto: 'full' | async"
        alt=""
        classes="absolute top-0 left-0 z-0"
      />

      <div class="container relative z-10 pt-8 pb-8 container-space">
        <div class="flex flex-col items-center gap-y-4 container-1-col">
          <parkour-profiel-foto [profiel]="data.profiel" [size]="'5.5'" />

          <p class="typo-h3">{{ data.profiel | profielnaam }}</p>

          <parkour-profiel-select
            (createPersoonlijkProfiel)="onCreatePersoonlijkProfiel()"
            (createProfessioneelProfiel)="createProfessioneelProfiel()"
            (profielSelect)="onProfielSelect($event)"
            [currentProfiel]="data.profiel"
            [profielCreateOptions]="data.profielCreateOptions"
            [profielen]="data.mijnProfielen"
          />
        </div>

        <section
          *ngIf="
            data.huidigeUitnodiging ||
            (data.verstuurdeUitnodigingenVoorMij &&
              data.verstuurdeUitnodigingenVoorMij.length > 0) ||
            (data.hangendeUitnodigingenVoorMij && data.hangendeUitnodigingenVoorMij.length > 0)
          "
          class="mt-8 container-1-col"
        >
          <h2 class="typo-h3">{{ 'team.teams-overview-page.invites' | translate }}</h2>
          @for (uitnodiging of data.verstuurdeUitnodigingenVoorMij; track uitnodiging.id) {
            <parkour-uitnodiging-confirmation
              (bevestigUitnodiging)="onJaClick(uitnodiging.id)"
              (verwijderUitnodiging)="verwijderUitnodinging(uitnodiging.id)"
              [uitnodiging]="uitnodiging"
            />
          }

          @if (data.huidigeUitnodiging) {
            <parkour-uitnodiging-confirmation
              (bevestigUitnodiging)="onJaClick(data.huidigeUitnodiging.id)"
              (verwijderUitnodiging)="verwijderUitnodinging(data.huidigeUitnodiging.id)"
              [uitnodiging]="data.huidigeUitnodiging"
            />
          }

          <div
            *ngIf="
              data.hangendeUitnodigingenVoorMij && data.hangendeUitnodigingenVoorMij.length > 0
            "
            class="bg-n-100 mt-4 rounded overflow-hidden border-[1.5px] border-n-500 divide-y-[1.5px] divide-n-500"
          >
            <div
              *ngFor="let hangendeUitnodiging of data.hangendeUitnodigingenVoorMij"
              class="flex items-center justify-between p-4 w-full bg-n-100 rounded"
            >
              <parkour-team-switch-card-content
                [naam]="hangendeUitnodiging.initiator"
                [profiel]="{ status: 'ONBEKEND' }"
                [rol]="hangendeUitnodiging.rol"
                [showNewIndicator]="false"
              >
                <parkour-tag iconBefore="loader" variant="light-blue">
                  {{ 'team.teams-overview-page.awaiting-activation' | translate }}
                </parkour-tag>
              </parkour-team-switch-card-content>
            </div>
          </div>
        </section>

        <section class="mt-8 container-1-col">
          <h2 class="typo-h3">{{ 'team.teams-overview-page.current-teams' | translate }}</h2>

          <ng-container *parkourPageContent="mainPageData$ | async; let data">
            <ng-container *ngIf="data.contexts && data.currentContext">
              <parkour-profiel-team-switch
                (contextSelected)="contextChanged($event, data.profiel)"
                [context]="data.currentContext.contextId"
                [contexten]="data.contexts"
                [isJongere]="data.profiel.type === 'JONGERE'"
                [profielId]="data.profiel.id"
                class="block mt-4"
              />
            </ng-container>
          </ng-container>
        </section>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
