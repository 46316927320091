import {
  ActieveJongereDto,
  MijnProfielDto,
  ProfielDto,
  ProfielFotoId,
  ProfielId,
  ProfielNaamType,
  ProfielType,
} from 'parkour-web-app-dto';

export type ActieveJongereProfiel = ActieveJongereDto;
export type GeblokkeerdWeergaveJongereProfiel = {
  readonly viewType: 'JONGERE';
  readonly status: 'GEBLOKKEERDE_WEERGAVE';
  readonly id: ProfielId;
  readonly voornaam: string;
  readonly naam: string;
};

export type InactieveWeergaveJongereProfiel = {
  readonly viewType: 'JONGERE';
  readonly status: 'INACTIEVE_WEERGAVE';
  readonly id: ProfielId;
  readonly voornaam: string;
  readonly naam: string;
};

export type UnsupportedJongereProfiel = {
  readonly viewType: 'JONGERE';
  readonly status: 'UNSUPPORTED';
  readonly id: ProfielId;
  readonly voornaam?: string;
  readonly naam?: string;
};

export type UitgenodigdWeergaveJongereProfiel = {
  readonly viewType: 'JONGERE';
  readonly status: 'UITGENODIGDE_WEERGAVE';
  readonly id: ProfielId;
  readonly voornaam: string;
  readonly naam: string;
  readonly profielnaamType: ProfielNaamType;
  readonly type: ProfielType;
  readonly hasProfielFoto: boolean;
  readonly profielFotoId: ProfielFotoId;
  readonly roepnaam?: string;
};

export type JongereProfiel =
  | ActieveJongereProfiel
  | GeblokkeerdWeergaveJongereProfiel
  | UitgenodigdWeergaveJongereProfiel
  | InactieveWeergaveJongereProfiel
  | UnsupportedJongereProfiel;

export type Profiel = ProfielDto;
export type PrivateInfo = {
  readonly email?: string;
  readonly telefoonnummer?: string;
};
export type MijnProfiel = MijnProfielDto;

export function getContactEmailOrTelefoonnummer(mijnProfiel: MijnProfiel): string {
  return mijnProfiel.email ?? mijnProfiel.telefoonnummer ?? '';
}

export type ContactMethodeType = 'email' | 'gsm-nummer';

export function getContactMethodeType(mijnProfiel: MijnProfiel): ContactMethodeType {
  return mijnProfiel.email ? 'email' : 'gsm-nummer';
}
