import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, ViewEncapsulation } from '@angular/core';
import { TOnboardingSlides } from './onboarding.types';
import { ParkourCarouselModule, ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-onboarding',
  templateUrl: './onboarding.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ParkourImageComponent,
    NgForOf,
    ParkourCarouselModule,
    ParkourIconComponent,
    TranslateModule,
  ],
})
export class OnboardingComponent {
  @Input({ required: true }) slides!: TOnboardingSlides;
}
