<article
	[ngClass]="{
	'bg-n-150': theme === 'n-150',
    'bg-n-200': theme === 'n-200',
    'bg-n-300': theme === 'n-300',
    'bg-n-500': theme === 'n-500',
    'bg-highlight': theme === 'highlight',
    'clickable-card-200': clickable,
  }"
	class="p-4 rounded bg-n-300"
>
	<div
		[ngClass]="{
      'items-start': aligned === 'start',
      'items-center': aligned === 'center',
    }"
		class="flex w-full gap-4"
	>
		@if (showIndicator || icon || image) {
			<div class="relative">
				<parkour-new-indicator [show]="showIndicator" />

				<parkour-badge
					*ngIf="icon && !image"
					[iconSize]="iconSize"
					[theme]="iconTheme ?? 'n-400'"
					[icon]="icon"
					class="flex"
				/>

				<parkour-image
					*ngIf="image && !icon"
					[src]="image"
					class="block w-12 overflow-hidden rounded"
				/>
			</div>
		}

		<div class="flex-1">
			<ng-content></ng-content>
		</div>
	</div>

	<div class="mt-4 empty:hidden">
		<ng-content select="[footer]"></ng-content>
	</div>
</article>
