import { Routes } from '@angular/router';
import {
  actiefAlsExJongeMatcher,
  actiefAlsJongereMatcher,
  actiefAlsTeamlidMatcher,
  actiefVoorMezelfMatcher,
} from '../core/matchers';
import { verhaalTeamlidRoutes } from './verhaal-teamlid/routes';
import { verhaalJongereRoutes } from './verhaal-jongere/routes';
import { VerhaalNoTeamPage } from './verhaal-shared/page/verhaal-no-team-page/verhaal-no-team.page';
import { VerhaalNietAangemeldPage } from './verhaal-shared/page/verhaal-niet-aangemeld-page/verhaal-niet-aangemeld.page';
import { verhaalExJongereRoutes } from './verhaal-ex-jongere/routes';

export const verhaalRoutes: Routes = [
  {
    path: '',
    canMatch: [actiefAlsTeamlidMatcher],
    children: verhaalTeamlidRoutes,
  },
  {
    path: '',
    canMatch: [actiefAlsJongereMatcher],
    children: verhaalJongereRoutes,
  },
  {
    path: '',
    canMatch: [actiefAlsExJongeMatcher],
    children: verhaalExJongereRoutes,
  },
  { path: '', canMatch: [actiefVoorMezelfMatcher], component: VerhaalNoTeamPage },
  { path: '', component: VerhaalNietAangemeldPage },
];
