import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  switchMap,
  take,
  timeout,
} from 'rxjs';
import { AnoniemeContextDto, ContextId, ProfielId } from 'parkour-web-app-dto';
import { Context, ExJongereContext, JongereContext, TeamlidContext } from '../model/context';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OnSameUrlNavigation, Router } from '@angular/router';
import { ParkourToastService } from '@parkour/ui';
import { LoggingService } from '../../core/logging.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent } from '../../analytics/analytics-event.model';

export type ContextUrlQueryParams = {
  [key: string]: boolean;
};

export type ContextUrl = {
  type: 'context-url';
  path: string[];
  queryParams?: ContextUrlQueryParams;
};

export type GlobalUrl = {
  type: 'global-url';
  url: string;
};

export type ContextType = 'jongere' | 'teamlid' | 'anoniem' | 'voor-mezelf' | 'ex-jongere';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private lastContextId?: ContextId;
  private readonly contextSubject$ = new ReplaySubject<Context>(1);
  public readonly context$: Observable<Context> = this.contextSubject$;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly toastService: ParkourToastService,
    private readonly loggingService: LoggingService,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.context$.subscribe((context) => {
      this.loggingService.log('Current context:', context);
    });

    this.context$.pipe(distinctUntilChanged()).subscribe((context) => {
      this.analyticsService.trackEvent(new AnalyticsEvent('team', 'contextType', context.type));
    });

    this.context$
      .pipe(
        map((context) => context.type),
        distinctUntilChanged(),
      )
      .subscribe((contextType) => this.analyticsService.setContextType(contextType));
  }

  public canAccessContextId(contextId: ContextId): Observable<boolean> {
    if (contextId === 'anoniem') {
      return of(true);
    }
    return this.httpClient.get<boolean>(
      `${environment.API_BASE_URL}/api/context/${contextId}/can-access`,
    );
  }

  public isContextIdDifferent(contextId: ContextId): boolean {
    return this.lastContextId !== contextId;
  }

  public retrieveContextById(contextId: ContextId): Observable<Context | undefined> {
    this.loggingService.log('Retrieving context', contextId);
    if (!this.isContextIdDifferent(contextId)) {
      return this.context$;
    } else {
      return this.canAccessContextId(contextId).pipe(
        switchMap((canAccess) => {
          if (canAccess) {
            return this.retrieveContext(contextId).pipe(
              map((context) => {
                this.lastContextId = contextId;
                this.contextSubject$.next(context);
                return context;
              }),
            );
          } else {
            return of(undefined);
          }
        }),
      );
    }
  }

  public getUrlInContext(
    contextId: ContextId,
    urlSegments: string[],
    queryParams?: ContextUrlQueryParams,
  ): ContextUrl {
    return {
      type: 'context-url',
      path: ['app', contextId, ...urlSegments],
      queryParams,
    };
  }

  public teamlidContext$(): Observable<TeamlidContext> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid') {
          return context;
        }

        throw new Error('Must be working in context of a teamlid to access this data');
      }),
      take(1),
      shareReplay(1),
    );
  }

  public contextIdOfJongere$(): Observable<ProfielId> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid') {
          return context.jongereProfiel.id;
        }

        if (context.type === 'jongere') {
          return context.contextId;
        }

        if (context.type === 'ex-jongere') {
          return context.contextId;
        }

        throw new Error('Must be working in context of a teamlid to access this data');
      }),
      take(1),
    );
  }

  public contextWithActiveJongere$(): Observable<JongereContext | TeamlidContext> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid' || context.type === 'jongere') {
          return context;
        }

        throw new Error('Must be working in context of a jongere to access this data');
      }),
      take(1),
    );
  }

  public contextWithJongere$(): Observable<JongereContext | TeamlidContext | ExJongereContext> {
    return this.context$.pipe(
      map((context) => {
        if (
          context.type === 'teamlid' ||
          context.type === 'jongere' ||
          context.type === 'ex-jongere'
        ) {
          return context;
        }

        throw new Error('Must be working in context of a jongere to access this data');
      }),
      take(1),
    );
  }

  async navigateToAbsoluteUrl(
    segments: string[],
    onSameUrlNavigation?: OnSameUrlNavigation,
  ): Promise<boolean> {
    return this.router.navigate(await firstValueFrom(this.getAbsoluteUrl(segments)), {
      onSameUrlNavigation,
      skipLocationChange: false,
    });
  }

  getAbsoluteUrl(segments: string[]): Observable<string[]> {
    return this.context$.pipe(
      take(1),
      map((context) => context.contextId),
      timeout(2000),
      catchError(() => of('me')),
      map((contextId) => ['/app', contextId, ...segments]),
    );
  }

  async switchContext(contextId: ContextId, type: ContextType, url?: string[]): Promise<boolean> {
    this.analyticsService.trackEvent(new AnalyticsEvent('team', 'wisselTeam', type));
    if (url) {
      const absoluteSegments = ['app', contextId, ...url];
      // history.pushState(null, '', '/app/block-back-navigation');
      return this.router.navigate(absoluteSegments);
    } else {
      const segments = this.router.url.split('/');
      segments[2] = contextId;
      const sameUrl = segments.join('/');
      // history.pushState(null, '', '/app/block-back-navigation');

      return this.router.navigateByUrl(sameUrl);
    }
  }

  private retrieveContext(contextId: ContextId): Observable<Context> {
    // If the context is anoniem, we don't need to make a request to the backend so parkour can be used offline
    if (contextId === 'anoniem') {
      return of<AnoniemeContextDto>({
        contextId: 'anoniem',
        type: 'anoniem',
      });
    }

    return this.httpClient
      .get<Context>(`${environment.API_BASE_URL}/api/context/${contextId}`)
      .pipe(
        catchError(() => {
          this.toastService.showToast({
            header: 'Oeps…',
            content: 'Er ging iets mis tijdens het wisselen naar een team.',
            error: true,
          });
          return from(this.switchContext('anoniem', 'anoniem', ['home'])).pipe(
            switchMap(() => NEVER),
            catchError(() => NEVER),
          );
        }),
      );
  }

  public isContextId(value: string): value is ContextId {
    const regex = /^[a-z,0-9,-]{36,36}$/;

    return value === 'anoniem' || regex.test(value);
  }
}
