import { Component } from '@angular/core';
import { map } from 'rxjs';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import {
  ParkourAnimationModalComponent,
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { DoelDetailsComponent } from '../../doel-shared/component/doel-details/doel-details.component';
import { ReactieOverviewComponent } from '../../doel-shared/component/reactie-overview/reactie-overview.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { DoelBerichtenComponent } from '../../../bericht/component/doel-berichten/doel-berichten.component';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import { DoelDetailPage } from '../../doel-shared/page/doel-detail/doel-detail-page';
import { CustomPageTitle } from '../../../shared/custom-page-title';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { SuggestieService } from '../../../suggestie/service/suggestie.service';
import { meldingenForPage } from '../../../meldingen/config';
import { Actie, Doel } from '../../doel-shared/model/doel';
import { validateDoelId } from '../../utils';
import { SuggestieId } from 'parkour-web-app-dto';
import { environment } from '../../../../environments/environment';

@Component({
  standalone: true,
  templateUrl: './doel-details-jongere.page.html',
  imports: [
    ParkourHeaderActionComponent,
    TranslateModule,
    ParkourBackButtonDirective,
    AsyncPipe,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    ParkourButtonComponent,
    ParkourAnimationModalComponent,
    ReactieOverviewComponent,
    PageLayoutComponent,
    PageContentDirective,
    PageSectionDirective,
    DoelBerichtenComponent,
    ShowIfSuccesfulDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    ParkourIconComponent,
    ArtikelCardComponent,
  ],
})
export class DoelDetailsJongerePage
  extends DoelDetailPage
  implements CustomPageTitle, MarksMeldingenAsReadOnView
{
  doelCompleteAnimationOpen = false;

  constructor(private readonly suggestieService: SuggestieService) {
    super();
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle((data) => data.doel.naam, 'doelen.doel-detail-page.title');
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (data) =>
          new MeldingenToMarkAsRead(
            meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.specificMeldingen,
            (melding) => data.success && melding.metaData.doelId === data.value.doel.id,
          ),
      ),
    );
  }

  updateActies({ doel, acties }: { doel: Doel; acties: Actie[] }) {
    this.doelenService
      .updateDoel(validateDoelId(doel.id), {
        ...doel,
        acties,
      })
      .subscribe(() => this.refreshMainData());
  }

  completeDoel(doel: Doel) {
    this.doelenService.completeDoel(doel.id).subscribe(() => {
      this.doelCompleteAnimationOpen = true;
      this.refreshMainData();
    });
  }

  ignoreGebeurtenisSuggestie(suggestieId: SuggestieId) {
    this.suggestieService
      .updateGebeurtenisSuggestieStatus(suggestieId, 'AFGEWEZEN')
      .subscribe(() => this.refreshMainData());
  }

  protected readonly String = String;
  protected readonly environment = environment;
}
