import { Injectable } from '@angular/core';
import { CmsPaginaDto } from 'parkour-web-app-dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private readonly http: HttpClient) {}

  getCmsPagina(): Observable<CmsPaginaDto> {
    return this.http.get<CmsPaginaDto>(`${environment.API_BASE_URL}/api/faq`);
  }
}
