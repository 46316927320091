<ion-header class="full-header"> </ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout class="h-full">
    <ng-container
      *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let mainPageData"
    >
      <div class="flex flex-col py-8 h-[100%]">
        <div class="mb-4 container container-space">
          <div class="container-1-col">
            <h1 class="text-center">Mijn profiel</h1>
            <parkour-divider class="inline-block w-full mt-4" />
          </div>
        </div>

        <div class="flex-1 grid grid-cols-[auto] grid-rows-[1fr_10%_auto]">
          <div class="relative z-0 col-start-1 col-end-2 row-start-1 row-end-4">
            <parkour-picture
              [rounded]="true"
              [sources]="{
                default: {
                  src: '/assets/onboarding/background/default/background_onboarding_default.webp',
                  fallback:
                    '/assets/onboarding/background/default/background_onboarding_default.png',
                },
                md: {
                  src: '/assets/onboarding/background/md/background_onboarding_md.webp',
                  fallback: '/assets/onboarding/background/md/background_onboarding_md.png',
                },
              }"
              alt=""
              class="absolute h-full w-[110%] left-[-5%] blur-[29px]"
              fetchPriority="high"
              fit="contain"
              position="center"
            />

            <parkour-picture
              [rounded]="true"
              [sources]="{
                default: {
                  src: '/assets/onboarding/background/default/background_onboarding_default.webp',
                  fallback:
                    '/assets/onboarding/background/default/background_onboarding_default.jpg',
                },
                md: {
                  src: '/assets/onboarding/background/md/background_onboarding_md.webp',
                  fallback: '/assets/onboarding/background/md/background_onboarding_md.jpg',
                },
              }"
              alt=""
              class="absolute h-full w-[110%] left-[-5%]"
              fetchPriority="high"
              fit="contain"
              position="center"
            />
          </div>

          <div
            class="z-10 col-start-1 col-end-2 row-start-2 row-end-4 bg-gradient-to-b from-transparent to-n-100 to-80%"
            role="presentation"
          ></div>

          <div class="z-10 col-start-1 col-end-2 row-start-3 row-end-4 container container-space">
            <div class="container-1-col">
              <p class="text-center typo-h2">
                Hallo {{ mainPageData.user.voornaam }}, <br />
                je hebt nog geen profiel
              </p>

              <p class="text-center max-w-[32ch] mx-auto mt-3">
                Begin met het maken van je eigen reis en sta klaar voor anderen.
              </p>
            </div>
          </div>
        </div>

        <div class="z-10 pt-6 container container-space bg-n-100">
          <div class="container-1-col">
            @if (
              mainPageData.user.hoedanigheid === 'BEGELEIDER_PERSONEELSLID' &&
              mainPageData.createOptions.includes('PROFESSIONEEL')
            ) {
              <parkour-button (clicked)="onProfessioneelProfielClick()" class="block w-full">
                Professioneel profiel maken
              </parkour-button>

              <parkour-button
                (clicked)="onPersoonlijkProfielClick()"
                class="block w-full mt-3"
                variant="tertiary"
              >
                Persoonlijk profiel maken
              </parkour-button>
            } @else {
              <parkour-button (clicked)="onPersoonlijkProfielClick()" class="block w-full">
                Profiel maken
              </parkour-button>
            }
          </div>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
