import { Injectable } from '@angular/core';
import {
  AfspraakAangemaaktMelding,
  AfspraakGestartMelding,
  AfspraakHerinneringMelding,
  AfspraakVerwijderdMelding,
  DoelBerichtAddedMelding,
  DoelBerichtReactieAddedMelding,
  DoelEmojiReactieAddedMelding,
  DoelGedeeldMelding,
  DoelGesuggereerdMelding,
  DoelVoltooidMelding,
  GebeurtenisGedeeldMelding,
  GebeurtenisGesuggereerdMelding,
  GeblokkeerdMelding,
  GedeblokkeerdMelding,
  JeugdhulpHistoriekGedeeldMelding,
  MeldingDto,
  MeldingOfType,
  MeldingType,
  NieuwChatBerichtMelding,
  NieuwChatReactieMelding,
  ProfielId,
  ProfielWordtVerwijderdHerinneringMelding,
  TeamVerlatenMelding,
  UitgenodigdMelding,
  UitnodigingAanvaardMelding,
  UitnodigingBevestigdMelding,
  UitnodigingBevestigingGeweigerd,
  UitnodigingGeweigerdMelding,
  VerwijderdUitTeamDoorJongere25,
  VerwijderdUitTeamMelding,
  VideogesprekGestartMelding,
} from 'parkour-web-app-dto';
import { catchError, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import {
  isSupportedMelding,
  Melding,
  MeldingView,
  MeldingVisualization,
  SupportedMeldingView,
  UnsupportedMeldingView,
} from '../model/meldingen';
import { asType } from '../../utils';
import { DatePipe } from '@angular/common';
import { TeamService } from '../../team/service/team.service';
import { getProfielnaam, ProfielInTeam } from '../model/profiel-in-team';
import { MeldingLinkService } from './melding-link.service';
import { getParkourEmoji } from '../../shared/pipes/parkourEmoji.pipe';
import { JongereProfiel } from '../model/profiel';
import { LoggingService } from '../../core/logging.service';

type MeldingVisualizationFunction<T extends MeldingType> = (
  melding: MeldingOfType<T>,
) => Observable<MeldingVisualization>;

@Injectable({
  providedIn: 'root',
})
export class MeldingVisualizationService {
  constructor(
    private readonly teamService: TeamService,
    private readonly datePipe: DatePipe,
    private readonly meldingLinkService: MeldingLinkService,
    private readonly loggingService: LoggingService,
  ) {}

  public transform(melding: Melding): Observable<MeldingView> {
    if (!isSupportedMelding(melding)) {
      return of(
        asType<UnsupportedMeldingView>({
          type: 'unsupported',
          id: melding.id,
          timestamp: melding.tijdstip,
        }),
      );
    }

    return forkJoin([
      this.meldingLinkService.getMeldingLink(melding),
      this.getVisualization(melding),
    ]).pipe(
      map(([link, visualization]) =>
        asType<SupportedMeldingView>({
          type: 'supported',
          id: melding.id,
          visualization,
          link,
          gelezen: melding.gelezen,
          timestamp: melding.tijdstip,
        }),
      ),
    );
  }

  private getVisualizationFunction<T extends MeldingType>(
    meldingType: T,
  ): MeldingVisualizationFunction<T> {
    return this.meldingVisualizationFunctions[meldingType];
  }

  private getVisualization(melding: MeldingDto): Observable<MeldingVisualization> {
    const visualizationFunction = this.getVisualizationFunction(melding.type);

    return of(melding).pipe(
      switchMap(visualizationFunction),
      catchError((e: unknown) => {
        this.loggingService.error(
          `Failed to create visualization for melding of type ${melding.type}`,
          e,
        );
        return of(asType<MeldingVisualization>({ type: 'error' }));
      }),
    );
  }

  private createMeldingWithJongereInfo(
    jongereProfielId: ProfielId,
    generateMeldingText: (profiel: JongereProfiel) => string,
  ): Observable<MeldingVisualization> {
    return this.teamService.getJongere(jongereProfielId).pipe(
      map((profiel) => {
        return {
          type: 'profiel',
          profiel,
          berichtRichText: generateMeldingText(profiel),
        };
      }),
    );
  }

  private createMeldingWithJongereOrTeamlidInfo(
    jongereId: ProfielId,
    teamlidOrJongereId: ProfielId,
    generateMeldingText: (profiel: ProfielInTeam) => string,
  ): Observable<MeldingVisualization> {
    return this.teamService.getJongereOrTeamlid(jongereId, teamlidOrJongereId).pipe(
      map((profiel) => {
        return {
          type: 'profiel',
          profiel,
          berichtRichText: generateMeldingText(profiel),
        };
      }),
    );
  }

  private getDoelNieuwBerichtReactieVisualization = (
    melding: DoelBerichtReactieAddedMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.verzenderId,
      (profiel) =>
        `${getProfielnaam(
          profiel,
        )}</strong> heeft gereageerd met <span class="typo-body variant-body-small">${getParkourEmoji(
          melding.metaData.reactieType,
        )}</span> op een bericht bij het doel <strong>${melding.metaData.doelNaam}</strong>.`,
    );

  private getDoelBerichtAddedVisualization = (
    melding: DoelBerichtAddedMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.verzenderId,
      (profiel) =>
        `${getProfielnaam(profiel)}</strong> heeft een reactie toegevoegd bij het doel <strong>${
          melding.metaData.doelNaam
        }</strong>.`,
    );

  private getDoelEmojiReactieAddedVisualization = (
    melding: DoelEmojiReactieAddedMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.reactieOwnerId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft gereageerd met <span class="typo-body variant-body-small">${getParkourEmoji(
          melding.metaData.reactie,
        )}</span> op het doel <strong>${melding.metaData.doelNaam}</strong>.`,
    );

  private getVideogesprekGestart = (melding: VideogesprekGestartMelding) =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.with,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> wil een videogesprek met je starten.`,
    );

  private getJeugdhulphistoriekGedeeldVisualization = (
    melding: JeugdhulpHistoriekGedeeldMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.contextId,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft een jeugdhulphistoriek met je gedeeld.`,
    );

  private getGebeurtenisGedeeldVisualization = (
    melding: GebeurtenisGedeeldMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.contextId,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft een gebeurtenis met je gedeeld.`,
    );

  private getDoelGesuggereerdVisualization = (
    melding: DoelGesuggereerdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.suggestedById,
      (profiel) => `<strong>${getProfielnaam(profiel)}</strong> heeft een nieuw doel voorgesteld.`,
    );

  private getDoelGedeeldVisualization = (
    melding: DoelGedeeldMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.contextId,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft het doel <strong>${
          melding.metaData.doelNaam
        }</strong> met je gedeeld.`,
    );

  private getNieuwChatReactieVisualization = (
    melding: NieuwChatReactieMelding,
  ): Observable<MeldingVisualization> => {
    return this.getGesprekNaam(melding.metaData.gesprekOwnerId, melding.metaData.deelnemers).pipe(
      take(1),
      switchMap((gesprekNaam) => {
        return this.createMeldingWithJongereOrTeamlidInfo(
          melding.contextId,
          melding.metaData.verzenderId,
          (profiel) => {
            return `<strong>${getProfielnaam(
              profiel,
            )}</strong> heeft gereageerd met <span class="typo-body variant-body-small">${getParkourEmoji(
              melding.metaData.reactieType,
            )}</span> op bericht${
              melding.metaData.groepsgesprek === 'true'
                ? ` in je groepsgesprek: <strong>${gesprekNaam}</strong>.`
                : '.'
            }`;
          },
        );
      }),
    );
  };

  private getNieuwChatBerichtVisualization = (
    melding: NieuwChatBerichtMelding,
  ): Observable<MeldingVisualization> => {
    return this.getGesprekNaam(melding.metaData.gesprekOwnerId, melding.metaData.deelnemers).pipe(
      take(1),
      switchMap((gesprekNaam) => {
        return this.createMeldingWithJongereOrTeamlidInfo(
          melding.contextId,
          melding.metaData.verzenderId,
          (profiel) => {
            return `
        <strong>${getProfielnaam(
          profiel,
        )}</strong> heeft een <strong>nieuw bericht</strong> gestuurd${
          melding.metaData.groepsgesprek === 'true'
            ? ` in je groepsgesprek: <strong>${gesprekNaam}</strong>.`
            : '.'
        }`;
          },
        );
      }),
    );
  };

  private getAfspraakGestartVisualization = (
    melding: AfspraakGestartMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.metaData.jongereProfielId,
      melding.metaData.with,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft jullie <strong>afspraak gestart</strong> en wacht op jou.`,
    );

  private getNieuwEyouthVisualization = (): Observable<MeldingVisualization> =>
    of({
      type: 'icon',
      icon: 'vlaanderen',
      berichtRichText: `Er is een <strong>nieuwe gebeurtenis uit Jeugdhulp</strong> toegevoegd aan je <strong>verhaal</strong>.`,
    });

  private getNieuwDrieKolommenVizualization = () =>
    of(
      asType<MeldingVisualization>({
        type: 'icon',
        icon: 'vlaanderen',
        berichtRichText: `Er is een <strong>nieuw document</strong> toegevoegd aan je <strong>verhaal</strong>.`,
      }),
    );

  private getUitnodigingBevestigingGeweigerdVisualization = (
    melding: UitnodigingBevestigingGeweigerd,
  ) =>
    this.createMeldingWithJongereInfo(
      melding.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong> niet toegevoegd</strong> als teamlid.`,
    );

  private getUitnodigingBevestigdVisualization = (melding: UitnodigingBevestigdMelding) =>
    this.createMeldingWithJongereInfo(
      melding.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong>toegevoegd</strong> als teamlid.`,
    );

  private getUitnodigingGeweigerdVisualization = (
    melding: UitnodigingGeweigerdMelding,
  ): Observable<MeldingVisualization> =>
    of({
      type: 'default',
      berichtRichText: `<strong>${melding.metaData.uitgenodigdeNaam}</strong> heeft je uitnodiging voor je team <strong>geweigerd</strong>.`,
    });

  private getUitnodigingAanvaardVisualization = (
    melding: UitnodigingAanvaardMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId as ProfielId,
      melding.metaData.uitgenodigdeId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je uitnodiging in je team <strong>aanvaard</strong> en wacht op jouw goedkeuring.`,
    );

  private getTeamVerlatenVisualization = (
    melding: TeamVerlatenMelding,
  ): Observable<MeldingVisualization> =>
    of({
      type: 'default',
      berichtRichText: `<strong>${melding.metaData.naam}</strong> heeft je <strong>team verlaten</strong>.`,
    });

  private getUitgenodigdVisualization = (
    meldingDto: UitgenodigdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      meldingDto.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong>uitgenodigd</strong> als teamlid.`,
    );

  private getAfspraakHerinneringVisualization = (
    meldingDto: AfspraakHerinneringMelding,
  ): Observable<MeldingVisualization> =>
    of<MeldingVisualization>({
      icon: 'kalender',
      type: 'icon',
      berichtRichText: `Herinnering: afspraak <strong>${meldingDto.metaData.titel}</strong> op <strong>${this.datePipe.transform(
        meldingDto.metaData.van,
      )} om ${this.datePipe.transform(meldingDto.metaData.van, 'HH:mm')}</strong>.`,
    });

  private getAfspraakAangemaaktVisualization = (
    meldingDto: AfspraakAangemaaktMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      meldingDto.metaData.jongereProfielId,
      meldingDto.metaData.with,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft een <strong>nieuwe afspraak</strong> met je gepland op ${this.datePipe.transform(
          meldingDto.metaData.van,
        )} om ${this.datePipe.transform(meldingDto.metaData.van, 'HH:mm')} tot ${this.datePipe.transform(
          meldingDto.metaData.tot,
          'HH:mm',
        )}.`,
    );

  private getAfspraakVerwijderdVisualization = (
    meldingDto: AfspraakVerwijderdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      meldingDto.metaData.jongereProfielId,
      meldingDto.metaData.with,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft je afspraak op ${this.datePipe.transform(
          meldingDto.metaData.van,
        )} om ${this.datePipe.transform(meldingDto.metaData.van, 'HH:mm')} tot ${this.datePipe.transform(
          meldingDto.metaData.tot,
          'HH:mm',
        )} <strong>geannuleerd</strong>.`,
    );

  private getDoelVoltooidVisualization = (
    meldingDto: DoelVoltooidMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      meldingDto.contextId,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft het doel <strong>${
          meldingDto.metaData.doelNaam
        }</strong> voltooid.`,
    );

  private getGeblokkeerdVisualization = (
    melding: GeblokkeerdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong>geblokkeerd</strong> als teamlid.`,
    );

  private getGedeblokkeerdVisualization = (
    melding: GedeblokkeerdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong>terug toegevoegd</strong> als teamlid.`,
    );

  private getVerwijderdUitTeamVisualization = (
    melding: VerwijderdUitTeamMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereInfo(
      melding.metaData.jongereProfielId,
      (profiel) =>
        `<strong>${getProfielnaam(
          profiel,
        )}</strong> heeft je <strong>verwijderd</strong> als teamlid.`,
    );

  private getVerwijderdUitTeamDoorJongere25Visualization = (
    melding: VerwijderdUitTeamDoorJongere25,
  ): Observable<MeldingVisualization> =>
    of({
      type: 'default',
      berichtRichText: `<strong>${melding.metaData.jongereNaam}</strong> heeft je verwijderd als teamlid.`,
    });

  private getReminderOneWeekVisualization = (): Observable<MeldingVisualization> =>
    of({
      type: 'icon',
      icon: 'parkour',
      berichtRichText: `Vanaf je 25 jaar bent kan je niet meer als jongere aan de slag in PARKOUR en stopt je team.`,
    });

  private getReminderOneDayVisualization = (): Observable<MeldingVisualization> =>
    of({
      type: 'icon',
      icon: 'parkour',
      berichtRichText: `Vanaf je 25 jaar bent kan je niet meer als jongere aan de slag in PARKOUR en stopt je team.`,
    });

  private getProfielWordtVerwijderdVisualisation = (
    melding: ProfielWordtVerwijderdHerinneringMelding,
  ): Observable<MeldingVisualization> => {
    return of({
      type: 'icon',
      icon: 'parkour',
      berichtRichText: `Je profiel wordt verwijderd op ${this.datePipe.transform(melding.metaData.verwijderDatum)}.`,
    });
  };

  private getGebeurtenisGesuggereerdVisualization = (
    melding: GebeurtenisGesuggereerdMelding,
  ): Observable<MeldingVisualization> =>
    this.createMeldingWithJongereOrTeamlidInfo(
      melding.contextId,
      melding.metaData.suggestedById,
      (profiel) =>
        `<strong>${getProfielnaam(profiel)}</strong> heeft een nieuwe gebeurtenis voorgesteld.`,
    );

  meldingVisualizationFunctions: {
    [Type in MeldingType]: MeldingVisualizationFunction<Type>;
  } = {
    TEAM_VERLATEN: this.getTeamVerlatenVisualization,
    UITNODIGING_AANVAARD: this.getUitnodigingAanvaardVisualization,
    UITNODIGING_GEWEIGERD: this.getUitnodigingGeweigerdVisualization,
    UITNODIGING_BEVESTIGD: this.getUitnodigingBevestigdVisualization,
    BEVESTIGING_GEWEIGERD: this.getUitnodigingBevestigingGeweigerdVisualization,
    UITGENODIGD: this.getUitgenodigdVisualization,
    NIEUW_DRIE_KOLOMMEN_DOCUMENT: this.getNieuwDrieKolommenVizualization,
    NIEUWE_EYOUTH_GEBEURTENIS: this.getNieuwEyouthVisualization,
    AFSPRAAK_HERINNERING: this.getAfspraakHerinneringVisualization,
    AFSPRAAK_AANGEMAAKT: this.getAfspraakAangemaaktVisualization,
    AFSPRAAK_VERWIJDERD: this.getAfspraakVerwijderdVisualization,
    AFSPRAAK_GESTART: this.getAfspraakGestartVisualization,
    NIEUW_CHAT_BERICHT: this.getNieuwChatBerichtVisualization,
    NIEUW_CHAT_REACTIE: this.getNieuwChatReactieVisualization,
    GEBEURTENIS_GESUGGEREERD: this.getGebeurtenisGesuggereerdVisualization,
    DOEL_GEDEELD: this.getDoelGedeeldVisualization,
    DOEL_GESUGGEREERD: this.getDoelGesuggereerdVisualization,
    DOEL_VOLTOOID: this.getDoelVoltooidVisualization,
    GEBEURTENIS_GEDEELD: this.getGebeurtenisGedeeldVisualization,
    JEUGDHULPHISTORIEK_GEDEELD: this.getJeugdhulphistoriekGedeeldVisualization,
    VIDEOGESPREK_GESTART: this.getVideogesprekGestart,
    DOEL_NIEUW_BERICHT: this.getDoelBerichtAddedVisualization,
    DOEL_NIEUW_BERICHT_REACTIE: this.getDoelNieuwBerichtReactieVisualization,
    DOEL_EMOJI_REACTIE_ADDED: this.getDoelEmojiReactieAddedVisualization,
    GEBLOKKEERD: this.getGeblokkeerdVisualization,
    GEDEBLOKKEERD: this.getGedeblokkeerdVisualization,
    VERWIJDERD_UIT_TEAM: this.getVerwijderdUitTeamVisualization,
    VERWIJDERD_UIT_TEAM_DOOR_JONGERE_25: this.getVerwijderdUitTeamDoorJongere25Visualization,
    JONGERE_REMINDER_ONE_WEEK: this.getReminderOneWeekVisualization,
    JONGERE_REMINDER_ONE_DAY: this.getReminderOneDayVisualization,
    PROFIEL_WORDT_VERWIJDERD_HERINNERING: this.getProfielWordtVerwijderdVisualisation,
  };

  private getGesprekNaam(gesprekOwnerId: ProfielId, deelnemers: string): Observable<string> {
    if (!deelnemers) {
      return of('Naam niet beschikbaar');
    }

    const deelnemersArray = deelnemers.split(',') as ProfielId[];

    return forkJoin(
      deelnemersArray.map((deelnemerId) =>
        this.teamService.getJongereOrTeamlid(gesprekOwnerId, deelnemerId),
      ),
    ).pipe(map((profielen) => profielen.map((profiel) => getProfielnaam(profiel)).join(', ')));
  }
}
